import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MainStepperComponent } from '@shared/components/main-stepper/main-stepper.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MainStepperComponent],
  imports: [CommonModule, MatIconModule, TranslateModule],
  exports: [MainStepperComponent]
})
export class MainStepperModule {}

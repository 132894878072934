import { IOption } from '@forms/base-select/base-select.component';

export function prepareFilterOption(data: any): any {
  if (!data) {
    return {};
  }
  return Object.keys(data).reduce((acc: any, item: string): any => {

    if (!data[item]) {
      return { ...acc };
    }

    if (Array.isArray(data[item])) {
      return {
        ...acc,
        [item]: data[item].map(( option: IOption ): string | number => option.value)
      };
    }

    if (typeof data[item] === 'object') {

      if (item === 'school_type') {
        return {
          ...acc,
          [item]: Object.keys(data[item]).filter((key: string): boolean => data[item][key]).join(',')
        };
      }

      return {
        ...acc,
        [item]: data[item].value
      };
    }

    return {
      ...acc,
      [item]: data[item]
    };
  }, {});
}

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { HttpService } from '@services/http/http.service';
import { IProgressStepRequest, IProgressStepResponse } from '@models/interfaces/progress-steps-status.interface';

@Injectable({
  providedIn: 'root'
})
export class UserFormStepperService {
  updatingStepsStatus: BehaviorSubject<void> = new BehaviorSubject(null);

  constructor(@Inject(APP_CONFIG) private config: IAppConfig, private http: HttpService) {
  }

  getStepStatus(data: IProgressStepRequest, studentId?: number): Observable<IProgressStepResponse> {
    const utl: string = studentId ? `/api/v1/students/progress-filling/${studentId}` : '/api/v1/students/progress-filling/';
    return this.http.get(`${this.config.apiUrl}${utl}?fields=${JSON.stringify(data)}`);
  }
}

<div class="flex-grid">
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-select
      [placeholder]="key + 'ENGLISH_EXAM_TYPE_LABEL' | translate"
      [options]='englishExamTypesOption'
      [control]="getControl('english_exam_type', inputForm)"
    ></base-select>
  </div>
  <div
    *ngIf="!getControl('date_of_exam', inputForm).disabled"
    class="cell cell_not-padding-bottom large-6 middle-6 small-12"
  >
    <base-datepicker
      startViewMode="month"
      [placeholder]="key + 'DATE_OF_EXAM_LABEL' | translate"
      [control]="getControl('date_of_exam', inputForm)"
    ></base-datepicker>
  </div>
</div>

<div *ngIf='canShowIELTS' class="flex-grid">
  <div class="cell cell_not-padding-bottom small-only-12">
    <h2 class="title-block m-b-3">{{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_IELTS' | translate | uppercase }}</h2>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate"
      [control]="getLanguageRequirementsControl('ielts-Reading')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate"
      [control]="getLanguageRequirementsControl('ielts-Listening')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate"
      [control]="getLanguageRequirementsControl('ielts-Speaking')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate"
      [control]="getLanguageRequirementsControl('ielts-Writing')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate"
      [control]="getLanguageRequirementsControl('ielts-Overall')"
    ></base-form-input>
  </div>
</div>
<div *ngIf='canShowTOEFL' class="flex-grid">
  <div class="cell cell_not-padding-bottom small-only-12">
    <h2 class="title-block m-b-3">{{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_TOEFL' | translate | uppercase }}</h2>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate"
      [control]="getLanguageRequirementsControl('toefl-Reading')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate"
      [control]="getLanguageRequirementsControl('toefl-Listening')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate"
      [control]="getLanguageRequirementsControl('toefl-Speaking')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate"
      [control]="getLanguageRequirementsControl('toefl-Writing')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate"
      [control]="getLanguageRequirementsControl('toefl-Overall')"
    ></base-form-input>
  </div>
</div>
<div  *ngIf='canShowOTHER' class="flex-grid">
  <div class="cell cell_not-padding-bottom small-only-12">
    <h2 class="title-block m-b-3">{{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_OTHER' | translate | uppercase }}</h2>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate"
      [control]="getLanguageRequirementsControl('other-Reading')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate"
      [control]="getLanguageRequirementsControl('other-Listening')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate"
      [control]="getLanguageRequirementsControl('other-Speaking')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate"
      [control]="getLanguageRequirementsControl('other-Writing')"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-2 middle-4 small-6">
    <base-form-input
      [placeholder]="'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate"
      [control]="getLanguageRequirementsControl('other-Overall')"
    ></base-form-input>
  </div>
</div>

<div class="flex-grid">
  <div
    class="cell  large-6 middle-6 small-12"
    [class.cell_not-padding-bottom]="getControl('i_have_gre_exam_scores', inputForm).value"
  >
    <base-boolean-field
      [type]="booleanFieldType.toggle"
      [control]="getControl('i_have_gre_exam_scores', inputForm)"
      toggleLabelPosition="before"
    >
      {{ key + 'I_HAVE_GRE_EXAM_SCORES_LABEL' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-datepicker
      *ngIf="triggerVisibilityControl('gre_exam_date')"
      startViewMode="month" [placeholder]="key + 'GRE_EXAM_DATE_LABEL' | translate"
      [control]="getControl('gre_exam_date', inputForm)"
    ></base-datepicker>
  </div>
</div>
<div class="flex-grid">
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gre_verbal_score')"
      [placeholder]="key + 'VERBAL_SCORE_LABEL' | translate"
      [control]="getControl('gre_verbal_score', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gre_quantitative_score')"
      [placeholder]="key + 'QUANTITATIVE_SCORE_LABEL' | translate"
      [control]="getControl('gre_quantitative_score', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gre_writing_score')"
      [placeholder]="key + 'WRITING_SCORE_LABEL' | translate"
      [control]="getControl('gre_writing_score', inputForm)"
    ></base-form-input>
  </div>
</div>

<div class="flex-grid">
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-boolean-field
      [type]="booleanFieldType.toggle" toggleLabelPosition="before"
      [control]="getControl('i_have_gmat_exam_scores', inputForm)"
    >
      {{ key + 'I_HAVE_GMAT_EXAM_SCORES_LABEL' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-datepicker
      *ngIf="triggerVisibilityControl('gmat_exam_date')"
      startViewMode="month"
      [placeholder]="key + 'GRE_EXAM_DATE_LABEL' | translate"
      [control]="getControl('gmat_exam_date', inputForm)"
    ></base-datepicker>
  </div>
</div>

<div class="flex-grid">
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gmat_verbal_score')"
      [placeholder]="key + 'VERBAL_SCORE_LABEL' | translate"
      [control]="getControl('gmat_verbal_score', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gmat_quantitative_score')"
      [placeholder]="key + 'QUANTITATIVE_SCORE_LABEL' | translate"
      [control]="getControl('gmat_quantitative_score', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gmat_writing_score')"
      [placeholder]="key + 'WRITING_SCORE_LABEL' | translate"
      [control]="getControl('gmat_writing_score', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      *ngIf="triggerVisibilityControl('gmat_writing_score')"
      [placeholder]="key + 'WRITING_OVERALL_LABEL' | translate"
      [control]="getControl('gmat_overall_score', inputForm)"
    ></base-form-input>
  </div>
</div>

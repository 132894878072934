import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';

import { AccountSidebarComponent } from '@shared/components/account-sidebar/account-sidebar.component';

@NgModule({
  declarations: [AccountSidebarComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, TranslateModule, MatIconModule, MatRippleModule],
  exports: [AccountSidebarComponent]
})
export class AccountSidebarModule {}

import { Component } from '@angular/core';
import { IFooterLink } from '@models/interfaces/footer-link.interface';
import { ISocialNetwork } from '@models/interfaces/social-network.interface';
import { socialNetwork } from '@misc/constants/social-network.constant';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
  readonly key: string = 'FOOTER.';

  participantsLinkList: IFooterLink[] = [
    {
      path: 'educator',
      value: `${this.key}EDUCATORS_LINK_LABEL`
    },
    {
      path: 'student',
      value: `${this.key}STUDENTS_LINK_LABEL`
    },
    {
      path: 'recruiter',
      value: `${this.key}RECRUITERS_LINK_LABEL`
    },
  ];
  aboutLinkList: IFooterLink[] = [
    {
      path: '/static/our-story',
      value: `${this.key}OUR_STORY_LINK_LABEL`
    },
    {
      path: 'recruiter',
      value: `${this.key}CAREERS_LINK_LABEL`
    },
    {
      path: '/news',
      value: `${this.key}NEWS_LINK_LABEL`
    },
  ];
  infoLinkList: IFooterLink[] = [
    {
      path: 'contact',
      value: `${this.key}CONTACT_LINK_LABEL`
    },
    {
      path: '/about-us',
      value: `${this.key}ABOUT_US_LINK_LABEL`
    },
    {
      path: '/static/faq',
      value: `${this.key}FAQ_LINK_LABEL`
    },
  ];
  productLinkList: IFooterLink[] = [
    {
      path: '/suggestion',
      value: `${this.key}DISCOVER_PROGRAM_LINK_LABEL`,
      queryParams: { activeTab: 'program' }
    },
    {
      path: '/suggestion',
      value: `${this.key}DISCOVER_SCHOOL_LINK_LABEL`,
      queryParams: { activeTab: 'school' }
    },
    {
      path: '/auth/sign-up',
      value: `${this.key}REGISTER_LINK_LABEL`
    },
    {
      path: '/static/fees',
      value: `${this.key}APPLY_CLEVER_LINK_LABEL`
    }
  ];
  legalLinkList: IFooterLink[] = [
    {
      path: '/static/private-policy',
      value: `${this.key}PRIVACY_POLICY_LINK_LABEL`
    },
    {
      path: '/static/terms-conditions',
      value: `${this.key}TERMS_LINK_LABEL`
    },
    {
      path: '/static/terms-of-use',
      value: `${this.key}TERMS_USE_LINK_LABEL`
    },
    {
      path: '/static/cookie-policy',
      value: `${this.key}COOKIE_POLICY_LINK_LABEL`
    }
  ];
  socialLinks: ISocialNetwork[] = socialNetwork;
}

<mat-sidenav-container class='account-layout'>
  <mat-sidenav
    [mode]='matSidenavMode'
    [opened]='sidebar$ | async'
    [fixedInViewport]="true"
    [fixedTopGap]="fixedTopGapOffset"
    [fixedBottomGap]="0"
    (closed)='sidebar$.next(false)'
    class='account-layout__sidebar-holder'
  >
    <account-sidebar></account-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class='account-layout__content-holder'>
    <button
      mat-mini-fab
      color='primary'
      (click)='sidebarTrigger()'
      class='account-layout__sidebar-icon-action'
    >
      <mat-icon>{{ actionIcon }}</mat-icon>
    </button>
    <div class='account-layout__container'>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { LoaderContainerModule } from './loader-container/loader-container.module';
import { PaginatedListModule } from './paginated-list/paginated-list.module';
import { DataTableModule } from './data-table/data-table.module';
import { PaginatorModule } from './paginator/paginator.module';
import { CroppedTextModule } from './cropped-text/cropped-text.module';
import { AppHeaderModule } from '@shared/components/app-header/app-header.module';
import { AppFooterModule } from '@shared/components/app-footer/app-footer.module';
import { CustomContentCardModule } from '@shared/components/custom-content-card/custom-content-card.module';
import { DctDownloadModule } from '@shared/components/dct-download/dct-download.module';
import { AdvertisingModule } from './advertising/advertising.module';
import { CountdownTimerModule } from '@shared/components/countdown-timer/countdown-timer.module';
import { ProgramPreviewModule } from './program-preview/program-preview.module';
import { GalleryModule } from './gallery/gallery.module';
import { SuggestionItemModule } from '@shared/components/suggestion-item/suggestion-item.module';
import { AccountSidebarModule } from '@shared/components/account-sidebar/account-sidebar.module';
import { FileUploadingModule } from '@shared/components/file-uploading/file-uploading.module';
import { YoutubeVideoModule } from '@shared/components/youtube-video/youtube-video.module';
import { BaseEditorModule } from '@shared/components/base-editor/base-editor.module';
import { AddYoutubeLinkPopupModule } from '@shared/components/add-youtube-link-popup/add-youtube-link-popup.module';
import { UploadMediaPopupModule } from '@shared/components/upload-media-popup/upload-media-popup.module';
import { FilterPopupModule } from '@shared/components/filter-popup/filter-popup.module';
import { MapGoogleModule } from './map-google/map-google.module';
import { GoogleMapSearchModule } from '@shared/components/google-map-search/google-map-search.module';
import { MainStepperModule } from '@shared/components/main-stepper/main-stepper.module';
import { PaidProductTableModule } from '@shared/components/paid-product-table/paid-product-table.module';
import { ReviewProgramPopupModule } from '@shared/components/review-program-popup/review-program-popup.module';
import { StudentProfileFormModule } from '@shared/components/student-profile-form/student-profile-form.module';
import { StudentProfileDetailModule } from '@shared/components/student-profile-detail/student-profile-detail.module';
import { ShowStudentProfilePopupModule } from '@shared/components/show-student-profile-popup/show-student-profile-popup.module';
import { NewsItemModule } from '@shared/components/news-item/news-item.module';

// Components

@NgModule({
  exports: [
    BreadcrumbsModule,
    LoaderContainerModule,
    PaginatedListModule,
    DataTableModule,
    PaginatorModule,
    CroppedTextModule,
    AppHeaderModule,
    AppFooterModule,
    CustomContentCardModule,
    DctDownloadModule,
    AdvertisingModule,
    CountdownTimerModule,
    ProgramPreviewModule,
    GalleryModule,
    SuggestionItemModule,
    AccountSidebarModule,
    FileUploadingModule,
    YoutubeVideoModule,
    BaseEditorModule,
    AddYoutubeLinkPopupModule,
    UploadMediaPopupModule,
    FilterPopupModule,
    UploadMediaPopupModule,
    MapGoogleModule,
    GoogleMapSearchModule,
    MainStepperModule,
    PaidProductTableModule,
    ReviewProgramPopupModule,
    StudentProfileFormModule,
    StudentProfileDetailModule,
    ShowStudentProfilePopupModule,
    NewsItemModule
  ]
})
export class SharedComponentsModule {}

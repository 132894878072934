<ng-container *ngIf='address && !forceHideMap; else campusAddressIsNotCorrect'>
  <div class='map-header'>
    <div class='map-header__address-line'><mat-icon class='map-header__address-line-icon' svgIcon='location'></mat-icon>{{ address }}</div>
    <div class='large-6 middle-6 small-6 map-header__btn-holder'>
      <button
        mat-button
        color="primary"
        (click)='mapModeStatus = mapModeView.map'
        [class.active]='mapIsShow'
        class='mid-size-btn average-font-weight'
      >{{ 'BUTTON_NAME.MAP_VIEW' | translate | uppercase }}</button>
    </div>
    <div class='large-6 middle-6 small-6 map-header__btn-holder' >
      <button
        mat-button
        color="primary"
        (click)='mapModeStatus = mapModeView.street'
        [class.active]='!mapIsShow'
        class='mid-size-btn average-font-weight'
      >{{ 'BUTTON_NAME.STREET_VIEW' | translate | uppercase }}</button>
    </div>
  </div>
  <div class='map-body'>
    <google-map
      #map
      [width]='width'
      [height]='height'
      [options]='options'
      [class.active]='mapIsShow'
      class='map-body__item'
    >
      <map-marker
        *ngFor='let item of markerList'
        [position]='item.position'
      ></map-marker>
    </google-map>
    <div [class.active]='!mapIsShow'>
      <div [class]='panoramaId' [style]="{'width': widthElem, 'height': heightElem}" #panorama></div>
    </div>
  </div>
</ng-container>
<ng-template #campusAddressIsNotCorrect>
  <h2 class='campus-address-is-not-correct'>{{ 'MESSAGE.CAMPUS_ADDRESS_IS_NOT_CORRECT' | translate }}</h2>
</ng-template>




import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaidProductTableComponent } from '@shared/components/paid-product-table/paid-product-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { DataTableModule } from '@shared/components/data-table/data-table.module';
import { MaterialModule } from '@shared/material/material.module';
import { StripeModule } from '@shared/components/stripe/stripe.module';

@NgModule({
  declarations: [PaidProductTableComponent],
  imports: [CommonModule, TranslateModule, MaterialModule, PipesModule, MatButtonModule, DataTableModule, StripeModule],
  exports: [PaidProductTableComponent]
})
export class PaidProductTableModule {}

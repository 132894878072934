import { Inject, Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@misc/abstracts/api-base.abstract.service';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { HttpService, IServicesConfig } from '@services/http/http.service';
import { PathParsePipe } from '@pipes/path-parse/path-parse.pipe';
import { ClassType } from 'class-transformer/ClassTransformer';
import { INews } from '@models/interfaces/news.interface';
import { Observable } from 'rxjs';
import { List } from '@models/classes/_base.model';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends ApiBaseAbstractService<INews> {
  protected readonly URLPath: string = '/api/v1/news';
  protected readonly model: ClassType<INews>;

  constructor(@Inject(APP_CONFIG) protected config: IAppConfig, protected http: HttpService, protected pathParsePipe: PathParsePipe) {
    super(config, http, pathParsePipe);
  }

  get fullUrl(): string {
    return `${this.baseUrl}${this.URLPath}`;
  }

  getAllNews(options?: any, services?: IServicesConfig | null): Observable<List<INews>> {
    return this.http.get(`${this.fullUrl}`, options, services);
  }

  getNewsById(id: number, options?: any, services?: IServicesConfig | null): Observable<INews> {
    return this.http.get(`${this.fullUrl}/${id}`, options, services);
  }
}

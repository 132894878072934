<div *ngIf='visibilitySlider | async' class="input-slider-field">
  <label *ngIf="label" class="input-slider__label">{{ label }}</label>
  <ngx-slider
    [value]="minRange.value"
    [options]="options"
    [highValue]="maxRange.value"
    (valueChange)="valueChangeHandler($event)"
    (highValueChange)="highValueChangeHandler($event)"
    class="input-slider"
  ></ngx-slider>
</div>

import { Observable, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { List } from '@models/classes/_base.model';
import { INews } from '@models/interfaces/news.interface';

export interface INewsResponses {
  getAll(_: any, body: any): Observable<HttpResponse<List<INews>>>;
  getById(): Observable<HttpResponse<INews>>;
}

const links: string[] = [
  'https://www.vuelio.com/uk/wp-content/uploads/2019/02/Breaking-News.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuVWRu3j8uOFeMS4eRwhosVaYOydSjfb7pTg&usqp=CAU',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvVoNrzDjDak1Zmp6Uak8N_ytjt06Sb2NllA&usqp=CAU',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-2n0-119RskrhpwrQjGZz5s4vOMJQQBBVvw&usqp=CAU',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpe3dLPXQyLFNbYLxD5YGp37P_uzzF2B8mMQ&usqp=CAU'
];

function makeid(length: number): string {
  const result: any = [];
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength: number = characters.length;
  for ( let i: number = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

function getOneNews(): INews {
  return {
    id: 0,
    name: Array(Math.floor(Math.random() * 10)).fill(makeid(Math.floor(Math.random() * 2 * 10))).join(' '),
    youtube: null,
    preview: Array(Math.floor(Math.random() * 10000)).fill(makeid(Math.floor(Math.random() * 2 * 10))).join(' '),
    text: Array(Math.floor(Math.random() * 10000)).fill(makeid(Math.floor(Math.random() * 2 * 10))).join(' '),
    image: links[Math.floor(Math.random() * links.length)],
    created_at: new Date().toString()
  };
}

function getNewsList(length?: number): INews[] {
  const result: INews[] = [];

  if (!length) {
    return [
      {
        ...getOneNews(),
        id: 1
      }
    ];
  }

  for ( let i: number = 0; i < length; i++ ) {
    result.push(getOneNews());
  }
  return result.map((item: INews, index: number): INews => ({
    ...item,
    id: index + 1
  }));
}

export const newsResponses: INewsResponses = {
  getAll(_: any, body: any): Observable<HttpResponse<List<INews>>> {
    const count: number = body?.map?.get('count');
    const data: INews[] = getNewsList(count ?? Math.floor(Math.random() * 100));
    return of(
      new HttpResponse({
        status: 200,
        body: {
          data,
          meta: {
            count: data.length,
            current_page: 1,
            per_page: 10,
            total: data.length,
            total_pages: Math.round(data.length / 10) || 1
          }
        }
      })
    );
  },
  getById(): Observable<HttpResponse<INews>> {
    const data: INews[] = getNewsList();
    return of(
      new HttpResponse({
        status: 200,
        body: data[0]
      })
    );
  }
};

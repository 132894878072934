import { Component, Inject, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@misc/custom-validators';
import { youtubeVideoLinkRegex } from '@misc/reg-pattern';
import { getYoutubeVideoLink } from '@misc/helpers/get-youtube-video-link';

export interface ITestimonials {
  picture: string;
  video: string;
}

@Component({
  selector: 'upload-media-popup',
  templateUrl: './upload-media-popup.component.html',
  styleUrls: ['./upload-media-popup.component.scss']
})
export class UploadMediaPopupComponent extends BaseFormAbstractComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formInit(this.data);
  }

  formInit(data?: ITestimonials): void {
    this.formGroup = this.fb.group({
      type: [(data?.picture && 'picture') || (data?.video && 'video') || 'picture'],
      picture: this.fb.control(data?.picture || null),
      video: this.fb.control(data?.video || null, [
        Validators.required,
        CustomValidators.youtubeVideoLink,
        Validators.pattern(youtubeVideoLinkRegex)
      ])
    });
    this.setActiveControl(this.getControl('type').value);
    this.form.type.valueChanges.subscribe((type: any): void => {
      this.setActiveControl(type);
    });
  }

  getFormData(): ITestimonials {
    return {
      picture: this.form.picture.value,
      video: getYoutubeVideoLink(this.form.video.value)
    };
  }

  setActiveControl(type: string = 'picture'): void {
    switch (type) {
      case 'picture':
        this.form.picture.enable();
        this.form.video.reset();
        this.form.video.disable();
        break;
      case 'video':
        this.form.video.enable();
        this.form.picture.reset();
        this.form.picture.disable();
        break;
    }
  }
}

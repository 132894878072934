import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';

@Component({
  template: ''
})
export class StudentProfileFormHelperComponent extends BaseFormAbstractComponent implements OnInit {

  @Input() inputForm: FormGroup = new FormGroup({});
  @Input() studentForm: FormGroup;

  gmatExamScoresControls: string[] = [
    'gmat_exam_date',
    'gmat_verbal_score',
    'gmat_quantitative_score',
    'gmat_writing_score',
    'gmat_overall_score'
  ];
  greExamScoresControls: string[] = [
    'gre_exam_date',
    'gre_verbal_score',
    'gre_quantitative_score',
    'gre_writing_score',
  ];

  ngOnInit(): void {
    this.triggerLanguageRequirements(this.getControl('english_exam_type', (this.studentForm ?? this.inputForm)).value);
    this.triggerDisablingControl(
      this.gmatExamScoresControls,
      this.getControl('i_have_gmat_exam_scores', (this.studentForm ?? this.inputForm)).value
    );
    this.triggerDisablingControl(
      this.greExamScoresControls,
      this.getControl('i_have_gre_exam_scores', (this.studentForm ?? this.inputForm)).value
    );
  }

  triggerDisablingControl(controls: string[], status: boolean): void {
    controls.map((control: string): void => {
      if (!(control in (this.studentForm ?? this.inputForm).controls)) {
        return;
      }

      const currentControl: FormControl = this.getControl(control, (this.studentForm ?? this.inputForm));

      if (status) {
        currentControl.enable();
      } else {
        currentControl.reset();
        currentControl.disable();
      }
    });
  }

  triggerLanguageRequirements(value?: string): void {
    const date: FormControl = this.getControl('date_of_exam', (this.studentForm ?? this.inputForm));
    const group: FormGroup = this.getGroup('language_requirements', (this.studentForm ?? this.inputForm));
    Object.keys(group.controls).map((controlName: string): void => {
      if (value && controlName.includes(value)) {
        group.get(controlName).enable();
        return;
      }
      group.get(controlName).reset();
      group.get(controlName).disable();
    });

    if (Object.keys(group.controls).some((key: string): boolean => key.includes(value))) {
      date.enable();
    } else {
      date.reset();
      date.disable();
    }
  }
}

<button
  *ngIf='btnIsShow'
  mat-raised-button
  [color]="color"
  [class]="extraClass"
  (click)="apply(contentId)"
  [disabled]="needDisable"
>
  {{ label }}
</button>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'dct-download',
  templateUrl: './dct-download.component.html',
  styleUrls: ['./dct-download.component.scss']
})
export class DctDownloadComponent {
  @Input() title: string;
  @Input() downloadLinkDoc: string;
  @Input() downloadLinkPdf: string;
}

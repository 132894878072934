import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFormsModule } from '@forms/forms.module';
import { MatInputModule } from '@angular/material/input';
import { GoogleMapSearchComponent } from '@shared/components/google-map-search/google-map-search.component';

@NgModule({
  declarations: [GoogleMapSearchComponent],
  imports: [CommonModule, MatInputModule, AppFormsModule],
  exports: [GoogleMapSearchComponent]
})
export class GoogleMapSearchModule {}

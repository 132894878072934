import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { HttpService, IServicesConfig } from '@services/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(@Inject(APP_CONFIG) private config: IAppConfig, private http: HttpService) {}

  get apiUrl(): string {
    return this.config.apiUrl;
  }

  fetchPromotions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/v1/promotions-test`);
  }

  joinProgram(programId: number, options?: any, services?: IServicesConfig | null): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/v1/students/join-program/${programId}`, {}, options, services);
  }
}

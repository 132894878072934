<label class="base-autocomplete">
  <mat-form-field [appearance]="appearance" class="base-input__field">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <mat-icon matPrefix *ngIf="icon">{{ icon }}</mat-icon>
    <input
        matInput
        [type]="type"
        (input)="search.next($event)"
        [matAutocomplete]="auto"
        [formControl]="formControl"
        [placeholder]="placeholder"
        [readonly]="readonly"
    />
    <mat-icon *ngIf='formControl.value' matSuffix (click)='formControl.reset()'>clear</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayWith'>
      <mat-option *ngFor="let option of options" [value]="getValue(option)">
        <ng-container
            *ngTemplateOutlet="template ? template : defaultTemplate; context: { $implicit: option }"></ng-container>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</label>

<ng-template #defaultTemplate let-item>
  {{ item | json }}
</ng-template>

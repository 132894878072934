import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from '@services/filter/filter.service';
import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SaveCurrentSessionService {

  private _markersForSavingFilters: string[] = ['suggestion'];
  private _storageKey: string = 'currentSession';

  constructor(
    private router: Router,
    private filterService: FilterService,
    private storage: StorageService
  ) { }

  saveSession(): void {
    this._setDataToStorage({
      redirectTo: this.router.url.split('/'),
      filterData: this._shouldSaveFilter() ? this.filterService.allFiltersOptions : null
    });
  }

  restoreSession(): any {
    return this._getDataFromStorage();
  }

  private _shouldSaveFilter(): boolean {
    return this._markersForSavingFilters.some((marker: string): boolean => this.router.url.includes(marker)) ;
  }

  private _setDataToStorage(data: any): void {
    this.storage.setItem(this._storageKey, JSON.stringify(data), false);
  }

  private _getDataFromStorage(): any {
    const sessionData: any = JSON.parse(this.storage.get(this._storageKey));
    if (sessionData?.filterData) {
      this.filterService.allFiltersOptions = sessionData.filterData;
    }
    this.storage.remove(this._storageKey);
    return sessionData;
  }
}

<div class='news__holder'>
  <div class='news__image'>
    <img [src]='news.image' alt=''>
  </div>
  <h2 class='news__title'>{{ news.name }}</h2>
  <div class='flex-align-center news__content'>
    <p class='news__description' [innerHTML]='news.preview | maxLengthString: 300'></p>
    <button
      mat-stroked-button
      color="primary"
      (click)='readNews()'
      class='normal-size-btn'
    >{{ 'BUTTON_NAME.READ' | translate }}</button>
  </div>
</div>

import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent{

  @Input() galleryItemTemplate: TemplateRef<any>;
  @Input() galleryBtnNextTemplate: TemplateRef<any>;
  @Input() galleryBtnPrevTemplate: TemplateRef<any>;
  @Input() items: any[];
  @Input() config: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 3, all: 0 },
    speed: 250,
    touch: true,
    // loop: true,
    interval: { timing: 3500 },
    animation: 'lazy'
  };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AppFormsModule } from '@forms/forms.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { FilterPopupComponent } from '@shared/components/filter-popup/filter-popup.component';
import { FilterContentComponent } from '@shared/components/filter-popup/filter-content/filter-content.component';

@NgModule({
  declarations: [FilterPopupComponent, FilterContentComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, MatDialogModule, AppFormsModule, MatMenuModule, MatIconModule],
  exports: [FilterPopupComponent]
})
export class FilterPopupModule {}

import { IOption } from '@forms/base-select/base-select.component';

export const PER_PAGE_DEFAULT: number = 10;

export enum DATE_FORMAT {
  DM = 'dd/MM',
  DMY = 'dd/MM/yyyy',
  TIME = 'HH:mm'
}

export const intakeStatuses: IOption[] = [
  {
    value: 'open',
    label: 'Open'
  },
  {
    value: 'opens_soon',
    label: 'Opens soon'
  },
  {
    value: 'closed',
    label: 'Closed'
  },
  {
    value: 'closes_soon',
    label: 'Closes soon'
  }
];

export enum ExamList {
  bmat = 'BMAT',
  imat = 'IMAT',
  gmat = 'GMAT',
  gre = 'GRE',
  own = 'Own entrance exam',
}

export enum DocumentsRequested {
  bachelors_Degree = 'COMMON.DOCUMENTS_REQUESTED_BACHELOR_DEGREE',
  bachelors_transcript = 'COMMON.DOCUMENTS_REQUESTED_BACHELOR',
  english_language_certificate = 'COMMON.DOCUMENTS_REQUESTED_ENGLISH_CERTIFICATE',
  high_school_diploma = 'COMMON.DOCUMENTS_REQUESTED_HIGH_SCHOOL_DIPLOMA',
  high_school_transcript = 'COMMON.DOCUMENTS_REQUESTED_HIGH_SCHOOL_TRANSCRIPT',
  passport = 'COMMON.DOCUMENTS_REQUESTED_PASSPORT',
  photo = 'COMMON.DOCUMENTS_REQUESTED_PHOTO',
  portfolio = 'COMMON.DOCUMENTS_REQUESTED_PORTFOLIO',
  resume = 'COMMON.DOCUMENTS_REQUESTED_RESUME'
}

export enum ErrorStatusCode {
  notFound = 404
}

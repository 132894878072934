import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { CountdownTimerComponent } from '@shared/components/countdown-timer/countdown-timer.component';
import { CountdownVideoPopupComponent } from './components/countdown-video-popup/countdown-video-popup.component';
import { YoutubeVideoModule } from '@shared/components/youtube-video/youtube-video.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CountdownTimerComponent, CountdownVideoPopupComponent],
  imports: [CommonModule, MatButtonModule, YoutubeVideoModule, TranslateModule],
  exports: [CountdownTimerComponent]
})
export class CountdownTimerModule {}

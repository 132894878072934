import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router, RouterEvent } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'auth-wrapper',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  isHideNav: boolean = false;
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.checkNav();
    this.router.events
      .pipe(
        filter((event: RouterEvent): boolean => event instanceof ActivationEnd),
        map((evn: any): ActivationEnd  => evn)
      )
      .subscribe((): void => {
        this.checkNav();
    });
  }

  checkNav(): void {
    this.isHideNav = ['/auth/forgot-password'].includes(this.router.url);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '@shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { BaseInputModule } from '@forms/base-input/base-input.module';

import { AddYoutubeLinkPopupComponent } from '@shared/components/add-youtube-link-popup/add-youtube-link-popup.component';

@NgModule({
  declarations: [AddYoutubeLinkPopupComponent],
  imports: [CommonModule, MatButtonModule, MaterialModule, TranslateModule, BaseInputModule],
  exports: [AddYoutubeLinkPopupComponent]
})
export class AddYoutubeLinkPopupModule {}

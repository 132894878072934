import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IDataTableColumn } from '@models/interfaces/data-table-column.interface';
import { List } from '@models/classes/_base.model';
import { ServicesApiService } from '@services/api/services/services-api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'paid-product-table',
  templateUrl: './paid-product-table.component.html',
  styleUrls: ['./paid-product-table.component.scss']
})
export class PaidProductTableComponent implements OnInit {
  @ViewChild('descriptionCell', { static: true }) descriptionCell: TemplateRef<any>;
  @ViewChild('productsActionCell', { static: true }) productsActionCell: TemplateRef<any>;
  @Input() title: string;

  productsColumns: IDataTableColumn[] = [];
  productsList: List;

  constructor(private servicesApiService: ServicesApiService, private sanitizer: DomSanitizer) {}

  get tableTitle(): string {
    return this.title ?? 'COMMON.PAID_PRODUCTS';
  }

  ngOnInit(): void {
    this.initData();

    this.getAllProducts();
  }

  initData(): void {
    this.productsColumns = [
      { columnName: 'name', title: 'Name of products' },
      { columnName: 'description', title: 'Description', template: this.descriptionCell },
      { columnName: 'price', title: 'Price' },
      { columnName: 'action', template: this.productsActionCell }
    ];
  }

  getAllProducts(): void {
    this.servicesApiService.getAll().subscribe((services: any): void => {
      this.productsList = services;
    });
  }
}

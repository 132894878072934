<div class="flex-grid">
  <div class="cell cell_not-padding-bottom small-only-12">
    <h2 class="title-block m-b-3">{{ key + 'MY_DOCUMENTS' | translate }}</h2>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <file-uploading
      [label]="key + 'PASSPORT' | translate"
      [control]="getControl('passport', this.getDocumentGroup())"
      [btnLabel]="'BUTTON_NAME.UPLOAD' | translate"
      [previewTemplate]="imgTemplate"
    ></file-uploading>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <file-uploading
      [label]="key + 'RESUME' | translate"
      [control]="getControl('resume', this.getDocumentGroup())"
      [btnLabel]="'BUTTON_NAME.UPLOAD' | translate"
      [previewTemplate]="imgTemplate"
    ></file-uploading>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <file-uploading
      [label]="key + 'REFERENCE_LABEL' | translate"
      [btnLabel]="'BUTTON_NAME.UPLOAD' | translate"
      [control]="getControl('reference', this.getDocumentGroup())"
      [previewTemplate]="imgTemplate"
    ></file-uploading>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <file-uploading
      [label]="key + 'PHOTO_LABEL' | translate"
      [btnLabel]="'BUTTON_NAME.UPLOAD' | translate"
      [control]="getControl('photo', this.getDocumentGroup())"
      [previewTemplate]="imgTemplate"
    ></file-uploading>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <file-uploading
      [label]="key + 'OTHER_DOCUMENTS_LABEL' | translate"
      [btnLabel]="'BUTTON_NAME.UPLOAD' | translate"
      [control]="getControl('other_documents', this.getDocumentGroup())"
      [previewTemplate]="imgTemplate"
    ></file-uploading>
  </div>
</div>

<ng-template #imgTemplate let-item let-control="control" let-order="order">
  <div class="image-holder">
    <div class="image-box">
      <button mat-mini-fab color="warn" (click)="control.reset()" class="image-delete">
        <mat-icon>close</mat-icon>
      </button>
      <img [src]="item.url" [alt]="item.name" />
    </div>
  </div>
</ng-template>

import { NgModule } from '@angular/core';
import { BaseMultiAutocompleteComponent } from '@forms/base-multi-autocomplete/base-multi-autocomplete.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [BaseMultiAutocompleteComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [BaseMultiAutocompleteComponent]
})
export class BaseMultiAutocompleteModule {}

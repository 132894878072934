import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLengthString'
})
export class MaxLengthStringPipe implements PipeTransform {
  transform(value: string, length: number = value.length): string {
    return value?.length > length ? `${value.slice(0, length)}...` : value;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ControlType } from '@models/enums/control-type.enum';
import { Validators } from '@angular/forms';
import { IBaseStepperStatus } from '@models/interfaces/base-stepper-status.interface';
import { BaseStepperStatusEnum } from '@models/enums/base-stepper-status.enum';
import { IFullStudentProfile, IProfileFormFieldItem } from '@models/interfaces/profile.interface';
import { VALIDATORS_SET } from '@misc/constants/validators-set.constant';
import { CustomValidators } from '@misc/custom-validators';
import { dynamicAddControlByTypeFunction } from '@misc/helpers/dynamic-add-control-by-type.function';
import { StudentProfileEnum } from '@models/enums/student-profile-enum';
import { IProgressStepRequest } from '@app/models/interfaces/progress-steps-status.interface';
import { StudentProfileFormHelperComponent } from '@shared/components/student-profile-form/student-profile-form.helper.component';

@Component({
  selector: 'student-profile-form',
  templateUrl: './student-profile-form.component.html',
  styleUrls: ['./student-profile-form.component.scss']
})
export class StudentProfileFormComponent extends StudentProfileFormHelperComponent implements OnInit {
  readonly key: string = 'ACCOUNT_STUDENT.';
  readonly studentProfile: typeof StudentProfileEnum = StudentProfileEnum;
  readonly controlType: typeof ControlType = ControlType;

  @Input() student: IFullStudentProfile = {} as IFullStudentProfile;

  showForm: boolean;
  stepList: IBaseStepperStatus[] = [
    {
      label: 'General Information',
      key: StudentProfileEnum.general_information,
      status: BaseStepperStatusEnum.untouched,
      active: false
    },
    {
      label: 'Educational history',
      key: StudentProfileEnum.educator_history,
      status: BaseStepperStatusEnum.untouched,
      active: false
    },
    {
      label: 'Test Scores',
      key: StudentProfileEnum.test_scores,
      status: BaseStepperStatusEnum.untouched,
      active: false
    },
    {
      label: 'Background Information',
      key: 'background_information',
      status: BaseStepperStatusEnum.untouched,
      active: false
    },
    {
      label: 'Documents',
      key: StudentProfileEnum.documents,
      status: BaseStepperStatusEnum.untouched,
      active: false
    }
  ];
  activeStep: IBaseStepperStatus;
  progressFields: IProgressStepRequest = {
    general_information: [
      'first_name.required',
      'patronymic',
      'last_name.required',
      'date_of_birth.required',
      'languages.required',
      'country_of_citizenship.required',
      'passport_number.required',
      'gender.required',
      'marital_status.required',
      'address.required',
      'country',
      'province',
      'city',
      'email',
      'phone',
      'zip_code'
    ],
    educator_history: [
      'country_of_education',
      'highest_level_of_education',
      'have_graduated_recent_school',
      'grading_scheme',
      'your_grade_average',
      'primary_school',
      'secondary_school',
      'high_school',
      'bachelors_degree',
      'masters_degree',
      'doctorate_degree',
      'school_list'
    ],
    test_scores: ['date_of_exam', 'english_exam_type', 'i_have_gre_exam_scores', 'i_have_gmat_exam_scores'],
    background_information: [
      'have_usa_f1_visa',
      'have_usa_f1_visa_details',
      'canadian_study_permit',
      'canadian_study_permit_details',
      'have_uk_student_visa_short_study_visa',
      'have_uk_student_visa_short_study_visa_details',
      'have_australian_student',
      'have_australian_student_details',
      'have_new_zealand_student',
      'have_new_zealand_student_details',
      'have_valid_visa_form_other_country',
      'other_country',
      'have_ever_visited_canada_usa',
      'you_currently_residing_canada_usa',
      'applied_type_immigration_into_canada_usa',
      'have_valid_canadian_study_permit',
      'have_valid_us_study_visa',
      'have_ever_been_refused_visa_for_canada_usa',
      'were_ever_removed_from_canada_usa'
    ],
    documents: ['passport.required', 'resume', 'reference', 'photo', 'other_documents']
  };
  formList: IProfileFormFieldItem[] = [
    {
      name: 'first_name',
      validators: [Validators.required, VALIDATORS_SET.NAME]
    },
    {
      name: 'last_name',
      validators: [Validators.required, VALIDATORS_SET.NAME]
    },
    {
      name: 'patronymic',
      validators: [VALIDATORS_SET.NAME]
    },
    {
      name: 'phone',
      validators: [CustomValidators.phone]
    },
    {
      name: 'email',
      validators: [Validators.email]
    },
    {
      name: 'date_of_birth'
    },
    {
      name: 'languages',
      validators: [Validators.maxLength(200)]
    },
    {
      name: 'country_of_citizenship',
      validators: [Validators.required]
    },
    {
      name: 'passport_number',
      validators: [Validators.maxLength(200)]
    },
    {
      name: 'gender'
    },
    {
      name: 'marital_status'
    },
    {
      name: 'address',
      validators: [Validators.maxLength(200)]
    },
    {
      name: 'country',
    },
    {
      name: 'province',
      validators: [Validators.maxLength(200)]
    },
    {
      name: 'city',
      validators: [Validators.maxLength(200)]
    },
    {
      name: 'zip_code',
      validators: [Validators.maxLength(200)]
    },
    {
      name: 'subscribe_parents',
      validators: [Validators.email]
    },
    {
      name: 'primary_school'
    },
    {
      name: 'secondary_school'
    },
    {
      name: 'high_school'
    },
    {
      name: 'bachelors_degree'
    },
    {
      name: 'masters_degree'
    },
    {
      name: 'doctorate_degree'
    },
    {
      name: 'country_of_education'
    },
    {
      name: 'highest_level_of_education'
    },
    {
      name: 'have_graduated_recent_school'
    },
    {
      name: 'grading_scheme'
    },
    {
      name: 'your_grade_average'
    },
    {
      name: 'school_list',
      controlType: ControlType.array
    },
    {
      name: 'english_exam_type'
    },
    {
      name: 'date_of_exam'
    },
    {
      name: 'language_requirements',
      controlType: ControlType.group,
      controls: [
        {
          name: 'ielts-Reading',
          validators: [CustomValidators.onlyFloatNumber, Validators.required]
        },
        {
          name: 'toefl-Reading',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'other-Reading',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'ielts-Writing',
          validators: [CustomValidators.onlyFloatNumber, Validators.required]
        },
        {
          name: 'toefl-Writing',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'other-Writing',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'ielts-Listening',
          validators: [CustomValidators.onlyFloatNumber, Validators.required]
        },
        {
          name: 'toefl-Listening',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'other-Listening',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'ielts-Speaking',
          validators: [CustomValidators.onlyFloatNumber, Validators.required]
        },
        {
          name: 'toefl-Speaking',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'other-Speaking',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'ielts-Overall',
          validators: [CustomValidators.onlyFloatNumber, Validators.required]
        },
        {
          name: 'toefl-Overall',
          validators: [CustomValidators.onlyNumber, Validators.required]
        },
        {
          name: 'other-Overall',
          validators: [CustomValidators.onlyNumber, Validators.required]
        }
      ]
    },
    {
      name: 'i_have_gre_exam_scores'
    },
    {
      name: 'gre_exam_date',
      validators: [Validators.required]
    },
    {
      name: 'gre_verbal_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(170), Validators.min(0)]
    },
    {
      name: 'gre_quantitative_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(170), Validators.min(0)]
    },
    {
      name: 'gre_writing_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(6), Validators.min(0)]
    },
    {
      name: 'i_have_gmat_exam_scores'
    },
    {
      name: 'gmat_exam_date',
      validators: [Validators.required]
    },
    {
      name: 'gmat_verbal_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(60), Validators.min(0)]
    },
    {
      name: 'gmat_quantitative_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(60), Validators.min(0)]
    },
    {
      name: 'gmat_writing_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(6), Validators.min(0)]
    },
    {
      name: 'gmat_overall_score',
      validators: [Validators.required, CustomValidators.onlyNumber, Validators.max(800), Validators.min(0)]
    },
    {
      name: 'have_usa_f1_visa'
    },
    {
      name: 'have_usa_f1_visa_details'
    },
    {
      name: 'have_ever_visited_canada_usa'
    },
    {
      name: 'have_new_zealand_student'
    },
    {
      name: 'have_new_zealand_student_details'
    },
    {
      name: 'have_australian_student'
    },
    {
      name: 'have_australian_student_details'
    },
    {
      name: 'you_currently_residing_canada_usa'
    },
    {
      name: 'applied_type_immigration_into_canada_usa'
    },
    {
      name: 'valid_study_permit_for_situated'
    },
    {
      name: 'have_valid_visa_form_other_country'
    },
    {
      name: 'canadian_study_permit'
    },
    {
      name: 'canadian_study_permit_details'
    },
    {
      name: 'have_valid_canadian_study_permit'
    },
    {
      name: 'have_valid_us_study_visa'
    },
    {
      name: 'have_ever_been_refused_visa_for_canada_usa'
    },
    {
      name: 'were_ever_removed_from_canada_usa'
    },
    {
      name: 'have_uk_student_visa_short_study_visa'
    },
    {
      name: 'have_uk_student_visa_short_study_visa_details'
    },
    {
      name: 'other_country',
      controlType: ControlType.array
    },
    {
      name: 'documents',
      controlType: ControlType.group,
      controls: [
        {
          name: 'passport'
        },
        {
          name: 'resume'
        },
        {
          name: 'reference'
        },
        {
          name: 'photo'
        },
        {
          name: 'other_documents'
        }
      ]
    }
  ];

  ngOnInit(): void {
    this.formGeneration(this.student);
    super.ngOnInit();
  }

  formGeneration(data: IFullStudentProfile = {} as IFullStudentProfile): void {
    dynamicAddControlByTypeFunction(this.studentForm, this.formList, data);
    this.showForm = true;
  }
}

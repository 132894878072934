import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/material/material.module';
import { RouterModule } from '@angular/router';

import { AppFooterComponent } from '@shared/components/app-footer/app-footer.component';
import { FooterNavItemComponent } from '@shared/components/app-footer/footer-nav-item/footer-nav-item.component';

@NgModule({
  declarations: [AppFooterComponent, FooterNavItemComponent],
  imports: [CommonModule, PipesModule, TranslateModule, MaterialModule, RouterModule],
  exports: [AppFooterComponent]
})
export class AppFooterModule {

}

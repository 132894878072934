import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@misc/abstracts/api-base.abstract.service';
import { ClassType } from 'class-transformer/ClassTransformer';
import { FileResponse } from '@models/classes/file-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeApiService extends ApiBaseAbstractService<FileResponse> {
  protected model: ClassType<FileResponse> = FileResponse;
  protected URLPath: string = '/api/v1/payments/stripe';

  createCheckoutSession(data: object): Observable<any> {
    return this.http.post(`${this.baseUrl + this.URLPath}/create-checkout-session`, data);
  }
}

import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const showHide: AnimationTriggerMetadata = trigger('showHide', [
  state('show', style({
    ['height']: '*',
    ['overflow']: 'hidden'
  })),
  state('hide', style({
    ['height']: '0',
    ['overflow']: 'hidden',
  })),
  transition('show <=> hide', animate('400ms ease-in-out'))
]);

<div class="custom-content-card" [class.custom-content-card_reverse]="reverse">
  <div class="custom-content-card__container">
    <div class="custom-content-card__row">
      <div [class.custom-content-card__single-img-sell]='!contentList?.length' class="custom-content-card__cell">
        <div *ngIf="image" class="custom-content-card__image">
          <ng-container
            *ngTemplateOutlet="imageTemplate ? imageTemplate : defaultImageTemplate; context: { $implicit: image }"
          ></ng-container>
        </div>
      </div>
      <div *ngIf='contentList?.length' class="custom-content-card__cell align-center">
        <div class="custom-content-card-holder">
          <ng-container
            *ngTemplateOutlet="contentListTemplate ? contentListTemplate : defaultContentTemplate; context: { $implicit: contentList }"
          ></ng-container>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultContentTemplate let-list>
  <div class="custom-content-card__item" *ngFor="let item of list">
    <h2 *ngIf='item.title' class="custom-content-card__title">{{ item.title }}</h2>
    <p *ngIf='item.text' [innerHTML]='item.text'></p>
    <ul *ngIf='item.list' class='list'>
      <li *ngFor='let str of item.list' class='list__item'>{{ str }}</li>
    </ul>
    <ul *ngIf='item.nestedList' class='list'>
      <li *ngFor='let nestedItem of item.nestedList' class='list__item'>
        {{ nestedItem.title }}
        <ul *ngIf='nestedItem?.list' class='list'>
          <li *ngFor='let second of nestedItem.list' class='list__item nested'>{{ second }}</li>
        </ul>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #defaultImageTemplate let-img>
  <img [src]="img" alt=''>
</ng-template>

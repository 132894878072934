<header class="header" #header>
  <div class="header-holder" #headerHolder>
    <div class="container">
      <div class="p-grid">
        <div class="p-col-6 p-xl-3 p-md-3 p-sm-4">
          <a [routerLink]="['/']" class="header__logo">
            <img src="/assets/img/logo-apply-clever.svg" alt='Logo' />
          </a>
        </div>
        <ng-container
          [ngTemplateOutlet]='(mediaMatcher | async) ? mobile : desktop'
        ></ng-container>
      </div>
    </div>
  </div>
</header>

<ng-template #desktop>
  <div class="p-col-12 p-xl-6 p-md-6 p-sm-4 display-flex">
    <nav class="nav">
      <ul class="nav-list">
        <li class="nav-list__item" *ngFor="let item of items">
          <a
            [routerLink]="item.url"
            routerLinkActive="nav-list__link_active"
            [routerLinkActiveOptions]="{ exact: true }"
            mat-button
            class="nav-list__link"
          >
            {{ key + item.label | translate }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="p-col-12 p-xl-3 p-md-3 p-sm-4 display-flex">
    <div class="header-actions">
      <ng-container *ngTemplateOutlet="(isAuthenticated$ | async) ? userIsLogged : unknownUser"></ng-container>
      <button
        *ngIf="isAuthenticated$ | async"
        mat-icon-button
        [matBadge]="count"
        matBadgeColor="warn"
        [routerLink]="['/', 'account', 'messenger']"
        class="header-user__btn"
      >
        <mat-icon class="header-user__btn-icon">notifications</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet='startHereBtn'></ng-container>
<!--      <button-->
<!--        *ngIf="showStartHere"-->
<!--        mat-raised-button-->
<!--        [routerLink]="btnActionLink"-->
<!--        [queryParams]='btnActionLinkQueryParams'-->
<!--        color="primary"-->
<!--        class="header-actions__start-here mid-size-btn"-->
<!--      >-->
<!--        {{ btnActionTitle | translate }}-->
<!--      </button>-->
    </div>
  </div>
</ng-template>

<ng-template #mobile>
  <div class='header-mobile'>
    <div [class.is-open]='mobileMenuIsOpen' (click)='mobileMenuTrigger()' class='header-mobile__navigation-overlay'></div>
    <div class='header-mobile__menu-trigger'>
      <button mat-icon-button (click)='mobileMenuTrigger()' class="average-font-weight mid-size-btn">
        <mat-icon [svgIcon]="mobileMenuIsOpen ? 'custom-cancel' : 'menu'" class="header-user__btn-icon"></mat-icon>
      </button>
    </div>
    <div class='header-mobile__navigation-holder' [class.is-open]='mobileMenuIsOpen'>
      <div class='header-mobile__navigation-box'>
        <div class='flex-h-center m-b-4'>
          <div (click)='mobileMenuTrigger()'>
            <ng-container *ngTemplateOutlet='startHereBtn'></ng-container>
          </div>
        </div>
        <ul class='header-mobile__nav-list p-b-5' (click)='mobileMenuTrigger()'>
          <ng-container *ngTemplateOutlet="(isAuthenticated$ | async) ? userIsLoggedMobile : unknownUserMobile"></ng-container>
        </ul>
        <mat-divider></mat-divider>
        <nav class='header-mobile__nav p-t-5'>
          <ul class='header-mobile__nav-list' (click)='mobileMenuTrigger()'>
            <li *ngFor="let item of items">
              <a
                [routerLink]="item.url"
                routerLinkActive="header-mobile__nav-link_active"
                [routerLinkActiveOptions]="{ exact: true }"
                mat-button
                class="header-mobile__nav-link"
              >
                {{ key + item.label | translate }}
              </a>
            </li>
          </ul>
        </nav>
        <ul class="header-mobile__social-list">
          <li *ngFor='let item of socialLinks' class="header-mobile__social-list-item">
            <a [href]="item.href" [target]="item.target" class="header-mobile__social-list-link">
              <mat-icon [svgIcon]="item.svgIcon" class="header-mobile__social-list-icon"></mat-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userIsLoggedMobile>
  <li class='header-mobile__nav-link'>
    <button mat-button [routerLink]="'/account'" class='w-100 btn-text-end'>
      <mat-icon class='header-mobile__nav-icon'>person</mat-icon>
      <span>{{ 'BUTTON_NAME.MY_ACCOUNT' | translate }}</span>
    </button>
  </li>
  <li class='header-mobile__nav-link'>
    <button mat-button (click)="logout()" class='w-100 btn-text-end'>
      <mat-icon svgIcon="logout" class='header-mobile__nav-icon'></mat-icon>
      <span>{{ 'BUTTON_NAME.LOGOUT' | translate }}</span>
    </button>
  </li>
</ng-template>

<ng-template #unknownUserMobile>
  <li class='header-mobile__nav-link'>
    <button mat-button [routerLink]="['/auth']" class="average-font-weight mid-size-btn header__login-btn">
      <mat-icon svgIcon="login" class='header-mobile__nav-icon'></mat-icon>
      {{ key + 'LOGIN_BTN_LABEL' | translate }}
    </button>
  </li>
</ng-template>

<ng-template #startHereBtn>
  <button
    *ngIf="showStartHere"
    mat-raised-button
    [routerLink]="btnActionLink"
    [queryParams]='btnActionLinkQueryParams'
    color="primary"
    class="header-actions__start-here mid-size-btn"
  >
    {{ btnActionTitle | translate }}
  </button>
</ng-template>

<ng-template #userIsLogged>
  <button mat-icon-button [matMenuTriggerFor]="menu" class="header-user__btn">
    <mat-icon svgIcon="user" class="header-user__btn-icon"></mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="header-user__menu">
    <button mat-menu-item [routerLink]="'/account'" class="header-user__menu-btn">
      <mat-icon class="header-user__menu-btn-icon">person</mat-icon>
      <span>{{ 'BUTTON_NAME.MY_ACCOUNT' | translate }}</span>
    </button>
    <button mat-menu-item (click)="logout()" class="header-user__menu-btn">
      <mat-icon svgIcon="logout" class="header-user__menu-btn-icon"></mat-icon>
      <span>{{ 'BUTTON_NAME.LOGOUT' | translate }}</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #unknownUser>
  <button mat-button [routerLink]="['/auth']" class="average-font-weight mid-size-btn header__login-btn">
    {{ key + 'LOGIN_BTN_LABEL' | translate }}
  </button>
</ng-template>

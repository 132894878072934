import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SuggestionItemComponent } from '@shared/components/suggestion-item/suggestion-item.component';
import { AdvertisingModule } from '@shared/components/advertising/advertising.module';
import { CountdownTimerModule } from '@shared/components/countdown-timer/countdown-timer.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@shared/material/material.module';
import { MaxLengthStringPipe } from '@pipes/max-length-string/max-length-string.pipe';
import { PipesModule } from '@app/pipes/pipes.module';
import { ApplyToProgramModule } from '@shared/components/apply-to-program/apply-to-program.module';

@NgModule({
  declarations: [SuggestionItemComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AdvertisingModule,
    CountdownTimerModule,
    RouterModule,
    MaterialModule,
    PipesModule,
    ApplyToProgramModule
  ],
  exports: [SuggestionItemComponent],
})
export class SuggestionItemModule {}

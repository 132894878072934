import { NgModule } from '@angular/core';
import { ApplyToProgramComponent } from '@shared/components/apply-to-program/apply-to-program.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ApplyToProgramComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, RouterModule],
  exports: [ApplyToProgramComponent]
})
export class ApplyToProgramModule {}

<div class="dct-download">
  <div class="dct-download-container">
    <h2 *ngIf="title" class="dct-download__title">{{ title }}</h2>
    <div class="dct-download-holder" [class.flex-center]="!(downloadLinkDoc || downloadLinkPdf)">
      <div *ngIf="downloadLinkDoc || downloadLinkPdf" class="dct-download-btn-box">
        <a *ngIf="downloadLinkDoc" [href]="downloadLinkDoc" mat-button color="primary" class="mid-size-btn dct-download__btn">
          <mat-icon class="dct-download__btn-icon">vertical_align_bottom</mat-icon>
          {{ 'COMMON.DOWNLOAD_DOC_BTN_LABEL' | translate }}
        </a>
        <a *ngIf="downloadLinkPdf" [href]="downloadLinkPdf" download mat-button color="primary" class="mid-size-btn dct-download__btn">
          <mat-icon class="dct-download__btn-icon">vertical_align_bottom</mat-icon>
          {{ 'COMMON.DOWNLOAD_PDF_BTN_LABEL' | translate }}
        </a>
      </div>
      <div class="dct-download-custom-box">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

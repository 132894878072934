import { IOption } from '@models/interfaces/forms/option.interface';

export const primaryDegreesStudyOption: IOption[] = [
  {
    label: 'Grade 1',
    value: 'grade_1'
  },
  {
    label: 'Grade 2',
    value: 'grade_2'
  },
  {
    label: 'Grade 3',
    value: 'grade_3'
  },
  {
    label: 'Grade 4',
    value: 'grade_4'
  },
  {
    label: 'Grade 5',
    value: 'grade_5'
  },
  {
    label: 'Grade 6',
    value: 'grade_6'
  }
];
export const secondaryDegreesStudySchool: IOption[] = [
  {
    label: 'Grade 7',
    value: 'grade_7'
  },
  {
    label: 'Grade 8',
    value: 'grade_8'
  },
  {
    label: 'Grade 9',
    value: 'grade_9'
  }
];
export const highDegreesStudyOption: IOption[] = [
  {
    label: 'Grade 10',
    value: 'grade_10'
  },
  {
    label: 'Grade 11',
    value: 'grade_11'
  },
  {
    label: 'Grade 12',
    value: 'grade_12'
  }
];
export const bachelorDegreesStudyOption: IOption[] = [
  {
    label: '3 years',
    value: '3_years'
  },
  {
    label: '4 years',
    value: '4_years'
  },
  {
    label: '5 years',
    value: '5_years'
  }
];
export const mastersDegreesStudyOption: IOption[] = [
  {
    label: '1 years',
    value: '1_years'
  },
  {
    label: '2 years',
    value: '2_years'
  }
];

<h1 *ngIf="program?.school?.name" class="program__university-name">{{ program.school.name }}</h1>
<h2 *ngIf="program?.name" class="program__name m-b-4">{{ program.name }}</h2>
<span *ngIf="program?.school?.main_campus_address" class="program__location">
  <mat-icon svgIcon="location"></mat-icon>
  {{ program.school.main_campus_address }}
</span>

<ul class="program-fact-list">
  <li *ngIf="program?.study_type" class="program-fact-list__item">
    <mat-icon svgIcon="four-dots"></mat-icon>
    {{ key + 'TYPE_OF_STUDY' | translate }}:
    <b>{{ program?.study_type?.name }}</b>
  </li>
  <li *ngIf="program?.language_instruction?.length" class="program-fact-list__item">
    <mat-icon svgIcon="language"></mat-icon>
    {{ key + 'DETAIL_TAB_LANGUAGES_PROGRAM_TYPE_2_FIELD' | translate }}:
    <div>
      <div *ngFor="let item of program.language_instruction">
        <b class="m-r-2">{{ item.language }}</b>
        <span>{{ item.percent }}%</span>
      </div>
    </div>
  </li>
  <li *ngIf="program?.level" class="program-fact-list__item">
    <mat-icon svgIcon="study"></mat-icon>
    {{ key + 'DETAIL_TAB_PROGRAM_LEVEL_FIELD' | translate }}:
    <b>{{ program?.level?.name }}</b>
  </li>
  <li class="program-fact-list__item">
    <mat-icon svgIcon="program-accreditation"></mat-icon>
    {{ key + 'DETAIL_TAB_PROGRAM_ACCREDITATION_FIELD' | translate }}:
    <b *ngIf="program?.private_accreditation">
      {{ 'COMMON.PROGRAM_ACCREDITATION_PRIVATE' | translate }}
    </b>
    <b *ngIf="!program?.private_accreditation">{{ 'COMMON.PROGRAM_ACCREDITATION_STATE' | translate }}</b>
  </li>
  <li *ngIf="program?.discipline" class="program-fact-list__item">
    <mat-icon svgIcon="discipline-area"></mat-icon>
    {{ key + 'DETAIL_TAB_DISCIPLINE_AREA_FIELD' | translate }}:
    <b>{{ program?.discipline.name }}</b>
  </li>
  <li *ngIf="program?.classroom_size" class="program-fact-list__item">
    <mat-icon svgIcon="classroom"></mat-icon>
    {{ key + 'DETAIL_TAB_CLASSROOM_FIELD' | translate }}:
    <b>{{ program?.classroom_size }} people</b>
  </li>
  <li *ngIf="program?.category" class="program-fact-list__item">
    <mat-icon svgIcon="view-list-custom"></mat-icon>
    {{ key + 'DETAIL_TAB_CATEGORY_PROGRAM_FIELD' | translate }}:
    <b>{{ program?.category?.name }}</b>
  </li>
  <li *ngIf="program?.additional_cooperation" class="program-fact-list__item">
    <mat-icon svgIcon="suitcase"></mat-icon>
    {{ key + 'DETAIL_TAB_ADDITIONAL_COOPERATION_FIELD' | translate }}:
    <b>
      <span *ngIf="program?.additional_cooperation?.optional_practical_training">
        {{ 'PROGRAM.ADDITIONAL_COOPERATION_OPTIONAL' | translate }},
      </span>
      <span *ngIf="program?.additional_cooperation?.co_op">{{ 'PROGRAM.ADDITIONAL_COOPERATION_CO_OP' | translate }},</span>
      <span *ngIf="program?.additional_cooperation?.work_while_studying">
        {{ 'PROGRAM.ADDITIONAL_COOPERATION_WHILE_STUDYING' | translate }},
      </span>
      <span *ngIf="program?.additional_cooperation?.post_grad_work_permit">
        {{ 'PROGRAM.ADDITIONAL_COOPERATION_POST_GRAD_PERMIT' | translate }},
      </span>
    </b>
  </li>
  <li class="program-fact-list__item">
    <mat-icon svgIcon="receive-degree"></mat-icon>
    {{ key + 'DETAIL_TAB_RECEIVE_DEGREE_TYPE_2_FIELD' | translate }}:
    <b>
      <span *ngIf="program?.degree_in_end; else degreeInEndNo">
        {{ 'COMMON.YES' | translate }}
      </span>
      <ng-template #degreeInEndNo>
        <span>
          {{ 'COMMON.NO' | translate }}
        </span>
      </ng-template>
      <span *ngIf="program?.degree_in_end && program?.degree_in_freewriting">({{ program?.degree_in_freewriting }})</span>
    </b>
  </li>
  <li *ngIf="program?.discount_application_fee" class="program-fact-list__item">
    <mat-icon svgIcon="discount-app-fee"></mat-icon>
    {{ key + 'DETAIL_TAB_DISCOUNT_APP_FEE_FIELD' | translate }}:
    <b>
      {{ program?.discount_application_fee?.percent }}
      <span *ngIf="program?.discount_application_fee?.from">from {{ program?.discount_application_fee?.from | date: 'longDate' }}</span>
      <span *ngIf="program?.discount_application_fee?.to">to {{ program?.discount_application_fee?.to | date: 'longDate' }}</span>
    </b>
  </li>
  <li *ngIf="program?.education_from || program?.education_to" class="program-fact-list__item">
    <mat-icon svgIcon="clock"></mat-icon>
    {{ key + 'DETAIL_TAB_PERIOD_EDUCATION_FIELD' | translate }}:
    <b>{{ program?.education_from | date: 'shortDate' }} - {{ program?.education_to | date: 'shortDate' }}</b>
  </li>
  <li *ngIf="program.discount_tuition_fee" class="program-fact-list__item">
    <mat-icon svgIcon="discount-tuition-fee"></mat-icon>
    {{ key + 'DETAIL_TAB_DISCOUNT_APP_FEE_PER_YEAR_FIELD' | translate }}:
    <b>
      {{ program?.discount_tuition_fee.percent }},
      <span *ngIf="program?.discount_tuition_fee?.from">from {{ program.discount_tuition_fee.from | date: 'longDate' }}</span>
      <span *ngIf="program?.discount_tuition_fee?.to">to {{ program.discount_tuition_fee.to | date: 'longDate' }}</span>
    </b>
  </li>
  <li *ngIf="courseDurationField()" class="program-fact-list__item">
    <mat-icon svgIcon="hourglass"></mat-icon>
    {{ key + 'DETAIL_TAB_COURSE_DURATION_FIELD' | translate }}:
    <b>{{ courseDurationField() }}</b>
    <!--    <b>-->
    <!--      <span *ngIf='program?.duration?.years'>-->
    <!--        {{ 'PROGRAM.COURSE_DURATION_YEAR' | translate }} {{ program?.duration?.years }}-->
    <!--      </span>-->
    <!--      <span *ngIf='program?.duration?.months'>-->
    <!--        , {{ 'PROGRAM.COURSE_DURATION_MONTHS' | translate }} {{ program?.duration?.months }}-->
    <!--      </span>-->
    <!--      <span *ngIf='program?.duration?.weeks'>-->
    <!--        , {{ 'PROGRAM.COURSE_DURATION_WEEKS' | translate }} {{ program?.duration?.weeks }}-->
    <!--      </span>-->
    <!--      <span *ngIf='program?.duration?.days'>-->
    <!--        , {{ 'PROGRAM.COURSE_DURATION_DAYS' | translate }} {{ program?.duration?.days }}-->
    <!--      </span>-->
    <!--      <span *ngIf='program?.duration?.freewriting'>-->
    <!--        , {{ 'PROGRAM.COURSE_DURATION_FREEWRITING' | translate }} {{ program?.duration?.freewriting }}-->
    <!--      </span>-->
    <!--    </b>-->
  </li>
</ul>

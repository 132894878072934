<angular-editor
  [formControl]='control'
  [config]="config"
  class='base-editor'
></angular-editor>
<!--<editor-->
<!--  apiKey='ijb16q7cvjc1hbiueireukm64huwz58st19z5rgld1kmbm6j'-->
<!--  [init]="tese"-->
<!--  [formControl]='control'-->
<!--&gt;</editor>-->

<div class="tab-content-page-detail">
  <div class="tab-content-page-detail-field">
    <span class="average-bold-text">{{ key + 'LIST_DOCUMENT_APP' | translate}}:</span>
  </div>
  <div class="tab-content-page-detail-field">
    <div class="flex-grid">
      <div
        *ngFor='let item of getDocuments()'
        class='list-documents-cell cell cell_not-padding-bottom large-6 middle-6 small-12'
      >
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
  <div class="tab-content-page-detail-field">
    <span class="average-bold-text">{{ key + 'MIN_GPA_AVERAGES_GRADES' | translate }}</span>
    <span class='p-l-2'>{{program.min_gpa_requirement}} / {{ program.min_average_grades_admission }}</span>
  </div>
  <div class="tab-content-page-detail-field">
    <span class="average-bold-text">{{ key + 'MIN_LANGUAGE_REQUIREMENT' | translate }}</span>
  </div>
  <div class="language-requirement">
    <div
      *ngIf='shouldDisplayLanguageRequirements(program?.language_requirements?.ielts)'
      class="language-requirement__row"
    >
      <span class="language-requirement__name">
        {{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_IELTS' | translate | uppercase }}
      </span>
      <ul class="language-requirement-list">
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.ielts?.reading }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.ielts?.listening }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.ielts?.speaking }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.ielts?.writing }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.ielts?.overall }}</span>
        </li>
      </ul>
    </div>
    <div
      *ngIf='shouldDisplayLanguageRequirements(program?.language_requirements?.toefl)'
      class="language-requirement__row"
    >
      <span class="language-requirement__name">
        {{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_TOEFL' | translate | uppercase }}
      </span>
      <ul class="language-requirement-list">
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.toefl?.reading }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.toefl?.listening }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.toefl?.speaking }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.toefl?.writing }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.toefl?.overall }}</span>
        </li>
      </ul>
    </div>
    <div
      *ngIf='shouldDisplayLanguageRequirements(program?.language_requirements?.other)'
      class="language-requirement__row"
    >
      <span class="language-requirement__name">
        {{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_OTHER' | translate | uppercase }}
      </span>
      <ul class="language-requirement-list">
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.other?.reading }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.other?.listening }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.other?.speaking }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.other?.writing }}</span>
        </li>
        <li class="language-requirement-list__item">
          <span>{{ key + 'PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate }}</span>
          <span class='language-requirement-list__divider'>-</span>
          <span>{{ program.language_requirements.other?.overall }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf='program?.admission_deadline_date' class="tab-content-page-detail-field">
    <span class="average-bold-text">{{ key + 'DETAIL_TAB_ADMISSION_DEADLINE_FIELD' | translate }}</span>:
    <b class="accent-bold-text">{{ program.admission_deadline_date | date: 'MMM d, y' }}</b>
  </div>
  <div *ngIf='getIntakeStatus(program?.intake_status)' class="tab-content-page-detail-field">
    <span class="average-bold-text">{{ key + 'DETAIL_TAB_INTAKE_STATUS_FIELD' | translate }}</span>:
    <b class="accent-bold-text">{{ getIntakeStatus(program?.intake_status) }}</b>
  </div>
  <div class="tab-content-page-detail-field exam-box">
    <div class="exam-box__cell">
      <mat-icon [svgIcon]="isEntranceExam()"></mat-icon>
      <span class="average-bold-text">
        {{ key + 'ENTRANCE_EXAM' | translate }}
      </span>
    </div>
    <div class="exam-box__cell">
      <span class="average-bold-text">
        {{ key + 'ENTRANCE_EXAM_REQUIRED' | translate }}
      </span>:
      <b class="accent-bold-text">{{ getExamList() }}</b>
    </div>
  </div>
  <div
    *ngIf='shouldDisplayProgramMandatorySubjects(program?.mandatory_subjects)'
    class="tab-content-page-detail-field exam-box"
  >
    <div class="exam-box__cell exam-box__cell_not-center-centered">
      <span>{{ key + 'MANDATORY_SUBJECT_STUDIED_TO_APPLY' | translate }}</span>:
    </div>
    <div class="exam-box__cell">
      <table class="content-simply-table exam-box__cell_not-center-centered">
        <thead class="content-simply-table__head">
          <tr>
            <th class="content-simply-table__cell"></th>
            <th class="content-simply-table__cell">{{ key + 'GPA_LABEL' | translate | uppercase }}</th>
            <th class="content-simply-table__cell">{{ key + 'NO_GPA_LABEL' | translate | uppercase }}</th>
          </tr>
        </thead>
        <tbody class="content-simply-table__body">
        <ng-container *ngFor="let item of program.mandatory_subjects">
          <tr *ngIf='item.name'>
            <td class="content-simply-table__cell accent-bold-text">{{ item.name }}</td>
            <td class="content-simply-table__cell">{{ item.gpa_percent }}</td>
            <td class="content-simply-table__cell">{{ item.none_gpa_percent }}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

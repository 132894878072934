<div class="testimonial">
  <div
    *ngFor='let item of program?.testimonials'
    class="testimonial__row"
  >
    <div class="testimonial__cover-box">
      <div *ngIf='item.picture' class="testimonial__cover-box-img">
        <img [src]="item.picture.url" alt=''>
      </div>
      <div *ngIf="item.video" class='testimonial__cover-box-video'>
        <youtube-video
          [link]="getYoutubeLink(item.video)"
        ></youtube-video>
      </div>
    </div>
    <div class="testimonial__description">
      <p>{{ item.text }}</p>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { IProgramDetail } from '@models/interfaces/program-detail.interface';

@Component({
  selector: 'program-description',
  templateUrl: './program-description.component.html',
  styleUrls: ['./program-description.component.scss']
})
export class ProgramDescriptionComponent {
  readonly key: string = 'PAGE_DETAIL.';
  @Input() program: IProgramDetail;
}

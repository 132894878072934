<div class="flex-grid">
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-autocomplete
      [placeholder]="key + 'COUNTRY_OF_EDUCATION_LABEL' | translate"
      [control]="getControl('country_of_education', inputForm)"
      [options]='countryOptions'
      (inputSearch)='fetchCountry($event)'
      [template]='autoCompleteTemplate'
      [displayWith]='displayWith'
      [getValue]='getValue'
    ></base-autocomplete>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-select
      [placeholder]="key + 'HIGHEST_LEVEL_OF_EDUCATION_LABEL' | translate"
      [options]='educationLevelByCountryOption'
      [disabled]="!getControl('country_of_education', inputForm).value"
      [control]="getControl('highest_level_of_education', inputForm)"
    ></base-select>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-radio-group
      [label]="key + 'HAVE_GRADUATED_RECENT_SCHOOL_LABEL' | translate"
      [radioOption]="graduatedRecentSchool"
      [control]="getControl('have_graduated_recent_school', inputForm)"
    ></base-radio-group>
  </div>
  <div
    *ngIf="getControl('highest_level_of_education', inputForm).value"
    class="cell cell_not-padding-bottom large-4 middle-6 small-12"
  >
    <base-select
      [placeholder]="key + 'GRADING_SCHEME_LABEL' | translate"
      [options]='gradingSchemeOptions'
      [disabled]="getControl('highest_level_of_education', inputForm).value"
      [control]="getControl('grading_scheme', inputForm)"
    ></base-select>
  </div>
  <div
    *ngIf="getControl('grading_scheme', inputForm).value"
    class="cell cell_not-padding-bottom large-4 middle-6 small-12"
  >
    <base-select
      [placeholder]="key + 'YOUR_GRADE_AVERAGE_LABEL' | translate"
      [options]='gradeAverageOptions'
      [disabled]="getControl('grading_scheme', inputForm).value"
      [control]="getControl('your_grade_average', inputForm)"
    ></base-select>
  </div>
</div>
<div class="flex-grid">
  <div class="cell small-only-12">
    <h2 class="title-block">{{ key + 'ATTENDED_SCHOOL_TITLE' | translate }}</h2>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PRIMARY_SCHOOL_LABEL' | translate"
      [control]="getControl('primary_school', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'SECONDARY_SCHOOL_LABEL' | translate"
      [control]="getControl('secondary_school', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'HIGH_SCHOOL_LABEL' | translate"
      [control]="getControl('high_school', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'BACHELORS_DEGREE_LABEL' | translate"
      [control]="getControl('bachelors_degree', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'MASTERS_DEGREE_LABEL' | translate"
      [control]="getControl('masters_degree', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'DOCTORATE_DEGREE_LABEL' | translate"
      [control]="getControl('doctorate_degree', inputForm)"
    ></base-form-input>
  </div>
</div>
<div class="flex-grid">
  <div
    *ngFor="let control of getArray('school_list', inputForm).controls; let i = index"
    class="cell cell_not-padding-bottom large-4 middle-6 small-12"
  >
    <div class="attended-school__other-container">
      <base-form-input
        [placeholder]="key + 'ADD_SCHOOL_NAME' | translate"
        [control]='control'
      ></base-form-input>
      <button
        mat-icon-button
        (click)='deleteSchoolName(i)'
        color="warn"
        class="attended-school__action-icon"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div class="cell small-only-12">
    <button mat-raised-button color="primary" class="normal-size-btn" (click)='addSchoolName()'>
      {{ 'BUTTON_NAME.ADD_SCHOOL' | translate }}
    </button>
  </div>
</div>

<ng-template #autoCompleteTemplate let-item>
  {{ item.label }}
</ng-template>

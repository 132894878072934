<h3 mat-dialog-title>{{ 'BUTTON_NAME.TESTIMONIALS_LOAD_PICTURE_VIDEO' | translate }}</h3>
<div mat-dialog-content>
  <mat-button-toggle-group [formControl]="getControl('type')">
    <mat-button-toggle value="video">
      <mat-icon>movie</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="picture">
      <mat-icon>add_photo_alternate</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <base-form-input
    *ngIf="!form.video.disabled"
    [control]="getControl('video')"
    [placeholder]="'ACCOUNT_SCHOOL.ADD_YOUTUBE_LINK' | translate"
    class="full-width"
  ></base-form-input>

  <file-uploading
    *ngIf="!form.picture.disabled"
    [control]="getControl('picture')"
    [label]="'ACCOUNT_SCHOOL.ADD_IMAGE' | translate"
  ></file-uploading>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="null" color="warn">
    {{ 'BUTTON_NAME.CLOSE' | translate }}
  </button>
  <button mat-stroked-button [mat-dialog-close]="getFormData()" color="primary">
    {{ 'BUTTON_NAME.SAVE' | translate }}
  </button>
</div>

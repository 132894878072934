import { NgModule } from '@angular/core';
import { AuthLayoutModule } from '@layouts/auth/auth-layout.module';
import { MainLayoutModule } from '@layouts/main/main-layout.module';
import { AccountLayoutModule } from './account/account-layout.module';

@NgModule({
  imports: [],
  exports: [MainLayoutModule, AuthLayoutModule, AccountLayoutModule]
})
export class LayoutsModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { BaseFormAbstractComponent, IFormControls } from '@misc/abstracts/base-form.abstract.component';
import { FilterService, IAllFilterMilledDictionary } from '@services/filter/filter.service';
import { IOption } from '@forms/base-select/base-select.component';
import { Options } from '@angular-slider/ngx-slider';
import { BooleanFieldType } from '@app/common/modules/forms/base-boolean-field/base-boolean-field.component';
import { FilterPopupAction } from '@models/enums/filter-popup-action';

@Component({
  selector: 'filter-content',
  templateUrl: './filter-content.component.html',
  styleUrls: ['./filter-content.component.scss']
})
export class FilterContentComponent extends BaseFormAbstractComponent implements OnInit {

  readonly BooleanFieldType: typeof BooleanFieldType = BooleanFieldType;
  readonly filterPopupAction: typeof FilterPopupAction = FilterPopupAction;
  accordionIsOpen: boolean;
  permitOptions: IOption[] = [];
  nationalityOptions: IOption[] = [];
  countryOptions: IOption[] = [];
  levelOptions: IOption[] = [];
  gradingOptions: IOption[] = [];
  examOptions: IOption[] = [];
  programLevelOptions: IOption[];
  programCategoriesOptions: IOption[];
  disciplineOptions: IOption[];
  intakesOptions: IOption[] = [
    {
      value: 1,
      label: '1 intake'
    },
    {
      value: 2,
      label: '2 intake'
    },
    {
      value: 3,
      label: '3 intake'
    },
    {
      value: 4,
      label: '4 intake'
    },
    {
      value: 5,
      label: '5 intake'
    },
    {
      value: 6,
      label: '6 intake'
    },
    {
      value: 7,
      label: '7 intake'
    },
    {
      value: 8,
      label: '8 intake'
    },
    {
      value: 9,
      label: '9 intake'
    },
    {
      value: 10,
      label: '10 intake'
    }
  ];
  sliderDefaultOptions: Options = {
    floor: 0,
    ceil: 300,
    step: 1,
    hidePointerLabels: false,
    hideLimitLabels: false
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private filterService: FilterService) {
    super();
  }

  get form(): IFormControls {
    return this.data.form.controls ?? new FormGroup({}).controls ;
  }

  get gradingSchemeIsDisable(): boolean {
    return !(this.form.country_id.value && this.form.level_id.value.length);
  }

  ngOnInit(): void {
    this.formGroup = this.data.form;
    this.fetchFilters();
  }

  reset(): void {
    this.data?.reset();
  }

  searchCountry(query: string): void {
    this.filterService.fetchCountry(query).subscribe((res: IOption[]): void => {
      this.countryOptions = res;
    });
  }

  searchNationality(query: string): void {
    this.filterService.fetchCountry(query).subscribe((res: IOption[]): void => {
      this.nationalityOptions = res;
    });
  }

  fetchFilters(): void {
    const filters: string[] = [];
    this.programLevelChange();
    this.filterService.fetchAllFilters(filters).subscribe((res: IAllFilterMilledDictionary): void => {
      this.permitOptions = res.welcome_packages;
      this.examOptions = res.english_exam_types;
    });
  }

  programLevelChange(query: string = ''): void {
    this.filterService.fetchEducationLevel(query).subscribe((res: IOption[]): void => {
      this.programLevelOptions = res;
    });
  }

  disciplineChange(query: string = ''): void {
    this.filterService.fetchProgramCategories(query).subscribe((res: IOption[]): void => {
      this.disciplineOptions = res;
    });
  }

  programCategoriesChange(query: string = ''): void {
    this.filterService.fetchProgramCategories(query).subscribe((res: IOption[]): void => {
      this.programCategoriesOptions = res;
    });
  }

  getValue(item: IOption): IOption {
    return item;
  }

  displayWith(item: IOption): string | null {
    return item?.label;
  }
}

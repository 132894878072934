import { NgModule } from '@angular/core';
import { UploadMediaPopupComponent } from '@shared/components/upload-media-popup/upload-media-popup.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material/material.module';
import { AppFormsModule } from '@forms/forms.module';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadingModule } from '@shared/components/file-uploading/file-uploading.module';

@NgModule({
  declarations: [UploadMediaPopupComponent],
  imports: [CommonModule, MaterialModule, AppFormsModule, TranslateModule, FileUploadingModule],
  exports: [UploadMediaPopupComponent]
})
export class UploadMediaPopupModule {}

export const iconsConfig: string[] = [
  'no',
  'fb',
  'instagram',
  'linkedin',
  'twitter',
  'youtube',
  'facebook-color',
  'google-color',
  'custom-cancel',
  'shield',
  'check-circle-outline-custom',
  'location',
  'currency-dollar',
  'house',
  'mail',
  'study',
  'suitcase',
  'alert',
  'handshake',
  'institution',
  'tuition',
  'programs',
  'four-dots',
  'calendar',
  'language',
  'program-accreditation',
  'discipline-area',
  'classroom',
  'view-list-custom',
  'discount-app-fee',
  'discount-tuition-fee',
  'clock',
  'hourglass',
  'receive-degree',
  'check-circle-custom',
  'minus-custom',
  'good',
  'user',
  'logout',
  'account-program',
  'home',
  'school',
  'service',
  'arrow-left',
  'notification',
  'bookmark',
  'gear',
  'eye',
  'upload',
  'plus',
  'camera',
  'question',
  'eye-two',
  'plus2',
  'faq',
  'messenger',
  'diagram',
  'favorites',
  'student',
  'payment',
  'exclamation',
  'menu',
  'login'
];

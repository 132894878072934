<div mat-dialog-title></div>
<div mat-dialog-content>
  <div class='flex-grid'>
    <div class='cell cell_not-padding-bottom large-6 middle-6 small-12'>
      <base-select
        [optionTemplate]="itemSelect"
        [placeholder]="'QUICK_START.ELIGIBILITY_PERMIT_STUDY_LABEL' | translate"
        [control]="form.welcome_packages"
        [options]="permitOptions"
      ></base-select>
      <base-autocomplete
        [placeholder]="'QUICK_START.ELIGIBILITY_NATIONALITY_LABEL' | translate"
        [control]="form.nationality_id"
        (inputSearch)="searchNationality($event)"
        [template]="itemSelect"
        [options]="nationalityOptions"
        [getValue]='getValue'
        [displayWith]='displayWith'
      ></base-autocomplete>
      <base-autocomplete
        [placeholder]="'QUICK_START.ELIGIBILITY_EDUCATION_COUNTRY_LABEL' | translate"
        [control]="form.country_id"
        (inputSearch)="searchCountry($event)"
        [template]="itemSelect"
        [options]="countryOptions"
        [getValue]='getValue'
        [displayWith]='displayWith'
      ></base-autocomplete>
      <base-select
        [placeholder]="'QUICK_START.ELIGIBILITY_GRADING_SCHEME_LABEL' | translate"
        [control]="form.grading_id"
        [disabled]='gradingSchemeIsDisable'
        [options]='examOptions'
      ></base-select>
      <base-select
        [placeholder]="'QUICK_START.ELIGIBILITY_ENGLISH_EXAM_LABEL' | translate"
        [control]="form.english_exam_types"
        [options]='examOptions'
      ></base-select>
      <base-boolean-field [type]="BooleanFieldType.checkbox" [control]='form.direct_admissions'>
        {{ 'QUICK_START.ELIGIBILITY_SHOW_ADMISSION_LABEL' | translate }}
      </base-boolean-field>
    </div>
    <div class='cell cell_not-padding-bottom large-6 middle-6 small-12'>
      <base-multi-autocomplete
        (autocompleteChange)="programLevelChange($event)"
        [placeholder]="'QUICK_START.PROGRAM_LEVEL_LABEL' | translate"
        [options]="programLevelOptions"
        [chipsControl]="getArray('level_id')"
        [chipDisplayWith]="displayWith"
        [autocompleteItemDisplayWith]="displayWith"
        svgIcon="custom-cancel"
      ></base-multi-autocomplete>

      <base-select
        [placeholder]="'QUICK_START.INTAKES_LABEL' | translate"
        [options]='intakesOptions'
        [control]='form.intakes'
      ></base-select>

      <base-autocomplete
        [placeholder]="'QUICK_START.SECONDARY_DISCIPLINE_LABEL' | translate"
        [control]="form.discipline_id"
        (inputSearch)="disciplineChange($event)"
        [options]="disciplineOptions"
        [template]="itemSelect"
        [getValue]='getValue'
        [displayWith]='displayWith'
      ></base-autocomplete>

      <base-autocomplete
        [placeholder]="'QUICK_START.SECONDARY_SUB_CATEGORY_LABEL' | translate"
        [control]="form.category_id"
        (inputSearch)="programCategoriesChange($event)"
        [options]="programCategoriesOptions"
        [template]="itemSelect"
        [getValue]='getValue'
        [displayWith]='displayWith'
      ></base-autocomplete>

      <span class="display-block m-b-6">{{ 'QUICK_START.CURRENCY_CLARIFICATION_' | translate }}</span>
      <base-boolean-field [type]="BooleanFieldType.checkbox" class="display-block  m-b-6" [control]='form.include_living_costs'>
        {{ 'QUICK_START.INCLUDE_LIVING_COST_LABEL' | translate }}
      </base-boolean-field>

      <input-slider
        [label]="'QUICK_START.TUITION_FREE_LABEL' | translate"
        [options]="sliderDefaultOptions"
        [minRange]="getControl('tuition_fee_from')"
        [maxRange]="getControl('tuition_fee_to')"
        class="display-block m-b-6"
      ></input-slider>

      <input-slider
        [label]="'QUICK_START.APPLICATION_FREE_LABEL' | translate"
        [options]="sliderDefaultOptions"
        [minRange]="getControl('application_fee_from')"
        [maxRange]="getControl('application_fee_to')"
        class="display-block m-b-6"
      ></input-slider>
    </div>
  </div>
</div>
<div mat-dialog-actions class='flex-h-center'>
  <div class='clear-menu-holder'>
    <button
      mat-button
      (click)='reset()'
      color="primary"
    >
      {{ 'BUTTON_NAME.CLEAR_FILTERS' | translate }}
    </button>
    <button mat-button [matMenuTriggerFor]="menu" class='clear-menu-icon'>
      <span class="material-icons">keyboard_arrow_up</span>
    </button>
    <mat-menu #menu="matMenu" xPosition='before' yPosition='below'>
      <button
        mat-menu-item
        [mat-dialog-close]="filterPopupAction.resetApply"
      >{{ 'BUTTON_NAME.CLEAR_APPLY_FILTERS' | translate }}</button>
    </mat-menu>
  </div>
  <button
    mat-raised-button
    [mat-dialog-close]="filterPopupAction.apply"
    color="primary">{{ 'BUTTON_NAME.APPLY_FILTERS' | translate }}</button>
</div>

<ng-template #itemSelect let-item>
  <span>{{ item.label }}</span>
</ng-template>

import { Component, Input , TemplateRef } from '@angular/core';
import { ICustomContentItem } from '@models/interfaces/custom-content-card.interface';

@Component({
  selector: 'custom-content-card',
  templateUrl: './custom-content-card.component.html',
  styleUrls: ['./custom-content-card.component.scss']
})
export class CustomContentCardComponent {

  @Input() contentList: ICustomContentItem[];
  @Input() image: string;
  @Input() reverse: boolean;
  @Input() contentListTemplate: TemplateRef<any>;
  @Input() imageTemplate: TemplateRef<any>;

}

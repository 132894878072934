import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { UploadService } from '@services/upload/upload.service';
import { IFileModel, IUploadFile } from '@models/interfaces/file-model.interface';
import { docRegex, imageRegex, videoRegex } from '@misc/reg-pattern';
import { NotificationService } from '@services/notification/notification.service';
import { SnackBarNotificationType } from '@models/enums/snack-bar-notification-type.enum';
import { TranslatePipe } from '@ngx-translate/core';
import { FileType } from '@models/enums/file-type.enum';
import { MimeTypeFile } from '@models/enums/mime-type-file.enum';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'file-uploading',
  templateUrl: './file-uploading.component.html',
  styleUrls: ['./file-uploading.component.scss']
})
export class FileUploadingComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Input() control: FormControl = new FormControl(null);
  @Input() controlArray: FormArray = new FormArray([]);
  @Input() previewTemplate: TemplateRef<any>;
  @Input() label: string | null = null;
  @Input() btnLabel: string | null = null;
  @Input() multiple: boolean = false;
  @Input() placeholder: string = '';
  @Input() tooltipLabel: string = '';
  @Input() type: FileType = FileType.image;
  @Input() maxQualityFiles: number = 15;
  @Input() ShowUploadBtn: boolean = true;
  @Input() alignSingleBtn: 'center' | 'left' | 'right' | null = 'center';
  @Input() alignMultipleBtn: 'center' | 'left' | 'right' | null = 'center';
  accept: string;
  loadOnlyImagesTooltip: string = 'COMMON.LOAD_ONLY_IMAGES';
  loadOnlyPdfTooltip: string = 'COMMON.LOAD_ONLY_PDF';
  loadOnlyVideoTooltip: string = 'COMMON.LOAD_ONLY_VIDEO';

  @Output() changed: EventEmitter<any> = new EventEmitter();

  private needReplace: IUploadFile = null;

  constructor(private uploadService: UploadService, private notification: NotificationService, private translatePipe: TranslatePipe) {}

  ngOnInit(): void {
    this.accept = this.mimeType;
  }

  clicked(): void {
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: Event | any): void {
    if (this.multiple) {
      const currentFiLesQuality: number = this.controlArray.value.length;
      const needUploadFiLesQuality: number = event.target.files.length;
      if (currentFiLesQuality + needUploadFiLesQuality > this.maxQualityFiles) {
        this.showNotification(
          this.translatePipe.transform('ERROR_MESSAGE.LIMIT_TO_UPLOAD_FILES', {
            limit: this.maxQualityFiles,
            reach: this.maxQualityFiles - this.controlArray.value.length,
            tryingLoad: needUploadFiLesQuality
          }),
          SnackBarNotificationType.error,
          { duration: 5000 }
        );
        return;
      }
    }

    const files: IFileModel[] = this.filteringFiles(event.target.files);
    if (files.length) {
      this.uploadService.uploadFile(files).subscribe((response: ArrayBuffer[]): void => {
        if (this.multiple) {
          if (this.needReplace) {
            const index: number = this.controlArray.value.findIndex((file: IUploadFile): boolean => file.id === this.needReplace.id);
            this.controlArray.value.splice(index, 1, ...response);
            this.needReplace = null;
          } else {
            response.map((file: ArrayBuffer): void => {
              this.controlArray.push(new FormControl({ ...file }));
            });
          }
        } else {
          this.control.setValue({ ...response[0] });
        }
        response.map((item: ArrayBuffer | any): void => {
          this.showNotification(
            this.translatePipe.transform('ERROR_MESSAGE.FILE_SUCCESS_UPLOAD', { name: item.name }),
            SnackBarNotificationType.success
          );
        });
      });
    }
    this.fileInput.nativeElement.value = '';
  }

  filteringFiles(files: IFileModel[]): IFileModel[] {
    return [...files].filter((file: IFileModel): boolean => {
      const status: boolean = this.fileRegExp.test(file.name);
      if (!status) {
        this.showNotification(
          this.translatePipe.transform('ERROR_MESSAGE.FILE_UNKNOWN_TYPE', { name: file.name }),
          SnackBarNotificationType.error
        );
      }
      return status;
    });
  }

  get fileRegExp(): RegExp {
    switch (this.type) {
      case FileType.document:
        return docRegex;
      case FileType.image:
        return imageRegex;
      case FileType.video:
        return videoRegex;
      default:
        return imageRegex;
    }
  }

  get btnTooltip(): string {
    switch (this.type) {
      case FileType.document:
        return this.loadOnlyPdfTooltip;
      case FileType.video:
        return this.loadOnlyVideoTooltip;
      case FileType.image:
        return this.loadOnlyImagesTooltip;
      default:
        return this.loadOnlyImagesTooltip;
    }
  }

  get mimeType(): MimeTypeFile {
    switch (this.type) {
      case FileType.document:
        return MimeTypeFile.document;
      case FileType.video:
        return MimeTypeFile.video;
      case FileType.image:
        return MimeTypeFile.image;
      default:
        return MimeTypeFile.image;
    }
  }

  showNotification(message: string, type: SnackBarNotificationType, configParams?: MatSnackBarConfig): void {
    this.notification.addToQueue(message, type, configParams);
  }

  editItem(item: IUploadFile): void {
    if (this.multiple) {
      this.needReplace = item;
    }
    this.clicked();
  }

  deleteItem(item: IUploadFile): void {
    if (this.multiple) {
      const index: number = this.controlArray.value.findIndex((file: IUploadFile): boolean => file.id === item.id);
      this.controlArray.removeAt(index);
    } else {
      this.control.reset();
    }
  }
}

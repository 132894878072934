import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { SchoolInfoCardComponent } from './school-info-card.component';

@NgModule({
  declarations: [SchoolInfoCardComponent],
  imports: [CommonModule, MatCardModule],
  exports: [SchoolInfoCardComponent]
})
export class SchoolInfoCardModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SnackBarNotificationType } from '@models/enums/snack-bar-notification-type.enum';
import { AuthService } from '@services/auth/auth.service';
import { AccountService } from '@services/api/account/account.service';
import { NotificationService } from '@services/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { UserRole } from '@models/enums/user-role.enum';
import { HttpParams } from '@angular/common/http';
import { CustomHTTPParamsEncoder } from '@misc/custom-http-params-encoder';
import { SaveCurrentSessionService } from '@services/save-current-session/save-current-session.service';

@Component({
  selector: 'apply-to-program',
  templateUrl: './apply-to-program.component.html',
  styleUrls: ['./apply-to-program.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyToProgramComponent {

  @Input() label: string;
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() extraClass: string = 'small-size-btn large-padding';
  @Input() contentId: number;
  @Input() needDisable: boolean;
  @Output() forceMakeApplied: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private auth: AuthService,
    private accountService: AccountService,
    private notification: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslatePipe,
    private saveCurrentSession: SaveCurrentSessionService
  ) {}

  get btnIsShow(): boolean {
    if (
      !this.auth.isAuthenticated
      || (this.auth.myRole === UserRole.student)
      || (this.auth.myRole === UserRole.recruiter && this.activatedRoute.snapshot.queryParams.student)
    ) {
      return true;
    }

    return false;
  }

  apply(id: number): void {
    if (!this.auth.me) {
      this.notification.addToQueue(this.translate.transform(`MESSAGE.YOU_ARE_NOT_LOGGED`), SnackBarNotificationType.warning, {
        duration: 10000
      });
      this.saveCurrentSession.saveSession();
      this.router.navigate(['', 'auth', 'log-in']);
      return;
    }

    const httpParams: HttpParams = this.auth.myRole === UserRole.recruiter && this.activatedRoute.snapshot.queryParams.student
      ? new HttpParams({
        fromObject: {
          student_id: this.activatedRoute.snapshot.queryParams.student,
        },
        encoder: new CustomHTTPParamsEncoder()
      })
    : new HttpParams();

    this.accountService.joinProgram(id, { params: httpParams }, { skipErrorNotification: true }).subscribe(
      (response: any): void => {
        this.notification.addToQueue(
          this.translate.transform(`APPLICATION.APP_HAS_BEEN_SUCCESSFULLY_CREATED`),
          SnackBarNotificationType.success
        );
        this.forceMakeApplied.emit();
        if (this.auth.myRole === UserRole.student) {
          this.router.navigate(['account', 'application-details', id]);
        }
      },
      (error: any): void => {
        Object.values(error.error.errors).map((message: string): void => {
          this.notification.addToQueue(message, SnackBarNotificationType.error);
        });
      }
    );
  }
}

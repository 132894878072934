import { Component, Input } from '@angular/core';
import { IProgramDetail } from '@models/interfaces/program-detail.interface';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'program-tab-facts',
  templateUrl: './program-tab-facts.component.html',
  styleUrls: ['./program-tab-facts.component.scss']
})
export class ProgramTabFactsComponent {
  readonly key: string = 'PAGE_DETAIL.';
  @Input() program: IProgramDetail;
  courseDurationFieldLabels: { [key: string]: string } = {
    years: 'PROGRAM.COURSE_DURATION_YEAR',
    months: 'PROGRAM.COURSE_DURATION_MONTHS',
    weeks: 'PROGRAM.COURSE_DURATION_WEEKS',
    days: 'PROGRAM.COURSE_DURATION_DAYS',
    freewriting: 'PROGRAM.COURSE_DURATION_FREEWRITING'
  };

  constructor(private translate: TranslatePipe) {}

  courseDurationField(): string {
    if (this.program?.duration) {
      const fieldsOrder: string[] = ['years', 'months', 'weeks', 'days', 'freewriting'];

      return fieldsOrder
        .map((item: string): string => {
          if (item in this.program?.duration && this.program?.duration[item] && item in this.courseDurationFieldLabels) {
            return `${this.translate.transform(this.courseDurationFieldLabels[item])} ${this.program.duration[item]}`;
          }
          return null;
        })
        .filter((item: string): boolean => !!item)
        .join(', ');
    }

    return '';
  }
}

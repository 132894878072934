import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { DialogService } from '@services/dialog/dialog.service';
import { CountdownVideoPopupComponent } from '@shared/components/countdown-timer/components/countdown-video-popup/countdown-video-popup.component';
import { getYoutubeVideoLink } from '@misc/helpers/get-youtube-video-link';

@Component({
  selector: 'countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject<void>();
  timerInterval: Observable<string>;
  @Input() date: string;
  @Input() video: string;
  // @Input() endDate: string;
  // @Input() startTimeSelling: string;
  @Input() title: string;

  constructor(private dialog: DialogService) {}

  ngOnInit(): void {
    if (this.canSelling) {
      this.timerInterval = timer(0, 1000).pipe(
        takeUntil(this.destroyed$),
        map((): string => this.timerChange())
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get canSelling(): boolean {
    return moment(this.date, 'YYYY-MM-D') > moment();
  }

  get sellDayIsNow(): boolean {
    return !this.canSelling && !moment(this.date, 'YYYY-MM-D').diff(moment(), 'days');
  }

  timerChange(): string {
    const start: moment.Moment = moment(this.date, 'YYYY-MM-D');
    const end: moment.Moment = moment();
    const days: number = start.diff(end, 'days');
    end.add(days, 'days');
    const hours: number = start.diff(end, 'hours');
    end.add(hours, 'hours');
    const minutes: number = start.diff(end, 'minutes');
    end.add(minutes, 'minutes');

    return `${days}D ${hours}H ${minutes}M`;
  }

  openPopup(): void {
    this.dialog.open({
      width: '95vw',
      height: '95vh',
      data: {
        video: getYoutubeVideoLink(this.video)
      }
    },
      CountdownVideoPopupComponent
    ).subscribe();
  }

}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private config: MatDialogConfig = {
    width: '300px',
    height: 'auto',
    backdropClass: '',
    hasBackdrop: true,
    panelClass: 'custom-dialog',
    position: {
      top: '',
      right: '',
      bottom: '',
      left: ''
    },
    data: {}
  };

  constructor(private dialog: MatDialog, private overlay: Overlay) { }

  public open<T>(config: any, component: ComponentType<any>): Observable<T> {
    config = {
      ...this.config,
      ...config
    };
    const dialogRef: any = this.dialog.open(component, config);

    return dialogRef.afterClosed();
  }

}

<h1 class="title">{{ key + 'TITLE' | translate }}</h1>
<main-stepper
  [steps]="stepList"
  (stepChange)="activeStep = $event"
  [fields]='progressFields'
  class="m-b-5"
></main-stepper>
<ng-container *ngIf="showForm">
  <ng-container [ngSwitch]="activeStep?.key">
    <ng-container *ngSwitchCase="studentProfile.general_information">
      <general-info-form [inputForm]="studentForm"></general-info-form>
    </ng-container>
    <ng-container *ngSwitchCase="studentProfile.educator_history">
      <educator-history-form [inputForm]="studentForm"></educator-history-form>
    </ng-container>
    <ng-container *ngSwitchCase="studentProfile.test_scores">
      <test-scores-form [inputForm]="studentForm"></test-scores-form>
    </ng-container>
    <ng-container *ngSwitchCase="studentProfile.background_information">
      <background-info-form [inputForm]="studentForm"></background-info-form>
    </ng-container>
    <ng-container *ngSwitchCase="studentProfile.documents">
      <documents-form [inputForm]="studentForm"></documents-form>
    </ng-container>
  </ng-container>
</ng-container>

import { NgModule } from '@angular/core';
import { YoutubeVideoComponent } from '@shared/components/youtube-video/youtube-video.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  declarations: [YoutubeVideoComponent],
  imports: [CommonModule, PipesModule],
  exports: [YoutubeVideoComponent]
})
export class YoutubeVideoModule {}

import { NgModule } from '@angular/core';
import { BaseAutocompleteComponent } from '@forms/base-autocomplete/base-autocomplete.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [BaseAutocompleteComponent],
    imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
    exports: [BaseAutocompleteComponent]
})
export class BaseAutocompleteModule {}

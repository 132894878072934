<footer class="footer">
  <div class="container">
    <div class="footer__row">
      <div class="footer__col footer__col_three-line">
        <footer-nav-item [linkList]="participantsLinkList"></footer-nav-item>
      </div>
      <div class="footer__col footer__col_three-line">
        <footer-nav-item [linkList]="aboutLinkList"></footer-nav-item>
      </div>
      <div class="footer__col footer__col_three-line">
        <footer-nav-item [linkList]="infoLinkList"></footer-nav-item>
      </div>
      <div class="footer__col footer__col_greedy footer__logo-holder">
        <a [routerLink]="['/']" class="footer__logo">
          <img src="/assets/img/svg/logo_white.svg" alt="" />
        </a>
        <button routerLink="/start-here" mat-raised-button>{{ 'COMMON.START_HERE' | translate }}</button>
      </div>
      <div class="footer__col footer__col_two-line">
        <footer-nav-item [linkList]="productLinkList"></footer-nav-item>
      </div>
      <div class="footer__col footer__col_two-line">
        <footer-nav-item [linkList]="legalLinkList"></footer-nav-item>
      </div>
    </div>
    <div class="footer__row align-end">
      <div class="footer__col footer__col_greedy">
        <h3 class="footer__title">{{ key + 'SOCIAL_TITLE_LABEL' | translate }}</h3>
        <ul class="social-list">
          <li *ngFor="let item of socialLinks" class="social-list__item">
            <a [href]="item.href" [target]="item.target" class="social-list__link">
              <mat-icon [svgIcon]="item.svgIcon" class="social-list__icon"></mat-icon>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__col footer__copyright">
        <p>© 2020 ApplyClever</p>
      </div>
    </div>
  </div>
</footer>

export function valueIsNumber(value: any): boolean {
  if (typeof value === 'number') {
    return true;
  }

  if (typeof value === 'string') {
    if (value.trim()) {
      return !isNaN(+value);
    }
    return false;
  }

  return false;
}

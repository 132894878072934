import { Component, Input } from '@angular/core';
import { INews } from '@models/interfaces/news.interface';
import { DialogService } from '@services/dialog/dialog.service';
import { NewsItemPopupComponent } from '@shared/components/news-item/news-item-popup/news-item-popup.component';

@Component({
  selector: 'news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent {

  @Input() news: INews;

  constructor(private dialog: DialogService) { }

  readNews(): void {
    this.dialog
      .open({
        width: '90vw',
        height: '90vh',
        data: {
          newsId: this.news.id
        }
      }, NewsItemPopupComponent)
      .subscribe();
  }
}

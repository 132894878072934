import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramPreviewComponent } from '@shared/components/program-preview/program-preview.component';

@NgModule({
  declarations: [ProgramPreviewComponent],
  imports: [CommonModule],
  exports: [ProgramPreviewComponent]
})
export class ProgramPreviewModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FileUploadingComponent } from '@shared/components/file-uploading/file-uploading.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FileUploadingComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, TranslateModule, MatTooltipModule],
  exports: [FileUploadingComponent]
})
export class FileUploadingModule {}

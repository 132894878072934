import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/material/material.module';
import { AppHeaderComponent } from '@shared/components/app-header/app-header.component';
import { RouterModule } from '@angular/router';
import { MegaMenuModule } from 'primeng/megamenu';
import { ButtonModule } from 'primeng/button';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [AppHeaderComponent],
  imports: [CommonModule, PipesModule, TranslateModule, MaterialModule, MegaMenuModule, RouterModule, ButtonModule, MatBadgeModule],
  exports: [AppHeaderComponent]
})
export class AppHeaderModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MapGoogleComponent } from '@shared/components/map-google/map-google.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MapGoogleComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule
  ],
  exports: [MapGoogleComponent]
})
export class MapGoogleModule {}

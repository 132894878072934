import { Observable, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

export interface ISettingsResponses {
  manager(): Observable<HttpResponse<Array<{ avatar: string; name: string }>>>;
  promotions(): Observable<HttpResponse<Array<{ avatar: string; name: string }>>>;
}

export interface IPromotionsItem {
  name: string;
  price: number;
  currency: string;
}

export const settingsResponses: ISettingsResponses = {
  manager(): Observable<HttpResponse<any>> {
    const items: Array<{ avatar: string; name: string }> = [
      {
        avatar: 'https://i.picsum.photos/id/1027/200/200.jpg?hmac=fiXlkLLwYm7JmmU80uRIj9g21XD4q9v_lM_2Z57UhuA',
        name: 'Alexa Alanna'
      },
      {
        avatar: 'https://i.picsum.photos/id/459/200/200.jpg?hmac=WxFjGfN8niULmp7dDQKtjraxfa4WFX-jcTtkMyH4I-Y',
        name: 'Cheryl Christie'
      },
      {
        avatar: 'https://i.picsum.photos/id/881/200/200.jpg?hmac=34beeNIxYSbYK-_PTy_YXvWyn11npGQSygCM7hjOUFo',
        name: 'Janice Janis'
      }
    ];

    return of(
      new HttpResponse({
        status: 200,
        body: items[Math.floor(Math.random() * items.length)]
      })
    );
  },
  promotions(): Observable<HttpResponse<any>> {
    const promotions: IPromotionsItem[] = [
      {
        name: 'VISA Application',
        price: 120,
        currency: 'EUR'
      },
      {
        name: 'Personal CV and interview preparation',
        price: 435,
        currency: 'USD'
      },
      {
        name: 'Scholarship applications',
        price: 867,
        currency: 'CAD'
      },
      {
        name: 'Help with your student income and tax planning?',
        price: 86789,
        currency: 'GBP'
      }
    ];
    return of(
      new HttpResponse({
        status: 200,
        body: promotions
      })
    );
  }
};

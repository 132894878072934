<ngu-carousel #myCarousel *ngIf="items?.length" [inputs]="config" [dataSource]="items">
  <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate">
    <ng-container
      *ngTemplateOutlet="galleryItemTemplate ? galleryItemTemplate : defaultSlideItem; context: { $implicit: item, index: i }"
    ></ng-container>
  </ngu-tile>

  <div NguCarouselPrev>
    <ng-container *ngTemplateOutlet="galleryBtnPrevTemplate ? galleryBtnPrevTemplate : defaultBtnPrev"></ng-container>
  </div>
  <div NguCarouselNext>
    <ng-container *ngTemplateOutlet="galleryBtnNextTemplate ? galleryBtnNextTemplate : defaultBtnNext"></ng-container>
  </div>
</ngu-carousel>

<ng-template #defaultSlideItem let-item>
  {{ item | json }}
</ng-template>

<ng-template #defaultBtnNext>
  <button mat-button class="ngu-carousel-action-btn ngu-carousel-action-btn-right">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</ng-template>
<ng-template #defaultBtnPrev>
  <button mat-button class="ngu-carousel-action-btn ngu-carousel-action-btn-left">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</ng-template>

import { Component, OnInit, Input } from '@angular/core';
import { SchoolingService } from '@services/api/schooling/schooling.service';
import { ActivatedRoute } from '@angular/router';
import { IProgramDetail } from '@models/interfaces/program-detail.interface';
import { valueIsNumber } from '@misc/helpers/value-is-number';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss']
})
export class ProgramDetailsComponent implements OnInit  {
  readonly key: string = 'PAGE_DETAIL.';
  programDetail: IProgramDetail;
  activeTabIndex: number = 0;

  courseDurationFieldLabels: { [key: string]: string } = {
    years: 'PROGRAM.COURSE_DURATION_YEAR',
    months: 'PROGRAM.COURSE_DURATION_MONTHS',
    weeks: 'PROGRAM.COURSE_DURATION_WEEKS',
    days: 'PROGRAM.COURSE_DURATION_DAYS',
    freewriting: 'PROGRAM.COURSE_DURATION_FREEWRITING'
  };

  @Input() idProgram: number;

  constructor(private schoolingService: SchoolingService, private activatedRoute: ActivatedRoute, private translate: TranslatePipe) {
  }

  ngOnInit(): void {
    this.fetchProgram();
  }

  fetchProgram(): void {
    const programId: number = this.idProgram ?? this.activatedRoute.snapshot.params?.id;
    if (programId) {
      this.schoolingService
        .fetchProgramsById(programId, {...this.activatedRoute.snapshot.queryParams})
        .subscribe((program: IProgramDetail): void => {
          this.programDetail = program;
        });
    }
  }

  checkNumberType(value: any): boolean {
    return valueIsNumber(value);
  }

  selectedIndexHandle(event: number): void {
    this.activeTabIndex = event;
  }

  courseDurationField(): string {
    if (this.programDetail?.duration) {
      const fieldsOrder: string[] = ['years', 'months', 'weeks', 'days', 'freewriting'];

      return fieldsOrder
        .map((item: string): string => {
          if (item in this.programDetail?.duration && this.programDetail?.duration[item] && item in this.courseDurationFieldLabels) {
            return `${this.translate.transform(this.courseDurationFieldLabels[item])} ${this.programDetail.duration[item]}`;
          }
          return null;
        })
        .filter((item: string): boolean => !!item)
        .join(', ');
    }

    return '';
  }

  forceMakeApplied(): void {
    this.programDetail = {...this.programDetail, applied: true};
  }
}

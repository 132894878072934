<div class="auth-nav">
  <div class="auth-nav__item">
    <a
      routerLink="/auth/sign-up"
      routerLinkActive="auth-nav__link_active"
      mat-button
      class="auth-nav__link mid-size-btn normal-font-weight"
    >
      {{ key + 'SIGN_UP_BTN_LABEL' | translate }}
    </a>
  </div>
  <div class="auth-nav__item">
    <a
      routerLink="/auth/log-in"
      routerLinkActive="auth-nav__link_active"
      mat-button
      class="auth-nav__link mid-size-btn normal-font-weight"
    >
      {{ key + 'LOGIN_BTN_LABEL' | translate }}
    </a>
  </div>
</div>

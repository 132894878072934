<label class="base-select">
  <mat-form-field class="base-select__field" [floatLabel]="floatLabel" [appearance]="appearance">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <mat-select [id]="id" [placeholder]="placeholder" [formControl]="formControl" [required]="required">
      <mat-select-trigger *ngIf="triggerTemplate">
        <ng-container *ngTemplateOutlet="triggerTemplate; context: { $implicit: control.value }"></ng-container>
      </mat-select-trigger>
      <ng-container *ngIf="options">
        <mat-option *ngFor="let option of options" [value]="getValue(option)">
          <ng-container *ngIf="!optionTemplate; else withTemplate">{{ getTitle(option) }}</ng-container>
          <ng-template #withTemplate>
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
          </ng-template>
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-icon *ngIf='formControl.value' matSuffix (click)='formControlReset($event)'>clear</mat-icon>
    <mat-error *ngIf="control && control.invalid && errorMessage">{{ errorMessage }}</mat-error>
  </mat-form-field>
</label>

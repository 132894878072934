<label>
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-icon *ngIf="icon" matPrefix>{{ icon }}</mat-icon>
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [required]="required"
      [readonly]="readonly"
    ></textarea>
    <mat-error *ngIf="control?.invalid && errorMessage">{{ errorMessage }}</mat-error>
  </mat-form-field>
</label>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/material/material.module';
import { StudentProfileDetailComponent } from '@shared/components/student-profile-detail/student-profile-detail.component';

@NgModule({
  declarations: [StudentProfileDetailComponent],
  imports: [CommonModule, TranslateModule, MaterialModule],
  exports: [StudentProfileDetailComponent]
})
export class StudentProfileDetailModule {}

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BaseFormFieldAbstractComponent } from '@misc/abstracts/base-form-field.abstract.component';

export interface IRadio {
  label?: string;
  value: boolean | string;
}

@Component({
  selector: 'base-radio-group',
  templateUrl: './base-radio-group.component.html',
  styleUrls: ['./base-radio-group.component.scss']
})
export class BaseRadioGroupComponent extends BaseFormFieldAbstractComponent {
  @Input() radioOption: IRadio[] = [];
  @Input() radioButtonTemplate: TemplateRef<any>;
}

import { NgModule } from '@angular/core';
import { StripeComponent } from '@shared/components/stripe/stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material/material.module';
import { PipesModule } from '@pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { environment } from '@env/environment';

@NgModule({
  declarations: [StripeComponent],
  imports: [
    NgxStripeModule.forRoot(environment.stripe_public_key),
    TranslateModule,
    MaterialModule,
    CommonModule,
    PipesModule,
    FormsModule
  ],
  exports: [StripeComponent]
})
export class StripeModule {}

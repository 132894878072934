import { Component, Input } from '@angular/core';
import { DialogService } from '@services/dialog/dialog.service';
import { StudentProfileDetailComponent } from '@shared/components/student-profile-detail/student-profile-detail.component';

@Component({
  selector: 'show-student-profile-popup',
  templateUrl: './show-student-profile-popup.component.html',
  styleUrls: ['./show-student-profile-popup.component.scss']
})
export class ShowStudentProfilePopupComponent {

  @Input() studentId: number;

  constructor(private dialog: DialogService) { }

  openModal(): void {
    if (!this.studentId) {
      throw Error('Student identifier didn\'t find. Make sure that field "studentId"  in the component has a value not null or undefined');
      return;
    }
    this.dialog.open({
      width: '950px',
      data: {
        id: this.studentId
      }
    }, StudentProfileDetailComponent).subscribe();
  }
}

<label class="base-input">
  <mat-form-field [appearance]="appearance" class="base-input__field">
    <mat-label *ngIf="placeholder">
      {{ placeholder }}
      <span *ngIf='isRequiredControl()' class=''>*</span>
    </mat-label>
    <mat-icon matPrefix *ngIf="icon">{{ icon }}</mat-icon>
    <input
      matInput
      [type]="type"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [required]="required"
      [readonly]="readonly"
      [autocomplete]="autocomplete"
    />
    <div *ngIf='customPrefix' matSuffix>
      <ng-container *ngTemplateOutlet='customPrefix'></ng-container>
    </div>
    <button *ngIf="isButtonShown" [disabled]="disabled" mat-icon-button matSuffix tabindex="-1" type="button" (click)="togglePassword()">
      <mat-icon class="base-input__right-icon">{{ buttonIcon }}</mat-icon>
    </button>
    <button
      *ngIf="showClearBtn && formControl.value"
      [disabled]="disabled"
      mat-icon-button
      matSuffix
      tabindex="-1"
      type="button"
      (click)="formControl.reset()"
    >
      <mat-icon class="base-input__right-icon">clear</mat-icon>
    </button>
    <mat-error *ngIf="control?.invalid && errorMessage">{{ errorMessage }}</mat-error>
  </mat-form-field>
</label>

import { Component, Inject, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@misc/custom-validators';
import { youtubeVideoLinkRegex } from '@misc/reg-pattern';
import { getYoutubeVideoLink } from '@misc/helpers/get-youtube-video-link';

@Component({
  selector: 'add-youtube-link-popup',
  templateUrl: './add-youtube-link-popup.component.html',
  styleUrls: ['./add-youtube-link-popup.component.scss']
})
export class AddYoutubeLinkPopupComponent extends BaseFormAbstractComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formInit();
  }

  get placeholder(): string {
    return this.data.placeholder || 'ACCOUNT_SCHOOL.ADD_VIRTUAL_TOUR_LINK';
  }

  get title(): string {
    return this.data.title || 'ACCOUNT_SCHOOL.VIRTUAL_TOUR_MODAL_TITLE';
  }

  formInit(): void {
    this.formGroup = this.fb.group({
      video: this.fb.control(
        '',
        [Validators.required, CustomValidators.youtubeVideoLink, Validators.pattern(youtubeVideoLinkRegex)])
    });
  }

  getFormValue(): { video: string } {
    return { video: getYoutubeVideoLink(this.getControl('video').value) };
  }
}

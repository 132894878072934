<h2 *ngIf='program?.school?.name' class="program__name m-b-4">{{ program?.school?.name }}</h2>
<span *ngIf='program?.school?.main_campus_address' class="program__location">
  <mat-icon svgIcon='location'></mat-icon>
  {{ program.school.main_campus_address }}
</span>
<ul class="program__info-list">
  <li *ngIf='program?.days_left_to_apply' class="program__info-list-item">
    <mat-icon svgIcon="check-circle-outline-custom"></mat-icon>
    <b>{{ program?.days_left_to_apply }}</b>days left to apply
  </li>
  <li *ngIf='program?.places_left' class="program__info-list-item">
    <mat-icon svgIcon="check-circle-outline-custom"></mat-icon>
    <b>{{ program?.places_left }}</b>places left
  </li>
</ul>
<school-info-card
  [title]="key + 'PROGRAMS_DESCRIPTION_TAB_LABEL' | translate"
  extraClass="no-side-padding"
  [noShadow]="true"
>
  <ng-container *ngIf='program?.description'>
    <p [innerHTML]='program?.description'></p>
  </ng-container>
</school-info-card>

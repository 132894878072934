import { Component, Input, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'documents-form',
  templateUrl: './documents-form.component.html',
  styleUrls: ['./documents-form.component.scss']
})
export class DocumentsFormComponent extends BaseFormAbstractComponent {
  readonly key: string = 'ACCOUNT_STUDENT.';
  @Input() inputForm: FormGroup = new FormGroup({});

  getDocumentGroup(): FormGroup {
    return this.getGroup('documents', this.inputForm);
  }
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseTextareaModule } from '@forms/base-textarea/base-textarea.module';
import { BaseBooleanFieldModule } from './base-boolean-field/base-boolean-field.module';
import { BaseDatepickerModule } from './base-datepicker/base-datepicker.module';
import { BaseInputModule } from './base-input/base-input.module';
import { BaseSelectModule } from './base-select/base-select.module';
import { InputSliderModule } from './input-slider/input-slider.module';
import { BaseAutocompleteModule } from '@forms/base-autocomplete/base-autocomplete.module';
import { BaseMultiAutocompleteModule } from '@forms/base-multi-autocomplete/base-multi-autocomplete.module';
import { BaseRadioGroupModule } from '@forms/base-radio-group/base-radio-group.module';

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    BaseInputModule,
    BaseBooleanFieldModule,
    BaseDatepickerModule,
    BaseSelectModule,
    BaseTextareaModule,
    InputSliderModule,
    BaseAutocompleteModule,
    BaseMultiAutocompleteModule,
    BaseRadioGroupModule
  ],
  declarations: []
})
export class AppFormsModule {}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AccountLayoutComponent } from '@layouts/account/account-layout.component';
import { AccountSidebarModule } from '@shared/components/account-sidebar/account-sidebar.module';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [AccountLayoutComponent],
  imports: [
    RouterModule,
    AccountSidebarModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatSidenavModule,
    TranslateModule,
    MatTooltipModule
  ],
  exports: [AccountLayoutComponent]
})
export class AccountLayoutModule {}

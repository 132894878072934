import { Inject, Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@misc/abstracts/api-base.abstract.service';
import { ClassType } from 'class-transformer/ClassTransformer';
import { HttpParams } from '@angular/common/http';
import { CustomHTTPParamsEncoder } from '@misc/custom-http-params-encoder';
import { Params } from '@angular/router';
import { HttpService, IServicesConfig } from '@services/http/http.service';
import { forkJoin, Observable } from 'rxjs';
import { ISchooling } from '@models/interfaces/schooling.interface';
import { List } from '@models/classes/_base.model';
import { FilterService } from '@services/filter/filter.service';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { PathParsePipe } from '@pipes/path-parse/path-parse.pipe';
import { ISchoolDetail } from '@models/interfaces/school-detail.interface';
import { IProgramDetail } from '@models/interfaces/program-detail.interface';

@Injectable({
  providedIn: 'root'
})
export class SchoolingService extends ApiBaseAbstractService<ISchooling> {
  protected readonly URLPath: string = '/api/v1/';
  protected readonly model: ClassType<ISchooling>;

  constructor(
    @Inject(APP_CONFIG) config: IAppConfig,
    http: HttpService,
    pathParsePipe: PathParsePipe,
    private filterService: FilterService
  ) {
    super(config, http, pathParsePipe);
  }

  private getParams(params?: Params): HttpParams {
    return new HttpParams({ fromObject: params, encoder: new CustomHTTPParamsEncoder() });
  }

  fetchSchool(params?: Params, servicesConfig?: IServicesConfig): Observable<List<ISchooling>> {
    const httpParams: Params = {
      ...this.filterService.getSchoolFiltersOptions(),
      ...this.filterService.getProgramFiltersOptions(),
      ...params
    };
    const servicesConf: IServicesConfig = {
      skipErrorNotification: true,
      ...servicesConfig
    };

    const resultParams: {params?: HttpParams} = { params: this.getParams({ ...httpParams }) };

    return this.http.get(`${this.url}schools`, resultParams, servicesConf);
  }

  fetchSchoolById(id: number, servicesConfig?: IServicesConfig): Observable<ISchoolDetail> {
    const servicesConf: IServicesConfig = {
      skipErrorNotification: true,
      ...servicesConfig
    };
    return this.http.get(`${this.url}schools/${id}`, {}, servicesConf);
  }

  fetchPrograms(params?: Params, servicesConfig?: IServicesConfig): Observable<List<ISchooling>>  {
    const httpParams: Params = {
      ...this.filterService.getProgramFiltersOptions(),
      ...this.filterService.getSchoolFiltersOptions(),
      ...params
    };
    const servicesConf: IServicesConfig = {
      skipErrorNotification: true,
      ...servicesConfig
    };

    const resultParams: {params?: HttpParams} = { params: this.getParams({ ...httpParams }) };
    return this.http.get(`${this.url}programs`, resultParams, servicesConf);
  }

  fetchProgramsById(id: number, params?: any, servicesConfig?: IServicesConfig): Observable<IProgramDetail> {
    const servicesConf: IServicesConfig = {
      skipErrorNotification: true,
      ...servicesConfig
    };
    return this.http.get(`${this.url}programs/${id}`, {params}, servicesConf);
  }

  fetchData(params?: Params, servicesConfig?: IServicesConfig): Observable<Array<List<ISchooling>>> {
    return forkJoin([
      this.fetchSchool(params, servicesConfig),
      this.fetchPrograms(params, servicesConfig)
    ]);
  }
}

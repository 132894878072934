import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MediaBreakpoints } from '@models/enums/media-breakpoints.enum';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaMatcherService {

  constructor(private mediaMatcher: MediaMatcher, private breakpointObserver: BreakpointObserver) {}

  get breakpointMatcherObserve(): Observable<BreakpointState> {
    return this.breakpointObserver.observe([
      MediaBreakpoints.tabletS,
      MediaBreakpoints.tabletM,
      MediaBreakpoints.tabletL,
      MediaBreakpoints.desktopM,
      MediaBreakpoints.desktopL
    ]);
  }

  breakpointByMediaIsMatched$(media: MediaBreakpoints): Observable<boolean> {
    return this.breakpointObserver.observe(media).pipe(map((state: BreakpointState): boolean => state.matches));

  }

  breakpointIsMatched(media: MediaBreakpoints): boolean {
    return this.breakpointObserver.isMatched(media);
  }
}

import { NgModule } from '@angular/core';
import { ShowStudentProfilePopupComponent } from '@shared/components/show-student-profile-popup/show-student-profile-popup.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StudentProfileDetailModule } from '@shared/components/student-profile-detail/student-profile-detail.module';
import { MaterialModule } from '@shared/material/material.module';

@NgModule({
  declarations: [ShowStudentProfilePopupComponent],
  imports: [CommonModule, TranslateModule, StudentProfileDetailModule, MaterialModule],
  exports: [ShowStudentProfilePopupComponent]
})
export class ShowStudentProfilePopupModule {}

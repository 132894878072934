import { Component, Input } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { switchMap } from 'rxjs/operators';
import { StripeService } from 'ngx-stripe';
import { StripeApiService } from '@services/api/stripe/stripe-api.service';
import { SnackBarNotificationType } from '@models/enums/snack-bar-notification-type.enum';
import { NotificationService } from '@services/notification/notification.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent {
  @Input() stroked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() data: object = {};

  constructor(
    private http: HttpService,
    private stripeService: StripeService,
    private stripeApiService: StripeApiService,
    private notification: NotificationService,
    private translate: TranslatePipe
  ) {}

  checkout(): void {
    this.stripeApiService
      .createCheckoutSession(this.data)
      .pipe(
        switchMap((session: any): any => {
          return this.stripeService.redirectToCheckout({ sessionId: session.id });
        })
      )
      .subscribe((result: any): void => {
        if (result.error) {
          this.notification.addToQueue(result.error.message, SnackBarNotificationType.error);
        }
      });
  }
}

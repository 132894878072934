import { ClassType } from 'class-transformer/ClassTransformer';
import { convertToModelsArray } from '@misc/helpers/model-conversion/convert-to-models-array';

export interface IMeta{
  count?: number;
  current_page?: number;
  per_page?: number;
  total?: number;
  total_pages?: number;
}

export class List<T = any> {
  data: T[];
  meta?: IMeta;

  constructor({ data = [], meta = null }: List<T> = { data: [], meta: null }, entityClass: ClassType<T>) {
    this.data = convertToModelsArray(data, entityClass);
    this.meta = meta;
  }
}

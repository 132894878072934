<mat-form-field class="base-multi-autocomplete" [appearance]="appearance">
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip *ngFor="let item of chipsControl.value" (removed)="remove(item)">
      {{ chipDisplayWith(item) }}
      <mat-icon matChipRemove *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
    </mat-chip>
    <input
      #autoCompleteInput
      [placeholder]="placeholder"
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [disabled]="disabled"
      [matChipInputFor]="chipList"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of options" [value]="option" [disabled]="isDisabled(option)">
      {{ autocompleteItemDisplayWith(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

import { Component, Input } from '@angular/core';
import { youtubeVideoLinkRegex } from '@app/misc/reg-pattern';
import { IProgramDetail } from '@models/interfaces/program-detail.interface';
import { prepareYoutubeVideoLink } from '@misc/helpers/get-youtube-video-link';

@Component({
  selector: 'testimonial-item',
  templateUrl: './testimonial-item.component.html',
  styleUrls: ['./testimonial-item.component.scss']
})
export class TestimonialItemComponent {

  @Input() program: IProgramDetail;

  getYoutubeLink(url?: string): string {
    if (!url) {
      return null;
    }
    return prepareYoutubeVideoLink(url);
  }

}

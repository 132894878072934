import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IBaseStepperStatus } from '@models/interfaces/base-stepper-status.interface';
import { BaseStepperStatusEnum } from '@models/enums/base-stepper-status.enum';
import { UserFormStepperService } from '@services/user-form-stepper/user-form-stepper.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { IProgressStepRequest, IProgressStepResponse } from '@models/interfaces/progress-steps-status.interface';
import { ProgressStepsStatus } from '@models/enums/progress-steps-status.enum';
import { AuthService } from '@services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { UserRole } from '@models/enums/user-role.enum';

@Component({
  selector: 'main-stepper',
  templateUrl: './main-stepper.component.html',
  styleUrls: ['./main-stepper.component.scss']
})
export class MainStepperComponent implements OnInit, OnDestroy {
  @Input() steps: IBaseStepperStatus[] = [];
  @Input() fields: IProgressStepRequest;
  @Output() stepChange: EventEmitter<IBaseStepperStatus> = new EventEmitter();

  updatingStepsStatusSubscription: Subscription;
  totalPercent: number = 0;

  statusStep: typeof BaseStepperStatusEnum = BaseStepperStatusEnum;

  constructor(
    private formStepperService: UserFormStepperService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) {
  }

  get studentId(): number | null {
    if (this.auth.myRole === UserRole.recruiter) {
      return this.activatedRoute.snapshot?.params?.id ?? null;
    }
    return null;
  }

  ngOnInit(): void {
    this.findActiveStep();
    this.updatingStepsStatusSubscription = this.formStepperService.updatingStepsStatus.subscribe((): void => {
      if (this.fields) {
        this.formStepperService.getStepStatus(this.fields, this.studentId).subscribe((data: IProgressStepResponse): void => {
          this.totalPercent = data.total_percent;
          this.steps = this.steps.map((item: IBaseStepperStatus): IBaseStepperStatus => {
            if (data.hasOwnProperty(item.key)) {
              return {
                ...item,
                status: (data[item.key] ?? {} as IProgressStepResponse).status ?? ProgressStepsStatus.untouched
              };
            }
            return { ...item };
          });
        });
      }
    });
  }

  findActiveStep(): void {
    if (this.steps?.length) {
      let activeStep: IBaseStepperStatus = this.steps.find((item: IBaseStepperStatus): boolean => item.active);
      if (!activeStep) {
        this.steps[0].active = true;
        activeStep = this.steps[0];
      }
      this.stepChange.emit(activeStep);
    }
  }

  getCurrentIcon(status: BaseStepperStatusEnum): string {
    if (status === BaseStepperStatusEnum.complete) {
      return 'done';
    }
    if (status === BaseStepperStatusEnum.touched) {
      return 'warning_amber';
    }
    return '';
  }

  stepActionHandler(step: IBaseStepperStatus): void {
    this.steps.forEach((item: IBaseStepperStatus): void => {
      item.active = item.key === step.key;
    });
    this.stepChange.emit(step);
  }

  ngOnDestroy(): void {
    this.updatingStepsStatusSubscription?.unsubscribe();
  }
}

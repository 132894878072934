import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { IProfileFormFieldItem } from '@models/interfaces/profile.interface';
import { ControlType } from '@models/enums/control-type.enum';
import { IOption } from '@models/interfaces/forms/option.interface';

export function dynamicAddControlByTypeFunction(form: FormGroup, fieldList: IProfileFormFieldItem[], data: any = {}): void {
  fieldList.map((item: IProfileFormFieldItem): void => {
    const { name, validators, controlType, getValue }: IProfileFormFieldItem = item;
    if (!controlType || controlType === ControlType.control ) {
      addControl(name, validators, getValue);
    }
    if (controlType === ControlType.array) {
      addArray(name, validators);
    }
    if (controlType === ControlType.group) {
      addGroup(name, item);
    }
  });

  function addControl(
    name: string, validators?: ValidatorFn[],
    getValue: (content: any) => any = (content: any): any => content,
    currentForm: FormGroup = form
  ): void {
    const setData: (controlData: any) => IOption | any = (controlData: any): IOption | any => {
      if ((controlData?.hasOwnProperty('id') || controlData?.hasOwnProperty('value')) && controlData?.hasOwnProperty('name')) {
        return {
          label: controlData.name,
          value: controlData.id ?? controlData.value
        };
      }

      return controlData;
    };
    currentForm.addControl(name, new FormControl(getValue(setData(data[name])), [...(validators || [])]));
  }

  function addArray(name: string, validators?: ValidatorFn[]): void {

    if (!data[name]) {
      form.addControl(name, new FormArray([]));
      return;
    }

    if (Array.isArray(data[name])) {
      form.addControl(name, new FormArray(data[name].map((value: any): FormControl => {
        if (typeof value === 'string') {
          return new FormControl(value, [...(validators || [])]);
        }

        return new FormControl({
          value: value?.id || value?.key,
          label: value?.name
        }, [...(validators || [])]);
      })));
      return;
    }

    throw Error(`You try to create array control. The expected value of control as an array, but got as ${typeof data[name]}`);
  }

  function addGroup(name: string, group: IProfileFormFieldItem): void {
    const tempGroup: FormGroup = new FormGroup({});
    group?.controls.map((control: { name: string; validators?: ValidatorFn[]; }): void => {
      const currentValue: any = data[name] ? data[name][control.name] : null;
      tempGroup.addControl(control.name, new FormControl(currentValue, control.validators ?? []));
    });
    form.addControl(name, tempGroup);
  }
}

<h2 class="paid-product-table__title">{{ tableTitle | translate }}</h2>
<data-table [columns]="productsColumns" [list]="productsList" emptyIcon=""></data-table>

<ng-template #descriptionCell let-item let-entity="entity">
  <ng-container *ngIf="entity.file?.name; else descriptionTemplate">
    <a [href]="entity.file.url" target="_blank">{{ entity.file.name }}</a>
  </ng-container>
  <ng-template #descriptionTemplate>
    <span>{{ entity.description }}</span>
  </ng-template>
</ng-template>

<ng-template #productsActionCell let-item let-entity="entity">
  <div class="w-100 flex-content-end">
    <stripe [data]="{ type: 'service', entity_id: entity.id }"></stripe>
  </div>
</ng-template>

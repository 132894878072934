import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ProgramDetailsComponent } from '@modules/main/common/program-details/program-details.component';
import { ProgramPreviewModule } from '@shared/components/program-preview/program-preview.module';
import { SchoolInfoCardModule } from '@shared/components/school-info-card/school-info-card.module';
import { MaterialModule } from '@shared/material/material.module';
import { YoutubeVideoModule } from '@shared/components/youtube-video/youtube-video.module';
import { ProgramDescriptionComponent } from './program-description/program-description.component';
import { ProgramRequirementsCandidateComponent } from './program-requirements-candidate/program-requirements-candidate.component';
import { ProgramTabFactsComponent } from './program-tab-facts/program-tab-facts.component';
import { TestimonialItemComponent } from './testimonial-item/testimonial-item.component';
import { ApplyToProgramModule } from '@shared/components/apply-to-program/apply-to-program.module';

@NgModule({
  declarations: [
    ProgramDetailsComponent,
    ProgramDescriptionComponent,
    ProgramRequirementsCandidateComponent,
    ProgramTabFactsComponent,
    TestimonialItemComponent
  ],
  imports: [
    CommonModule,
    ProgramPreviewModule,
    SchoolInfoCardModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    YoutubeVideoModule,
    ApplyToProgramModule
  ],
  exports: [ProgramDetailsComponent]
})
export class ProgramDetailsModule {}

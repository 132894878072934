import { Component, Input } from '@angular/core';
import { IFooterLink } from '@models/interfaces/footer-link.interface';

@Component({
  selector: 'footer-nav-item',
  templateUrl: './footer-nav-item.component.html',
  styleUrls: ['./footer-nav-item.component.scss']
})
export class FooterNavItemComponent {
  @Input() title: string;
  @Input() linkList: IFooterLink[];
}

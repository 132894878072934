import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomContentCardComponent } from '@shared/components/custom-content-card/custom-content-card.component';

@NgModule({
  declarations: [CustomContentCardComponent],
  imports: [CommonModule],
  exports: [CustomContentCardComponent]
})
export class CustomContentCardModule {}

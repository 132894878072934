<div *ngIf="content" [class.vip]="content.vip_status" class="suggestion-item">
  <div class="suggestion-item-holder">
    <div class="suggestion-item__cover">
      <div class="suggestion-item__special" *ngIf="content.promotion || content.application_discount">
        <div *ngIf="content.promotion" class="suggestion-item__special-info suggestion-item__special-info_promotion">
          {{ 'COMMON.PROMOTION_LABEL' | translate }}
        </div>
        <div *ngIf="content.application_discount" class="suggestion-item__special-info suggestion-item__special-info_sale">
          {{ 'COMMON.SALE_LABEL' | translate }} -{{ content.application_discount }}%
        </div>
      </div>
      <div class="suggestion-item__cover-box">
        <ng-container *ngIf="this.content.logo">
          <img [src]="this.content.logo.url" alt="" />
        </ng-container>
      </div>
    </div>
    <div class="suggestion-item__info-box">
      <h2 class="suggestion-item__title">
        <ng-container *ngIf="this.showFavoriteBtn && this.type === typeList.PROGRAM && role !== roleList.school">
          <button *ngIf="content.is_favorite" mat-button (click)="deleteFromFavorite(content.id)">
            <mat-icon class="is-favorite">star_rate</mat-icon>
            {{ 'BUTTON_NAME.DELETE_FROM_FAVORITE' | translate | uppercase }}
          </button>
          <button *ngIf="!content.is_favorite" mat-button (click)="addToFavorite(content.id)">
            <mat-icon>star_rate</mat-icon>
            {{ 'BUTTON_NAME.ADD_TO_FAVORITE' | translate | uppercase }}
          </button>
        </ng-container>
        <ng-container *ngIf='userIsAuth; else titleNotAuth'>
          <span
            [routerLink]="detailUrl(content.id)"
            [queryParams]='programQueryParams()'
            class="suggestion-item__title-text"
          >
            {{ content.name }}
          </span>
        </ng-container>
        <ng-template #titleNotAuth>
          <a
            [href]="detailUrl(content.id)"
            target='_blank'
            class="suggestion-item__title-text"
          >
            {{ content.name }}
          </a>
        </ng-template>
      </h2>
      <span *ngIf="content.main_campus_address" class="suggestion-item__address">
        <mat-icon svgIcon="location"></mat-icon>
        {{ content.main_campus_address }}
      </span>
      <ng-container *ngIf="this.type === typeList.SCHOOL">
        <p [innerHTML]="content.about_school | maxLengthString: 90"></p>
      </ng-container>
      <ng-container *ngIf="this.type === typeList.PROGRAM">
        <p [innerHTML]="content.description | maxLengthString: 90"></p>
      </ng-container>
      <ng-container *ngIf='userIsAuth; else notAuthReadSchool'>
        <button
          *ngIf='shouldHideSchoolLink'
          mat-button
          [routerLink]="'/suggestion/school-details/' + content.id"
          color="primary"
          class="small-size-btn show-mobile"
        >
          {{ 'BUTTON_NAME.READ_ABOUT_SCHOOL' | translate }}
        </button>
      </ng-container>
      <ng-template #notAuthReadSchool>
        <a
          *ngIf='shouldHideSchoolLink'
          mat-button
          [href]="'/suggestion/school-details/' + content.id"
          target='_blank'
          color="primary"
          class="small-size-btn show-mobile"
        >
          {{ 'BUTTON_NAME.READ_ABOUT_SCHOOL' | translate }}
        </a>
      </ng-template>
      <div class="suggestion-item__action show-desktop">
        <ng-container *ngTemplateOutlet="suggestionItemActionBtn"></ng-container>
      </div>
    </div>
    <div class="suggestion-item__addition-box">
      <ul class="suggestion-item-cost">
        <li *ngIf='content?.ranking' class="suggestion-item-cost__item">
          <mat-icon svgIcon="shield" class="suggestion-item-cost__icon"></mat-icon>
          <span class="suggestion-item-cost__title">{{ key + 'PRANKING_TAB_LABEL' | translate }}</span>
          <span class="suggestion-item-cost__quality">{{ content.ranking || 0 }}</span>
        </li>
        <li *ngIf="isNumber(content.application_fee?.sum)" class="suggestion-item-cost__item">
          <mat-icon svgIcon="shield" class="suggestion-item-cost__icon"></mat-icon>
          <span class="suggestion-item-cost__title">{{ key + 'APPLICATION_FEE_LABEL' | translate }}</span>
          <span class="suggestion-item-cost__quality">
            {{ content.application_fee?.sum | currency: content.application_fee?.currency?.toUpperCase() }}
          </span>
        </li>
        <li *ngIf="isNumber(content.application_fee?.amount) && this.type === typeList.PROGRAM" class="suggestion-item-cost__item">
          <mat-icon svgIcon="shield" class="suggestion-item-cost__icon"></mat-icon>
          <span class="suggestion-item-cost__title">{{ key + 'APPLICATION_FEE_LABEL' | translate }}</span>
          <span class="suggestion-item-cost__quality">
            {{ content.application_fee?.amount | currency: content.application_fee?.currency?.toUpperCase() }}
          </span>
        </li>
        <li *ngIf="isNumber(content.tuition_fee?.cost)" class="suggestion-item-cost__item">
          <mat-icon svgIcon="shield" class="suggestion-item-cost__icon"></mat-icon>
          <span class="suggestion-item-cost__title">{{ key + 'TUITION_FREE_LABEL' | translate }}</span>
          <span class="suggestion-item-cost__quality">
            {{ content.tuition_fee?.cost | currency: content.tuition_fee?.currency?.toUpperCase() }}
          </span>
        </li>
        <li *ngIf="isNumber(content.tuition_fee?.amount) && this.type === typeList.PROGRAM" class="suggestion-item-cost__item">
          <mat-icon svgIcon="shield" class="suggestion-item-cost__icon"></mat-icon>
          <span class="suggestion-item-cost__title">{{ key + 'TUITION_FREE_LABEL' | translate }}</span>
          <span class="suggestion-item-cost__quality">
            {{ content.tuition_fee?.amount | currency: content.tuition_fee?.currency?.toUpperCase() }}
          </span>
        </li>
      </ul>
      <ul *ngIf="type === typeList.PROGRAM && (content.days_left_to_apply || content.places_left)" class="suggestion-item-summary-list">
        <li *ngIf="content.days_left_to_apply" class="suggestion-item-summary-list__item">
          <mat-icon svgIcon="check-circle-outline-custom"></mat-icon>
          <b>{{ content.days_left_to_apply }}</b>
          {{ key + 'DAY_LEFT_APPLY' | translate }}
        </li>
        <li *ngIf="content.places_left" class="suggestion-item-summary-list__item">
          <mat-icon svgIcon="check-circle-outline-custom"></mat-icon>
          <b>{{ content.places_left }}</b>
          {{ key + 'PLACES_LEFT' | translate }}
        </li>
      </ul>
      <countdown-timer
        *ngIf="canShowTimer"
        [date]="content.going_live?.date"
        [video]="content.going_live?.video_link"
        [title]="'COMMON.GOING_LIVE_LABEL' | translate"
        class="suggestion-item__countdown-timer"
      ></countdown-timer>
    </div>
    <div class="suggestion-item__mobile-action show-mobile">
      <ng-container *ngTemplateOutlet="suggestionItemActionBtn"></ng-container>
    </div>
  </div>
  <div [@showHide]="showExtraInfo ? 'show' : 'hide'">
    <div class="suggestion-item__extra-info">
      <mat-tab-group animationDuration="0ms" class="without-bottom-border small-label-font-size">
        <mat-tab [label]="'BUTTON_NAME.DESCRIPTION_TAB_LABEL' | translate">
          <ng-container *ngIf="this.type === typeList.SCHOOL">
            <p [innerHTML]="content.about_school"></p>
          </ng-container>
          <ng-container *ngIf="this.type === typeList.PROGRAM">
            <p [innerHTML]="content.description"></p>
          </ng-container>
        </mat-tab>
        <mat-tab *ngIf="type === typeList.SCHOOL" [label]="'BUTTON_NAME.PROGRAM_TAB_LABEL' | translate">
          <div class="flex-grid">
            <ng-container *ngIf="content.programs?.length">
              <div *ngFor="let program of content.programs" class="cell large-6 middle-6 small-12">
                <div class="suggestion-item__program">
                  <span>
                    {{ program.category }}
                    <b>{{ program.count }}</b>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab *ngIf="type === typeList.SCHOOL" [label]="'BUTTON_NAME.RANKING_TAB_LABEL' | translate">
          <div class="flex-grid">
            <ng-container *ngIf="content.rankings?.length">
              <div *ngFor="let item of content.rankings" class="cell large-3 middle-6 small-12">
                <div class="suggestion-item__program">
                  <span>{{ item.name }}</span>
                  <span>{{ item.value }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab *ngIf="type === typeList.SCHOOL" [label]="'BUTTON_NAME.FACTS_TAB_LABEL' | translate">
          <ul *ngIf="content.facts" class="suggestion-facts-list">
            <li *ngFor="let item of content.facts" [matTooltip]="item.description" class="suggestion-facts-list__item">
              <mat-icon [svgIcon]="factIconsList[item.key]" class="suggestion-facts-list__icon"></mat-icon>
              {{ item.name }}
              <mat-icon svgIcon="alert" class="suggestion-facts-list__alert-icon"></mat-icon>
            </li>
          </ul>
        </mat-tab>
        <mat-tab [label]="'BUTTON_NAME.TESTIMONIALS_TAB_LABEL' | translate">
          <ng-container *ngTemplateOutlet="content.testimonials?.length ? testimonialsList : testimonialsIsEmpty"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #suggestionItemActionBtn>
  <button (click)="showExtraInfo = !showExtraInfo" mat-button color="primary" class="small-size-btn">
    {{ 'BUTTON_NAME.SHOW_MORE' | translate }}
    <mat-icon>expand_more</mat-icon>
  </button>

  <ng-container *ngIf='userIsAuth; else btnNotAut'>
    <button
      *ngIf='shouldHideSchoolLink'
      mat-button
      [routerLink]="'/suggestion/school-details/' + suggestionId"
      color="primary"
      class="small-size-btn show-desktop"
    >
      {{ 'BUTTON_NAME.READ_ABOUT_SCHOOL' | translate }}
    </button>
  </ng-container>

  <ng-template #btnNotAut>
    <a
      *ngIf='shouldHideSchoolLink'
      mat-button
      [href]="'/suggestion/school-details/' + suggestionId"
      target='_blank'
      color="primary"
      class="small-size-btn show-desktop"
    >
      {{ 'BUTTON_NAME.READ_ABOUT_SCHOOL' | translate }}
    </a>
  </ng-template>


  <apply-to-program
    *ngIf="type === typeList.PROGRAM && role !== roleList.school"
    [contentId]="content.id"
    [label]="'BUTTON_NAME.APPLY' | translate | uppercase"
    [needDisable]="content.applied"
    (forceMakeApplied)='forceMakeApplied()'
  ></apply-to-program>

  <!--  <button-->
  <!--    *ngIf="type === typeList.PROGRAM && role !== roleList.school"-->
  <!--    mat-raised-button-->
  <!--    color="primary"-->
  <!--    class="small-size-btn large-padding"-->
  <!--    (click)="apply(content.id)"-->
  <!--    [disabled]="content.applied"-->
  <!--  >-->
  <!--    {{ 'BUTTON_NAME.APPLY' | translate | uppercase }}-->
  <!--  </button>-->
</ng-template>

<ng-template #testimonialsList>
  <div *ngFor="let item of content.testimonials" class="suggestion-item__comment">
    <h3 *ngIf="item.name">{{ item.name }}</h3>
    <p *ngIf="item.text">{{ item.text }}</p>
    <div *ngIf="item.picture || item.video" class="testimonials__extra-row">
      <div *ngIf="item.video" class="testimonials__extra-row-cell testimonials__video">
        <iframe
          class="testimonials____video"
          [src]="item.video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div *ngIf="item.picture" class="testimonials__extra-row-cell testimonials__picture">
        <img [src]="item.picture" alt="" />
      </div>
    </div>
  </div>
</ng-template>
<ng-template #testimonialsIsEmpty>
  <h3 class="testimonials__is-empty">{{ key + 'TESTIMONIALS_LIST_IS_EMPTY' | translate }}</h3>
</ng-template>

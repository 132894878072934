export const imageRegex: RegExp = /(\.jpg|\.jpeg|\.png)$/i;
export const docRegex: RegExp = /(\.pdf)$/i;
export const videoRegex: RegExp = /(\.mp4)$/i;
export const youtubeVideoLinkRegex: RegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
export const youtubeVideoIDRegex: RegExp = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim;
export const numberRegex: RegExp = /^[0-9]+$/;
export const floatNumberRegex: RegExp = /^\d+([(\.|\,)][\d])?$/gm;
export const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,40}$/;
export const notSpacesRegex: RegExp = /(^.*[^\s]$)/;
export const phoneRegex: RegExp = /^[+]?[0-9]{1,4}[0-9]{7,10}$/gm;
export const urlRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:\/?#\[\]@!$&'()*+,;=]+$/gm;

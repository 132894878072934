import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Data } from '@angular/router';

@Injectable()
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  applyData(data: Data): void {
    this.updateTitle(data.title ? `ApplyClever - ${data.title}` : 'ApplyClever - Helping students study abroad');

    this.updateTags([data.description ? { name: 'Description', content: data.description } : null]);
    this.updateTag({ property: 'og:url', content: window.location.href });
  }

  updateTitle(title: string): void {
    this.title.setTitle(title);
  }

  updateTag(tag: MetaDefinition): void {
    this.meta.updateTag(tag);
  }

  updateTags(tags: Array<MetaDefinition | null>): void {
    tags.forEach((tag: MetaDefinition | null): void => {
      if (tag) {
        this.meta.updateTag(tag);
      }
    });
  }
}

<div>
  <h3 mat-dialog-title>{{ title | translate }}</h3>
  <div mat-dialog-actions>
    <base-form-input
      [control]="getControl('video')"
      [placeholder]="placeholder | translate"
      class='full-width'
    ></base-form-input>
  </div>
  <div mat-dialog-actions>
    <button
      mat-stroked-button
      [mat-dialog-close]="null"
      color="warn"
    >
      {{ 'BUTTON_NAME.CLOSE' | translate }}
    </button>
    <button
      mat-stroked-button
      [mat-dialog-close]="getFormValue()"
      color="primary"
      [disabled]='formGroup.invalid'
    >
      {{ 'BUTTON_NAME.SAVE' | translate }}
    </button>
  </div>
</div>

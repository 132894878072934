import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import Echo from 'laravel-echo';
import SocketIoClient from 'socket.io-client';
import {BehaviorSubject} from 'rxjs';
import {IGetNewMessageData} from '@models/interfaces/sockets/get-new-message-data.interface';
import {AuthService} from '@services/auth/auth.service';
import { filter } from 'rxjs/operators';
import { User } from '@app/models/classes/user.model';
import {APP_CONFIG, IAppConfig} from '@misc/constants/app-config.constant';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  observable: Observable<string>;
  echo: any;

  getNewMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth: AuthService, @Inject(APP_CONFIG) private config: IAppConfig) {
    if (!this.config.socketUrl) {
      return;
    }
    try {
      this.auth.me$
        .pipe(filter((data: any): boolean => data))
        .subscribe((user: User): void => {
          this.echo = new Echo({
            broadcaster: 'socket.io',
            host: this.config.socketUrl,
            client: SocketIoClient,
          });

          this.echo.connector.socket.on('connect', (): void => {
            console.log('Socket.io CONNECTED');
          });

          this.echo.channel('applyclever_database_messageEvent')
            .listen('.messageEvent_' + user.id, (data: IGetNewMessageData): void => this.getNewMessage.next(data));
      });
    } catch (e) {
      console.log(e);
    }
  }
}

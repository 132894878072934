<div *ngIf='isLoading; else loading' class='reading-news'>
  <h2 class='reading-news__title'>{{ reading.name }}</h2>
  <div *ngIf='reading.image' class='reading-news__image'>
    <img [src]='reading.image' alt=''>
  </div>
  <div *ngIf='reading.youtube' class='reading-news__video-holder' >
    <youtube-video [link]='link' class='reading-news__video'></youtube-video>
  </div>
  <p class='reading-news__text' [innerHTML]='reading.text'></p>
</div>

<ng-template #loading>
  <div class='reading-news__loading'>
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@misc/abstracts/api-base.abstract.service';
import { ClassType } from 'class-transformer/ClassTransformer';
import { FileResponse } from '@models/classes/file-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesApiService extends ApiBaseAbstractService<FileResponse> {
  protected model: ClassType<FileResponse> = FileResponse;
  protected URLPath: string = '/api/settings';

  getAll(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/services`);
  }
}

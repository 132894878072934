import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NewsItemComponent } from '@shared/components/news-item/news-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes/pipes.module';
import { NewsItemPopupComponent } from './news-item-popup/news-item-popup.component';
import { YoutubeVideoModule } from '@shared/components/youtube-video/youtube-video.module';

@NgModule({
  declarations: [NewsItemComponent, NewsItemPopupComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, PipesModule, MatProgressSpinnerModule, YoutubeVideoModule],
  exports: [NewsItemComponent]
})
export class NewsItemModule {}

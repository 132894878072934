import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NguCarouselModule } from '@ngu/carousel';
import { GalleryComponent } from '@shared/components/gallery/gallery.component';

@NgModule({
  declarations: [GalleryComponent],
  imports: [CommonModule, NguCarouselModule, MatButtonModule, MatIconModule],
  exports: [GalleryComponent]
})
export class GalleryModule {}

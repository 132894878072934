import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IOption } from '@forms/base-select/base-select.component';
import { IRadio } from '@forms/base-radio-group/base-radio-group.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { FilterService } from '@services/filter/filter.service';

@Component({
  selector: 'background-info-form',
  templateUrl: './background-info-form.component.html',
  styleUrls: ['./background-info-form.component.scss']
})
export class BackgroundInfoFormComponent extends BaseFormAbstractComponent implements OnInit, OnDestroy {
  readonly key: string = 'ACCOUNT_STUDENT.';
  readonly booleanFieldType: typeof BooleanFieldType = BooleanFieldType;

  @Input() inputForm: FormGroup = new FormGroup({});

  countryOptions: IOption[] = [];
  questionStatus: IRadio[] = [
    {
      label: 'COMMON.YES',
      value: true
    },
    {
      label: 'COMMON.NO',
      value: false
    }
  ];
  visaF1Usa: Subscription;
  canadianStudyPermit: Subscription;
  ukStudentStudyVisa: Subscription;
  australianStudent: Subscription;
  newZealandStudent: Subscription;
  otherCountrySubscription: Subscription;

  constructor(private filterService: FilterService) {
    super();
  }

  ngOnInit(): void {
    this.visaF1Usa = this.subscription('have_usa_f1_visa', 'have_usa_f1_visa_details');
    this.canadianStudyPermit = this.subscription('canadian_study_permit', 'canadian_study_permit_details');
    this.ukStudentStudyVisa = this.subscription('have_uk_student_visa_short_study_visa', 'have_uk_student_visa_short_study_visa_details');
    this.australianStudent = this.subscription('have_australian_student', 'have_australian_student_details');
    this.newZealandStudent = this.subscription('have_new_zealand_student', 'have_new_zealand_student_details');
    this.otherCountrySubscription = this.getControl('have_valid_visa_form_other_country', this.inputForm).valueChanges.subscribe(
      (value: boolean): void => {
        const control: FormArray = this.getArray('other_country', this.inputForm);
        if (value) {
          control.enable();
          return;
        }
        control.clear();
        control.disable();
      }
    );
  }

  subscription(targetControl: string, secondControl: string): Subscription {
    const subscribeHandler: (value: boolean) => void = (value: boolean): void => {
      const tempControl: FormControl = this.getControl(secondControl, this.inputForm);
      if (value) {
        tempControl.enable();
        return;
      }
      tempControl.reset();
      tempControl.disable();
    };
    const control: FormControl = this.getControl(targetControl, this.inputForm);
    subscribeHandler(control.value);
    return control.valueChanges.subscribe(subscribeHandler);
  }

  changeCountry(query: string): void {
    this.filterService.fetchCountry(query).subscribe((country: IOption[]): void => {
      this.countryOptions = country;
    });
  }

  displayWith(item: IOption): string {
    return item.label ?? '';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.visaF1Usa?.unsubscribe();
    this.canadianStudyPermit?.unsubscribe();
    this.ukStudentStudyVisa?.unsubscribe();
    this.australianStudent?.unsubscribe();
    this.newZealandStudent?.unsubscribe();
    this.otherCountrySubscription?.unsubscribe();
  }
}

import { youtubeVideoLinkRegex } from '@misc/reg-pattern';

export function getVideoId(originalLink: string): string {
  const match: RegExpMatchArray = originalLink.match(youtubeVideoLinkRegex);
  return match && match[2].length === 11 ? match[2] : null;
}

export function getYoutubeVideoLink(originalLink?: string): string {
  if (!originalLink) {
    return null;
  }
  return `https://www.youtube.com/embed/${getVideoId(originalLink)}`;
}

export function prepareYoutubeVideoLink(url: string): string {
  if (youtubeVideoLinkRegex.test(url)) {
    return url;
  }
  return `https://www.youtube.com/embed/${url}`;
}

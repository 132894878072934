<div class='account-sidebar'>
  <div class='account-sidebar__body'>
    <ul class='account-sidebar-nav-list'>
      <li *ngFor='let itemNav of sidebarNavList; let ind = index' class='account-sidebar-nav-list__item'>
        <ng-container
          *ngTemplateOutlet='itemNav.children ? itemDropDown : itemLink; context: { $implicit: itemNav, index: ind }'
        ></ng-container>
      </li>
    </ul>
  </div>
</div>

<ng-template #itemLink let-item>
  <a
    mat-button
    [routerLink]='item.href'
    [routerLinkActiveOptions]='{exact: true}'
    routerLinkActive='active'
    class='account-sidebar-nav-list__link'
  >
    <mat-icon
      *ngIf='item.svgIcon; else alternativeIconTemplate'
      [svgIcon]='item.svgIcon'
      class='account-sidebar-nav-list__icon'
    ></mat-icon>
    <ng-template #alternativeIconTemplate>
      <mat-icon class='account-sidebar-nav-list__icon'>{{ item.icon }}</mat-icon>
    </ng-template>
    {{ item.label | translate }}
  </a>
</ng-template>

<ng-template #itemDropDown let-item let-index="index">
  <button mat-button (click)='dropDownTrigger(index)'>
    <mat-icon [svgIcon]='item.svgIcon' class='account-sidebar-nav-list__icon'></mat-icon>
    <span class='account-sidebar-nav-list__label'>{{ item.label | translate }}</span>
    <mat-icon [class.is-open]='currentMenuIsOpen(index)' class='account-sidebar-nav-list__triangle'>arrow_drop_down</mat-icon>
  </button>
  <ul [@showHide]="currentMenuIsOpen(index) ? 'show' : 'hide'" class='account-sidebar-sub-nav-list'>
    <li *ngFor='let subItem of item.children' class='account-sidebar-sub-nav-list__item'>
      <ng-container *ngTemplateOutlet='itemLink; context: { $implicit: subItem }'></ng-container>
    </li>
  </ul>
</ng-template>

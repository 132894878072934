import { Component, OnInit } from '@angular/core';
import { SidebarService } from '@services/sidebar/sidebar.service';
import { showHide } from '@app/animation';
import { AuthService } from '@services/auth/auth.service';

export interface IActiveMenu {
  index: number;
  open: boolean;
}

@Component({
  selector: 'account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss'],
  animations: [showHide]
})
export class AccountSidebarComponent implements OnInit {

  sidebarNavList: any;
  currentMenu: IActiveMenu = {} as IActiveMenu;

  constructor(private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.sidebarNavList = this.sidebarService.currentNav;
  }

  logout(): void {
    this.sidebarService.logout();
  }

  currentMenuIsOpen(index: number): boolean {
    return this.currentMenu.index === index && this.currentMenu.open;
  }

  dropDownTrigger(index: number): void {
    if (this.currentMenu.index === index) {
      this.currentMenu.open = !this.currentMenu.open;
    } else {
      this.currentMenu = {
        index,
        open: true
      };
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { FormControl, FormGroup } from '@angular/forms';
import { IRadio } from '@forms/base-radio-group/base-radio-group.component';
import { IGradingAverageOriginal, IGradingSchemeOriginal, IOption } from '@models/interfaces/forms/option.interface';
import { FilterService } from '@services/filter/filter.service';
import { Observable, throwError } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@services/notification/notification.service';
import { TranslatePipe } from '@ngx-translate/core';
import { SnackBarNotificationType } from '@models/enums/snack-bar-notification-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpServiceError } from '@services/http/http-service-error.class';

@Component({
  selector: 'educator-history-form',
  templateUrl: './educator-history-form.component.html',
  styleUrls: ['./educator-history-form.component.scss']
})
export class EducatorHistoryFormComponent extends BaseFormAbstractComponent implements OnInit {
  readonly key: string = 'ACCOUNT_STUDENT.';

  @Input() inputForm: FormGroup = new FormGroup({});

  graduatedRecentSchool: IRadio[] = [
    {
      label: 'COMMON.NO',
      value: false
    },
    {
      label: 'COMMON.YES',
      value: true
    }
  ];
  countryOptions: IOption[];

  countryEducationSubscriber: Subscription;
  educationLevelSubscriber: Subscription;
  gradingSchemeSubscriber: Subscription;
  educationLevelByCountryOption: IOption[];
  gradingSchemeOptions: IOption[] = [];
  gradingAverageOriginalOptions: IGradingSchemeOriginal[] = [];
  gradeAverageOptions: IOption[] = [];

  constructor(private filterService: FilterService, private notification: NotificationService, private translate: TranslatePipe) {
    super();
  }

  ngOnInit(): void {
    this.fetchCountry();
    this.initSubscribers();
    this.fetchEducationLevelByCountryId(this.getControl('country_of_education', this.inputForm).value);
  }

  initSubscribers(): void {
    this.countryEducationSubscriber = this.getControl('country_of_education', this.inputForm).valueChanges.subscribe(
      (data?: IOption): void => {
        if (data) {
          this.fetchEducationLevelByCountryId(data);
        } else {
          this.educationLevelByCountryOption = null;
          this.getControl('highest_level_of_education', this.inputForm).reset();
          this.getControl('grading_scheme', this.inputForm).reset();
          this.getControl('your_grade_average', this.inputForm).reset();
        }
      }
    );
    this.educationLevelSubscriber = this.getControl('highest_level_of_education', this.inputForm).valueChanges.subscribe(
      (data?: IOption): void => {
        if (!data) {
          this.getControl('grading_scheme', this.inputForm).reset();
          this.getControl('your_grade_average', this.inputForm).reset();
          return;
        }
        this.fetchGradingSchema();
      }
    );
    this.gradingSchemeSubscriber = this.getControl('grading_scheme', this.inputForm).valueChanges.subscribe((): void => {
      this.getControl('your_grade_average', this.inputForm).reset();
      this.setGradeAverage();
    });
  }

  fetchEducationLevelByCountryId(data: IOption): void {
    if (!data?.value) {
      return;
    }
    this.filterService
      .fetchEducationLevelByCountry(data.value)
      .pipe(
        map((response: IOption[]): IOption[] => {
          if (!response?.length) {
            this.notification.addToQueue(
              this.translate.transform('MESSAGE.COUNTRY_DONT_HAVE_EDUCATION_LEVEL', { country: data.label }),
              SnackBarNotificationType.warning
            );
          }
          return response;
        }),
        catchError(
          (error: HttpErrorResponse): Observable<HttpServiceError> => {
            this.notification.addToQueue(this.translate.transform('MESSAGE.SOMETHING_WENT_WRONG'), SnackBarNotificationType.error);
            return throwError(error);
          }
        )
      )
      .subscribe((response: IOption[]): void => {
        this.educationLevelByCountryOption = response;
      });
  }

  fetchGradingSchema(): void {
    const country: IOption = this.getControl('country_of_education', this.inputForm)?.value;
    const gradingSchemeId: number = this.getControl('highest_level_of_education', this.inputForm)?.value;
    if (!country?.value && !gradingSchemeId) {
      return;
    }
    this.filterService.fetchGradingScheme(country.value, gradingSchemeId).subscribe((response: IGradingSchemeOriginal[]): void => {
      this.gradingAverageOriginalOptions = response;
      this.gradingSchemeOptions = response.map(
        (item: IGradingSchemeOriginal): IOption => ({
          label: item.name,
          value: item.id
        })
      );
      this.setGradeAverage();
    });
  }

  setGradeAverage(): void {
    const gradingSchemeId: number = this.getControl('grading_scheme', this.inputForm)?.value;
    this.gradeAverageOptions = this.gradingAverageOriginalOptions
      .find((item: IGradingSchemeOriginal): boolean => item.id === gradingSchemeId)
      ?.ranges.map((item: IGradingAverageOriginal): IOption => ({ label: item.name, value: item.percentages }));
  }

  addSchoolName(): void {
    this.getArray('school_list', this.inputForm).push(new FormControl(null));
  }

  deleteSchoolName(index: number): void {
    this.getArray('school_list', this.inputForm).removeAt(index);
  }

  fetchCountry(params: string = ''): void {
    this.filterService.fetchCountry(params).subscribe((country: IOption[]): void => {
      this.countryOptions = country;
    });
  }

  getValue(item: IOption): IOption {
    return item;
  }

  displayWith(item: IOption): string | null {
    return item?.label;
  }
}

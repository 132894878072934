import { Component, OnInit, Input, Output, TemplateRef, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormArray, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { BaseFormFieldAbstractComponent } from '@misc/abstracts/base-form-field.abstract.component';

@Component({
  selector: 'base-multi-autocomplete',
  templateUrl: './base-multi-autocomplete.component.html',
  styleUrls: ['./base-multi-autocomplete.component.scss']
})
export class BaseMultiAutocompleteComponent extends BaseFormFieldAbstractComponent implements OnInit {
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef<HTMLInputElement>;

  @Output() autocompleteChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() template: TemplateRef<any>;
  @Input() useGroup: boolean;
  @Input() options: any[] = [];
  @Input() chipsControl: FormArray = new FormArray([]);
  @Input() chipDisplayWith: (chip: any) => string = (ship: any): string => ship;
  @Input() autocompleteItemDisplayWith: (item: any) => string = (item: any): string => item;
  @Input() checkIsDisable: (selected: any) => boolean = (selected: any) =>
    (this.chipsControl.value || []).some((item: any): boolean => item?.value === selected?.value)

  ngOnInit(): void {
    this.control.valueChanges.pipe(startWith(''), debounceTime(300), distinctUntilChanged()).subscribe((value: string): void => {
      if (typeof value === 'string') {
        this.autocompleteChange.emit(value);
      }
    });
  }

  remove(fruit: string): void {
    const index: number = this.chipsControl.value.indexOf(fruit);
    this.chipsControl.removeAt(index);
  }

  isDisabled(option: any): boolean {
    return this.checkIsDisable(option);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.chipsControl.push(new FormControl(event.option.value));
    this.control.reset();
    this.autoCompleteInput.nativeElement.value = '';
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INews } from '@models/interfaces/news.interface';
import { NewsService } from '@services/api/news/news.service';
import { finalize } from 'rxjs/operators';
import { getYoutubeVideoLink } from '@misc/helpers/get-youtube-video-link';

@Component({
  selector: 'news-item-popup',
  templateUrl: './news-item-popup.component.html',
  styleUrls: ['./news-item-popup.component.scss']
})
export class NewsItemPopupComponent implements OnInit {

  reading: INews;
  isLoading: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private newsService: NewsService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  get link(): string {
    return getYoutubeVideoLink(this.reading.youtube);
  }

  fetchData(): void {
    if (!this.data.newsId) {
      return;
    }
    this.isLoading = false;
    this.newsService
      .getNewsById(this.data.newsId, {}, {skipLoaderStart: true})
      .pipe(
        finalize((): void => {
          this.isLoading = true;
        })
      )
      .subscribe((data: INews): void => {
      this.reading = data;
    });
  }

}

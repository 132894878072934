import { Component, Input } from '@angular/core';

@Component({
  selector: 'school-info-card',
  templateUrl: './school-info-card.component.html',
  styleUrls: ['./school-info-card.component.scss']
})
export class SchoolInfoCardComponent {

  @Input() title: string;
  @Input() noShadow: boolean;
  @Input() extraClass: string = '';

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'program-preview',
  templateUrl: './program-preview.component.html',
  styleUrls: ['./program-preview.component.scss']
})
export class ProgramPreviewComponent {

  @Input() imgSrc: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeofPipe } from '@pipes/typeof/typeof.pipe';
import { PathParsePipe } from '@pipes/path-parse/path-parse.pipe';
import { PrettifyUrlPipe } from './prettify-url/prettify-url.pipe';
import { MaxLengthStringPipe } from './max-length-string/max-length-string.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';

@NgModule({
  declarations: [TypeofPipe, PathParsePipe, PrettifyUrlPipe, MaxLengthStringPipe, SafeUrlPipe, CurrencySymbolPipe],
  exports: [TypeofPipe, PathParsePipe, PrettifyUrlPipe, MaxLengthStringPipe, SafeUrlPipe, CurrencySymbolPipe],
  imports: [CommonModule],
  providers: [PathParsePipe, TypeofPipe, PrettifyUrlPipe]
})
export class PipesModule {}

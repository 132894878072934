import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderStatus = google.maps.GeocoderStatus;
import LatLngLiteral = google.maps.LatLngLiteral;
import LatLng = google.maps.LatLng;

export interface IGoogleMarkerItem {
  position: LatLng | LatLngLiteral;
}

enum MapModeView {
  map = 'map',
  street = 'street'
}

@Component({
  selector: 'map-google',
  templateUrl: './map-google.component.html',
  styleUrls: ['./map-google.component.scss']
})
export class MapGoogleComponent implements OnInit {

  mapModeView: typeof MapModeView = MapModeView;
  mapModeStatus: MapModeView = MapModeView.map;
  panoramaId: string = `panorama-${Math.round(Math.random() * 10000)}`;
  forceHideMap: boolean = false;

  @ViewChild('map', {static: true}) searchElem: any;
  @ViewChild('panorama', {static: true}) panoramaElem: ElementRef;
  @Input() width: string | number | null = '100%';
  @Input() height: string | number | null = 500;
  @Input() address: string;

  markerList: IGoogleMarkerItem[] = [];

  options: google.maps.MapOptions = {
    mapTypeId: 'terrain',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 1,
  };

  get googleAddress(): string {
    return this.address;
  }

  get widthElem(): string {
    if (!this.width) {
      return '';
    }
    if (typeof this.width === 'number') {
      return `${this.width}px`;
    }
    return this.width;
  }

  get heightElem(): string {
    if (!this.height) {
      return '';
    }
    if (typeof this.height === 'number') {
      return `${this.height}px`;
    }
    return this.height;
  }

  get mapIsShow(): boolean {
    return this.mapModeStatus === this.mapModeView.map;
  }

  ngOnInit(): void {
    this.addMarkerToMap();
  }

  addMarkerToMap(): void {
    if (!this.googleAddress) {
      return;
    }
    new window.google.maps.Geocoder().geocode({
      address: this.googleAddress
    }, (results: GeocoderResult[], status: GeocoderStatus): void => {
      if (status === GeocoderStatus.OK) {
        this.markerList = results.map((item: GeocoderResult): IGoogleMarkerItem => ({
          position: {
            lat: item.geometry?.location?.lat(),
            lng: item.geometry?.location?.lng()
          }
        }));
        this.options = {
          ...this.options,
          center: this.markerList[this.markerList.length - 1]?.position,
        };
        this.panorama();
      }
      if (!this.markerList?.length) {
        this.forceHideMap = true;
      }
    });
  }

  panorama(): void {
    const panorama: any = new google.maps.StreetViewPanorama(
      document.querySelector(`.${this.panoramaId}`) as HTMLElement,
      {
        position: this.markerList[this.markerList.length - 1]?.position,
        pov: {
          heading: 14,
          pitch: 1,
        },
        scrollwheel: false,
        mode: 'html5'
      }
    );
  }
}

import { Component, EventEmitter, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'input-slider',
  templateUrl: './input-slider.component.html',
  styleUrls: ['./input-slider.component.scss']
})
export class InputSliderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() label: string;
  @Input() minRange: FormControl = new FormControl(0);
  @Input() maxRange: FormControl = new FormControl(0);
  @Input() options: Options = {
    floor: 0,
    ceil: 200,
    step: 1,
    hidePointerLabels: true,
    hideLimitLabels: true
  };
  showSlider: BehaviorSubject<void> = new BehaviorSubject(null);
  visibilitySlider: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showSliderSubscription: Subscription;

  ngOnInit(): void {
    this.showSliderSubscription = this.showSlider.pipe(debounceTime(800)).subscribe((): void => {
      this.visibilitySlider.next(!this.visibilitySlider.value);
    });
  }

  valueChangeHandler(event: number): void {
    this.minRange.setValue(event);
  }

  highValueChangeHandler(event: number): void {
    this.maxRange.setValue(event);
  }

  ngOnDestroy(): void {
    this.showSlider.next();
    this.showSliderSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.showSlider.next();
  }
}

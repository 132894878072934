<div class="flex-grid">
  <div class="cell small-only-12">
    <h2 class="title-block">{{ key + 'YOU_HAVE_VALID_STUDY_PERMIT_VISA_OR_VISITOR_VISA' | translate }}</h2>
  </div>
  <div class="cell large-6 middle-6 small-12 flex-align-center">
    <base-boolean-field [type]="booleanFieldType.checkbox" [control]="getControl('have_usa_f1_visa', inputForm)">
      {{ key + 'I_HAVE_USA_F1_VISA' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PLEASE_PROVIDE_MORE_DETAILS' | translate"
      [control]="getControl('have_usa_f1_visa_details', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell large-6 middle-6 small-12 flex-align-center">
    <base-boolean-field [type]="booleanFieldType.checkbox" [control]="getControl('canadian_study_permit', inputForm)">
      {{ key + 'I_HAVE_CANADIAN_STUDY_PERMIT_OR_VISITOR_VISA' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PLEASE_PROVIDE_MORE_DETAILS' | translate"
      [control]="getControl('canadian_study_permit_details', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell large-6 middle-6 small-12 flex-align-center">
    <base-boolean-field [type]="booleanFieldType.checkbox" [control]="getControl('have_uk_student_visa_short_study_visa', inputForm)">
      {{ key + 'I_HAVE_UK_STUDENT_VISA_TIER_4_OR_SHORT_TERM_STUDY_VISA' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PLEASE_PROVIDE_MORE_DETAILS' | translate"
      [control]="getControl('have_uk_student_visa_short_study_visa_details', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell large-6 middle-6 small-12 flex-align-center">
    <base-boolean-field [type]="booleanFieldType.checkbox" [control]="getControl('have_australian_student', inputForm)">
      {{ key + 'I_HAVE_AUSTRALIAN_STUDENT_OR_VISITOR_VISA' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PLEASE_PROVIDE_MORE_DETAILS' | translate"
      [control]="getControl('have_australian_student_details', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell large-6 middle-6 small-12 flex-align-center">
    <base-boolean-field [type]="booleanFieldType.checkbox" [control]="getControl('have_new_zealand_student', inputForm)">
      {{ key + 'I_HAVE_NEW_ZEALAND_STUDENT_OR_VISITOR_VISA' | translate }}
    </base-boolean-field>
  </div>
  <div class="cell cell_not-padding-bottom large-6 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PLEASE_PROVIDE_MORE_DETAILS' | translate"
      [control]="getControl('have_new_zealand_student_details', inputForm)"
    ></base-form-input>
  </div>
</div>
<div class="flex-grid">
  <div [class.m-b-7]="getArray('other_country', inputForm).disabled" class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-radio-group
      [label]="key + 'HAVE_VALID_VISA_FORM_OTHER_COUNTRY' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('have_valid_visa_form_other_country', inputForm)"
    ></base-radio-group>
  </div>
  <div *ngIf="getControl('have_valid_visa_form_other_country', inputForm).value"
       class="cell cell_not-padding-bottom large-8 middle-6 small-12">
    <base-multi-autocomplete
      [placeholder]="key + 'COUNTRY_LABEL' | translate"
      [options]="countryOptions"
      [chipsControl]="getArray('other_country', inputForm)"
      (autocompleteChange)="changeCountry($event)"
      [chipDisplayWith]="displayWith"
      [autocompleteItemDisplayWith]="displayWith"
      svgIcon="custom-cancel"
    ></base-multi-autocomplete>
  </div>
</div>
<div class="flex-grid">
  <div class="cell small-only-12">
    <h2 class="title-block">{{ key + 'ADDITIONAL_INFORMATION' | translate }}</h2>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <base-radio-group
      [label]="key + 'HAVE_EVER_VISITED_CANADA_USA' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('have_ever_visited_canada_usa', inputForm)"
      class="m-b-5"
    ></base-radio-group>
    <base-radio-group
      [label]="key + 'YOU_CURRENTLY_RESIDING_CANADA_USA' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('you_currently_residing_canada_usa', inputForm)"
      class="m-b-5"
    ></base-radio-group>
    <base-radio-group
      [label]="key + 'APPLIED_TYPE_IMMIGRATION_INTO_CANADA_USA' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('applied_type_immigration_into_canada_usa', inputForm)"
      class="m-b-5"
    ></base-radio-group>
    <base-radio-group
      [label]="key + 'HAVE_VALID_CANADIAN_STUDY_PERMIT' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('have_valid_canadian_study_permit', inputForm)"
      class="m-b-5"
    ></base-radio-group>
  </div>
  <div class="cell large-6 middle-6 small-12">
    <base-radio-group
      [label]="key + 'HAVE_VALID_US_STUDY_VISA' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('have_valid_us_study_visa', inputForm)"
      class="m-b-5"
    ></base-radio-group>
    <base-radio-group
      [label]="key + 'HAVE_EVER_BEEN_REFUSED_VISA_FOR_CANADA_USA' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('have_ever_been_refused_visa_for_canada_usa', inputForm)"
      class="m-b-5"
    ></base-radio-group>
    <base-radio-group
      [label]="key + 'WERE_EVER_REMOVED_FROM_CANADA_USA' | translate"
      [radioOption]="questionStatus"
      [control]="getControl('were_ever_removed_from_canada_usa', inputForm)"
      class="m-b-5"
    ></base-radio-group>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountProfileService } from '@services/api/account-profile/account-profile.service';
import { IFullStudentProfile } from '@models/interfaces/profile.interface';
import { IOption } from '@models/interfaces/forms/option.interface';
import {
  bachelorDegreesStudyOption,
  highDegreesStudyOption, mastersDegreesStudyOption,
  primaryDegreesStudyOption,
  secondaryDegreesStudySchool
} from '@misc/constants/degrees-of-study.constants';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'student-profile-detail',
  templateUrl: './student-profile-detail.component.html',
  styleUrls: ['./student-profile-detail.component.scss']
})
export class StudentProfileDetailComponent implements OnInit {

  readonly key: string = 'ACCOUNT_STUDENT.';
  student: IFullStudentProfile;
  isLoading: boolean = true;
  isError: boolean = false;

  primarySchoolOption: IOption[] = primaryDegreesStudyOption;
  secondarySchool: IOption[] = secondaryDegreesStudySchool;
  highSchoolOption: IOption[] = highDegreesStudyOption;
  bachelorDegreeOption: IOption[] = bachelorDegreesStudyOption;
  mastersDegreeOption: IOption[] = mastersDegreesStudyOption;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountProfileService: AccountProfileService
  ) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    if (!this.data.id) {
      return;
    }
    this.isLoading = false;
    this.accountProfileService
      .fetchStudentById(this.data.id, {},  {skipLoaderStart: true})
      .pipe(finalize((): void => {
        this.isLoading = true;
      }))
      .subscribe(
        (student: any): void => {
        this.student = student;
      }, (error: HttpErrorResponse): void => {
          console.log(error);
          this.isError = true;
        });
  }

  getLabel(condition: boolean, positiveLabel: string, negativeLabel: string): string {
    return condition ? positiveLabel : negativeLabel;
  }

  getDegreeLabel(key: string, degreeOptions: IOption[]): string {
    if (!key) {
      return null;
    }
    const current: IOption = degreeOptions.find((item: IOption): boolean => item.value === key);

    if (!current) {
      return null;
    }

    return current.label;
  }

  getEnglishExamType(type: string, field: 'Reading' | 'Writing' | 'Listening' | 'Speaking' | 'Overall' = 'Reading'): string {
    if (!type) {
      return null;
    }
    return this.student?.language_requirements[`${type}-${field}`];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { IRadio } from '@forms/base-radio-group/base-radio-group.component';
import { IOption } from '@forms/base-select/base-select.component';
import { FormGroup } from '@angular/forms';
import { FilterService } from '@services/filter/filter.service';
import { AuthService } from '@services/auth/auth.service';
import { UserRole } from '@models/enums/user-role.enum';

@Component({
  selector: 'general-info-form',
  templateUrl: './general-info-form.component.html',
  styleUrls: ['./general-info-form.component.scss']
})
export class GeneralInfoFormComponent extends BaseFormAbstractComponent implements OnInit {
  genderStatus: IRadio[] = [
    {
      label: 'COMMON.FEMALE_GENDER',
      value: 'female'
    },
    {
      label: 'COMMON.MALE_GENDER',
      value: 'male'
    }
  ];
  marriedStatus: IRadio[] = [
    {
      label: 'COMMON.MARITAL_STATUS_SINGLE',
      value: 'single'
    },
    {
      label: 'COMMON.MARITAL_STATUS_MARRIED',
      value: 'married'
    }
  ];
  countryOptions: IOption[];
  citizenshipOptions: IOption[];

  @Input() inputForm: FormGroup = new FormGroup({});

  key: string = 'ACCOUNT_STUDENT.';
  email: any;
  constructor(private filterService: FilterService, private auth: AuthService) {
    super();
  }

  get shouldDisabledEmail(): boolean {
    return this.auth.myRole === UserRole.recruiter;
  }

  ngOnInit(): void {
    this.fetchCountry();
    this.fetchCitizenship();
    this.email = this.getControl('email', this.inputForm).value;
  }

  fetchCitizenship(params: string = ''): void {
    this.filterService.fetchCountry(params).subscribe((country: IOption[]): void => {
      this.citizenshipOptions = country;
    });
  }

  fetchCountry(params: string = ''): void {
    this.filterService.fetchCountry(params).subscribe((country: IOption[]): void => {
      this.countryOptions = country;
    });
  }

  getValue(item: IOption): IOption {
    return item;
  }

  displayWith(item: IOption): string | null {
    return item?.label;
  }
}

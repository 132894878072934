import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { FormControl, FormGroup } from '@angular/forms';
import { IOption } from '@forms/base-select/base-select.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { FilterService } from '@services/filter/filter.service';
import { StudentProfileFormHelperComponent } from '@shared/components/student-profile-form/student-profile-form.helper.component';

@Component({
  selector: 'test-scores-form',
  templateUrl: './test-scores-form.component.html',
  styleUrls: ['./test-scores-form.component.scss']
})
export class TestScoresFormComponent extends StudentProfileFormHelperComponent implements OnInit, OnDestroy{
  readonly key: string = 'ACCOUNT_STUDENT.';
  readonly booleanFieldType: typeof BooleanFieldType = BooleanFieldType;

  englishExamTypesOption: IOption[];
  englishExamSubscriber: Subscription;
  gmatExamScoresSubscriber: Subscription;
  greExamScoresSubscriber: Subscription;

  constructor(private filterService: FilterService) {
    super();
  }

  get canShowIELTS(): boolean {
    return this.getLanguageRequirementsByType('ielts')
      .some((control: FormControl): boolean => !control.disabled);
  }

  get canShowTOEFL(): boolean {
    return this.getLanguageRequirementsByType('toefl')
      .some((control: FormControl): boolean => !control.disabled);
  }

  get canShowOTHER(): boolean {
    return this.getLanguageRequirementsByType('other')
      .some((control: FormControl): boolean => !control.disabled);
  }

  ngOnInit(): void {
    this.fetchEnglishExamTypes();
    // this.triggerLanguageRequirements(this.getControl('english_exam_type', this.inputForm).value);
    // this.triggerDisablingControl(this.gmatExamScoresControls, this.getControl('i_have_gmat_exam_scores', this.inputForm).value);
    // this.triggerDisablingControl(this.greExamScoresControls, this.getControl('i_have_gre_exam_scores', this.inputForm).value);
    super.ngOnInit();
    this.englishExamSubscriber = this.getControl('english_exam_type', this.inputForm)
      .valueChanges
      .subscribe((value: string): void =>  {
        this.triggerLanguageRequirements(value);
      });
    this.gmatExamScoresSubscriber = this.getControl('i_have_gmat_exam_scores', this.inputForm)
      .valueChanges
      .subscribe((value: boolean): void => {
        this.triggerDisablingControl(this.gmatExamScoresControls, value);
      });
    this.greExamScoresSubscriber = this.getControl('i_have_gre_exam_scores', this.inputForm)
      .valueChanges
      .subscribe((value: boolean): void => {
        this.triggerDisablingControl(this.greExamScoresControls, value);
      });
  }

  fetchEnglishExamTypes(): void {
    this.filterService.fetchEnglishExamTypes().subscribe((examTypes: IOption[]): void => {
      this.englishExamTypesOption = examTypes;
    });
  }

  getLanguageRequirementsControl(controlName: string): any {
    return this.getControl(controlName, this.getGroup('language_requirements', this.inputForm));
  }

  // triggerDisablingControl(controls: string[], status: boolean): void {
  //   controls.map((control: string): void => {
  //     if (!(control in this.inputForm.controls)) {
  //       return;
  //     }
  //
  //     const currentControl: FormControl = this.getControl(control, this.inputForm);
  //
  //     if (status) {
  //       currentControl.enable();
  //     } else {
  //       currentControl.reset();
  //       currentControl.disable();
  //     }
  //   });
  // }

  triggerVisibilityControl(controlName: string): boolean {
    if (controlName in this.inputForm.controls) {
      return !this.getControl(controlName, this.inputForm).disabled;
    }
    return false;
  }

  // triggerLanguageRequirements(value?: string): void {
  //   const date: FormControl = this.getControl('date_of_exam', this.inputForm);
  //   const group: FormGroup = this.getGroup('language_requirements', this.inputForm);
  //   Object.keys(group.controls).map((controlName: string): void => {
  //     if (value && controlName.includes(value)) {
  //       group.get(controlName).enable();
  //       return;
  //     }
  //     group.get(controlName).reset();
  //     group.get(controlName).disable();
  //   });
  //
  //   if (Object.keys(group.controls).some((key: string): boolean => key.includes(value))) {
  //     date.enable();
  //   } else {
  //     date.reset();
  //     date.disable();
  //   }
  // }

  getLanguageRequirementsByType(type: string): FormControl[] {
    const group: FormGroup = this.getGroup('language_requirements', this.inputForm);
    return Object
      .keys(group.controls)
      .filter((controlName: string): boolean => controlName.includes(type))
      .map((controlName: string): FormControl => {
        return this.getControl(controlName, group);
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.englishExamSubscriber?.unsubscribe();
    this.gmatExamScoresSubscriber?.unsubscribe();
    this.greExamScoresSubscriber?.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { UserRole } from '@models/enums/user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  readonly defaultPath: string = 'ACCOUNT_SIDEBAR.NAV.';
  sidebarNavSchool: any = [
    {
      label: `${this.defaultPath}HOME`,
      href: '/account',
      svgIcon: 'home'
    },
    {
      label: `${this.defaultPath}ABOUT_SCHOOL`,
      href: '/account/school',
      svgIcon: 'school'
    },
    {
      label: `${this.defaultPath}PROGRAMS`,
      href: '/account/program',
      svgIcon: 'account-program'
    },
    {
      label: `${this.defaultPath}STUDENTS`,
      href: '/account/student',
      svgIcon: 'student'
    },
    {
      label: `${this.defaultPath}SERVICES`,
      href: '/account/services',
      svgIcon: 'service'
    },
    {
      label: `${this.defaultPath}PROMOTIONS`,
      href: '/account/promotions',
      svgIcon: 'suitcase'
    },
    {
      label: `${this.defaultPath}MESSENGER`,
      href: '/account/messenger',
      svgIcon: 'messenger'
    },
    {
      label: `${this.defaultPath}SETTING`,
      href: '/account/settings',
      svgIcon: 'gear'
    },
    {
      label: `${this.defaultPath}FAQ`,
      href: '/account/fag',
      svgIcon: 'faq'
    }
  ];

  sidebarNavStudent: any = [
    {
      label: `${this.defaultPath}HOME`,
      href: '/account',
      svgIcon: 'home'
    },
    {
      label: `${this.defaultPath}PROFILE`,
      href: '/account/profile/',
      svgIcon: 'user'
    },
    {
      label: `${this.defaultPath}FAVORITES`,
      href: '/account/favorite-programs',
      svgIcon: 'favorites'
    },
    {
      label: `${this.defaultPath}APPLICATIONS`,
      href: '/account/applications/',
      svgIcon: 'diagram'
    },
    {
      label: `${this.defaultPath}SERVICES_PROMOTIONS`,
      href: '/account/promotions',
      svgIcon: 'suitcase'
    },
    {
      label: `${this.defaultPath}MESSENGER`,
      href: '/account/messenger',
      svgIcon: 'messenger'
    },
    {
      label: `${this.defaultPath}SETTING`,
      href: '/account/settings',
      svgIcon: 'gear'
    },
    {
      label: `${this.defaultPath}FAQ`,
      href: '/account/fag',
      svgIcon: 'faq'
    }
  ];

  sidebarNavRecruiter: any = [
    {
      label: `${this.defaultPath}HOME`,
      href: '/account',
      svgIcon: 'home'
    },
    {
      label: `${this.defaultPath}PROFILE`,
      href: '/account/profile/',
      svgIcon: 'user'
    },
    {
      label: `${this.defaultPath}STUDENTS`,
      href: '/account/student',
      svgIcon: 'student'
    },
    {
      label: `${this.defaultPath}APPLICATIONS`,
      href: '/account/applications/',
      svgIcon: 'diagram'
    },
    {
      label: `${this.defaultPath}SERVICES`,
      href: '/account/services',
      svgIcon: 'service'
    },
    {
      label: `${this.defaultPath}PROMOTIONS`,
      href: '/account/promotions',
      svgIcon: 'suitcase'
    },
    {
      label: `${this.defaultPath}MESSENGER`,
      href: '/account/messenger',
      svgIcon: 'messenger'
    },
    {
      label: `${this.defaultPath}SETTING`,
      href: '/account/settings',
      svgIcon: 'gear'
    },
    {
      label: `${this.defaultPath}FAQ`,
      href: '/account/fag',
      svgIcon: 'faq'
    },
    {
      label: `${this.defaultPath}STATISTIC`,
      href: '/account/statistic',
      icon: 'analytics'
    }
  ];

  constructor(private auth: AuthService) {}

  get currentNav(): any {
    switch (this.auth.myRole) {
      case UserRole.school:
        return this.sidebarNavSchool;
      case UserRole.recruiter:
        return this.sidebarNavRecruiter;
      case UserRole.student:
        return this.sidebarNavStudent;
      default:
        return [];
    }
  }

  logout(): void {
    this.auth.logout();
  }
}

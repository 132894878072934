import { NgModule } from '@angular/core';
import { StudentProfileFormComponent } from '@shared/components/student-profile-form/student-profile-form.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundInfoFormComponent } from './background-info-form/background-info-form.component';
import { DocumentsFormComponent } from './documents-form/documents-form.component';
import { EducatorHistoryFormComponent } from './educator-history-form/educator-history-form.component';
import { GeneralInfoFormComponent } from './general-info-form/general-info-form.component';
import { TestScoresFormComponent } from './test-scores-form/test-scores-form.component';
import { AppFormsModule } from '@forms/forms.module';
import { GoogleMapSearchModule } from '@shared/components/google-map-search/google-map-search.module';
import { MaterialModule } from '@shared/material/material.module';
import { FileUploadingModule } from '@shared/components/file-uploading/file-uploading.module';
import { MainStepperModule } from '@shared/components/main-stepper/main-stepper.module';
import { StudentProfileFormHelperComponent } from '@shared/components/student-profile-form/student-profile-form.helper.component';

@NgModule({
  declarations: [
    StudentProfileFormComponent,
    BackgroundInfoFormComponent,
    DocumentsFormComponent,
    EducatorHistoryFormComponent,
    GeneralInfoFormComponent,
    TestScoresFormComponent,
    StudentProfileFormHelperComponent
  ],
  imports: [CommonModule, TranslateModule, AppFormsModule, GoogleMapSearchModule, MaterialModule, FileUploadingModule, MainStepperModule],
  exports: [StudentProfileFormComponent]
})
export class StudentProfileFormModule {}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogService } from '@services/dialog/dialog.service';
import { FilterContentComponent } from '@shared/components/filter-popup/filter-content/filter-content.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { prepareFilterOption } from '@misc/helpers/prepare-filter-option';
import { FilterPopupAction } from '@models/enums/filter-popup-action';

@Component({
  selector: 'filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {

  @Input() btnLabel: string;
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Output() useFilters: EventEmitter<any> = new EventEmitter();
  defaultFloor: number = 0;
  defaultCeil: number =  300;
  formGroup: FormGroup;

  constructor(private dialog: DialogService, private fb: FormBuilder) {}

  get label(): string {
    return this.btnLabel ?? 'BUTTON_NAME.FILTERS';
  }

  ngOnInit(): void {
    this.formInit();
  }

  formInit(options?: any): void {
    this.formGroup = this.fb.group({
      welcome_packages: this.fb.control(options?.permit || null),
      nationality_id: this.fb.control(options?.nationality || null),
      country_id: this.fb.control(options?.country || null),
      level_id: this.fb.array(options?.level || []),
      grading_id: this.fb.control(options?.grading || null), // TODO: not implemented
      english_exam_types: this.fb.control(options?.exam || null),
      direct_admissions: this.fb.control(options?.directAdmissions || false),
      intakes: this.fb.control(null),
      discipline_id: this.fb.control(null),
      category_id: this.fb.control(null),
      include_living_costs: this.fb.control(false),
      application_fee_from: this.fb.control(this.defaultFloor),
      application_fee_to: this.fb.control(this.defaultCeil),
      tuition_fee_from: this.fb.control(this.defaultFloor),
      tuition_fee_to: this.fb.control(this.defaultCeil),
    });
  }

  resetForm(): void {
    this.formGroup.reset();
    const levelIdControl: FormArray = this.formGroup.get('level_id') as FormArray;
    levelIdControl.clear();
    this.formGroup.get('application_fee_from').setValue(this.defaultFloor);
    this.formGroup.get('application_fee_to').setValue(this.defaultCeil);
    this.formGroup.get('tuition_fee_from').setValue(this.defaultFloor);
    this.formGroup.get('tuition_fee_to').setValue(this.defaultCeil);
  }

  openModal(): void {
    this.dialog
      .open({
        width: '950px',
        data: {
          form: this.formGroup,
          reset: this.resetForm.bind(this)
        }
      }, FilterContentComponent)
      .subscribe((action: FilterPopupAction): void => {
        switch (action) {
          case FilterPopupAction.apply:
            this.applyFilter();
            break;
          case FilterPopupAction.reset:
            this.resetForm();
            break;
          case FilterPopupAction.resetApply:
            this.resetForm();
            this.applyFilter();
            break;
        }
    });
  }

  applyFilter(): void {
    this.useFilters.emit(prepareFilterOption(this.formGroup.value));
  }
}

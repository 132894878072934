import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { HttpService, IServicesConfig } from '@services/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountProfileService {
  private readonly baseUrl: string = '/api/v1/students';

  constructor(@Inject(APP_CONFIG) private config: IAppConfig, private http: HttpService) {}

  get apiUrl(): string {
    return `${this.config.apiUrl}${this.baseUrl}`;
  }

  fetchStudent(options?: any, services?: IServicesConfig | null): Observable<any> {
    return this.http.get(`${this.apiUrl}/student`, options, services);
  }

  fetchStudentById(id: number, options?: any, services?: IServicesConfig | null): Observable<any> {
    return this.http.get(`${this.apiUrl}/student/${id}`, options, services);
  }

  updateStudent(data: any, options?: any, services?: IServicesConfig | null): Observable<any> {
    return this.http.post(`${this.apiUrl}/update`, data, options, services);
  }

  updateStudentById(data: any, options?: any, services?: IServicesConfig | null): Observable<any> {
    const id: number = data.id;
    if (!id) {
      throw Error('Can not find property id');
    }

    return this.http.post(`${this.apiUrl}/update/${id}`, data, options, services);
  }
}

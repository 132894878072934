<div class="main-stepper__total-percent">
  {{ 'ACCOUNT_STUDENT.PROFILE_IS_COMPLETED_ON' | translate }}
  <span class="main-stepper__total-percent-value">{{ totalPercent }}</span> %
</div>
<div class="main-stepper__holder">
  <div *ngFor="let step of steps" (click)="stepActionHandler(step)" class="main-stepper__item">
    <ng-container *ngTemplateOutlet="step.active ? activeIcon : defaultIcon; context: { step: step }"></ng-container>
    <span class="main-stepper__label">{{ step.label }}</span>
  </div>
</div>

<ng-template #activeIcon let-step="step">
  <span class="main-stepper__icon active"></span>
</ng-template>
<ng-template #defaultIcon let-step="step">
  <mat-icon
    [ngClass]="{
      active: step.active,
      done: statusStep.complete === step.status && !step.active,
      touched: statusStep.touched === step.status && !step.active,
      untouched: statusStep.untouched === step.status && !step.active
    }"
    class="main-stepper__icon"
  >
    {{ getCurrentIcon(step.status) }}
  </mat-icon>
</ng-template>

import { Inject, Injectable } from '@angular/core';
import { IFileModel } from '@models/interfaces/file-model.interface';
import { HttpService } from '@services/http/http.service';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpServiceError } from '@services/http/http-service-error.class';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(@Inject(APP_CONFIG) protected config: IAppConfig, private http: HttpService) {
  }

  uploadFile(data: IFileModel[]): Observable<ArrayBuffer[]> {
    return this.multipleUploadFiles(data);
  }

  private singleUploadFile(file: any ): Observable<ArrayBuffer> {
    const fd: FormData = new FormData();
    fd.append('file', file);
    return this.http
      .post(`${this.config.apiUrl}/api/v1/files/upload`, fd, {skipErrorNotification: true})
      .pipe(catchError(this.errorUploadFile.bind(this, file)));
  }

  private multipleUploadFiles(data: IFileModel[]): Observable<ArrayBuffer[]> {
    const requests: Observable<ArrayBuffer>[] = data.map((file: IFileModel): Observable<ArrayBuffer> => this.singleUploadFile(file));

    return forkJoin(requests);
  }

  errorUploadFile(file: IFileModel, error: HttpErrorResponse): Observable<any> {
    const customError: HttpServiceError = new HttpServiceError(error);
    throwError(customError);
    return of(null);
  }
}

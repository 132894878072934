export enum MediaBreakpoints {
  phoneS = '(max-width: 480px)',
  phoneL = '(max-width: 680px)',
  tabletS = '(max-width: 767px)',
  tabletM = '(max-width: 992px)',
  tabletL = '(max-width: 1024px)',
  desktopS = '(max-width: 1280px)',
  desktopM = '(max-width: 1366px)',
  desktopL = '(max-width: 1450px)'
}

<mat-form-field [appearance]='appearance'>
  <mat-label *ngIf='placeholder'>{{ placeholder }}</mat-label>
  <input
    matInput
    #searchElem
    [formControl]="control"
    [placeholder]='placeholder'
    [readonly]='readonly'
  >
</mat-form-field>


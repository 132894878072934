import { Injectable } from '@angular/core';
import { ApiBaseAbstractService, ITransitData } from '@misc/abstracts/api-base.abstract.service';
import { User } from '@models/classes/user.model';
import { IServicesConfig } from '@services/http/http.service';
import { Observable, of } from 'rxjs';
import { Params } from '@angular/router';
import { ClassType } from 'class-transformer/ClassTransformer';
import { UserTokenAction } from '@models/enums/user-token-action.enum';
import { IParticipant, IShareAccess } from '@models/interfaces/profile.interface';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiBaseAbstractService<User> {
  protected readonly URLPath: string = '/api/v1/users';
  protected readonly model: ClassType<User> = User;

  getMe(services?: IServicesConfig): Observable<User> {
    return this.getItem('me', {}, services);
  }

  getUserById(id: number, services?: IServicesConfig): Observable<User> {
    return this.http.get(`${this.config.apiUrl}${this.URLPath}/get-by-id/${id}`, services);
  }

  updateMe(userData: IParticipant): Observable<User> {
    return this.http.post(`${this.config.apiUrl}${this.URLPath}/update`, userData);
  }

  updateById(id: number, userData: IParticipant): Observable<User> {
    return this.http.post(`${this.config.apiUrl}${this.URLPath}/update/${id}`, userData);
  }

  // updateMe(userData: IParticipant): Observable<User> {
  //   return this.http.post(`${this.config.apiUrl}${this.URLPath}/update`, userData);
  // }

  logout(services?: IServicesConfig): Observable<void> {
    return this.http.delete(`${this.config.apiUrl}/api/v1/auth/logout`, {}, services);
  }

  availableEmail(email: string, services?: IServicesConfig): Observable<any> {
    return this.http.get(`${this.config.apiUrl}/api/users/available/email/${email}`, {}, services);
  }

  sendToken(email: string, type: UserTokenAction, payload?: { [key: string]: string }, services?: IServicesConfig): Observable<any> {
    return this.http.post(`${this.config.apiUrl}/api/users/send/token`, { email, type, payload }, {}, services);
  }

  updatePassword(token: string, params: Params, services?: IServicesConfig): Observable<any> {
    return this.http.patch(`${this.config.apiUrl}/api/users/${token}/password`, { user: params }, {}, services);
  }

  changePassword(params: Params, services?: IServicesConfig): Observable<any> {
    return this.http.post(`${this.config.apiUrl}/api/v1/users/change-password`, params, {}, services);
  }

  confirmAccount(token: string, services?: IServicesConfig): Observable<any> {
    return this.http.patch(`${this.config.apiUrl}/api/users/${token}/confirm`, { user: { confirmed: true } }, {}, services);
  }

  resetPassword(email: string, services?: IServicesConfig): Observable<any> {
    return this.http.post(`${this.config.apiUrl}/api/v1/auth/reset-password`, { email }, {}, services);
  }

  delete(services?: IServicesConfig): Observable<void> {
    return this.http.delete(`${this.config.apiUrl}/api/v1/users/delete`, {}, {});
  }

  shareAccess(userData: IShareAccess): Observable<void> {
    return this.http.post(`${this.config.apiUrl}${this.URLPath}/share-access`, userData);
  }
}

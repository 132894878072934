import { NgModule } from '@angular/core';
import { InputSliderComponent } from '@forms/input-slider/input-slider.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@pipes/pipes.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [InputSliderComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PipesModule, NgxSliderModule],
  exports: [InputSliderComponent]
})
export class InputSliderModule {}

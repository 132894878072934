import { ISocialNetwork } from '@models/interfaces/social-network.interface';

export const socialNetwork: ISocialNetwork[] = [
  {
    href: 'https://m.facebook.com/applyclever',
    target: '_blank',
    svgIcon: 'fb'
  },
  {
    href: 'https://www.youtube.com/channel/UChBOdmkQUdpFf5jde_732mw',
    target: '_blank',
    svgIcon: 'youtube'
  },
  {
    href: 'https://instagram.com/applyclever?utm_medium=copy_link',
    target: '_blank',
    svgIcon: 'instagram'
  },
  {
    href: 'https://twitter.com/ApplyClever',
    target: '_blank',
    svgIcon: 'twitter'
  },
  {
    href: 'https://www.linkedin.com/in/applyclever/',
    target: '_blank',
    svgIcon: 'linkedin'
  }
];

import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'google-map-search',
  templateUrl: './google-map-search.component.html',
  styleUrls: ['./google-map-search.component.scss']
})
export class GoogleMapSearchComponent implements OnInit, OnDestroy {

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() placeholder: string;
  @Input() readonly: boolean = false;
  @Input() control: FormControl = new FormControl(null);

  @ViewChild('searchElem', {static: true}) searchElem: ElementRef;

  ngOnInit(): void {
    const search: any = new window.google.maps.places.Autocomplete(this.searchElem.nativeElement);
    search.addListener('place_changed', (): void => {
      this.control.setValue(this.searchElem.nativeElement.value);
    });
  }

  manualRemoveGoogleContainer(): void {
    document.querySelector('.pac-container').remove();
  }

  ngOnDestroy(): void {
    this.manualRemoveGoogleContainer();
  }
}

<h3 class="footer__title" *ngIf="title">{{ title | translate }}</h3>
<ul class="footer-list" [class.footer-list_only]="!title" *ngIf="linkList?.length">
  <li class="footer-list__item" *ngFor="let link of linkList">
    <ng-container
      *ngTemplateOutlet='link.queryParams ? withQueryParams : simpleLink; context: {link: link}'
    ></ng-container>
  </li>
</ul>

<ng-template #simpleLink let-link="link">
  <a [routerLink]="link.path" class="footer-list__link">{{ link.value | translate }}</a>
</ng-template>

<ng-template #withQueryParams let-link="link">
  <a [routerLink]="link.path" [queryParams]="link.queryParams" class="footer-list__link">{{ link.value | translate }}</a>
</ng-template>

import { NgModule } from '@angular/core';
import { AuthNavComponent } from '@modules/auth/auth-nav/auth-nav.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@shared/material/material.module';

@NgModule({
  declarations: [AuthNavComponent],
  imports: [CommonModule, TranslateModule, RouterModule, MaterialModule],
  exports: [AuthNavComponent]
})
export class AuthNavModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewProgramPopupComponent } from '@shared/components/review-program-popup/review-program-popup.component';
import { ProgramDetailsModule } from '@modules/main/common/program-details/program-details.module';

@NgModule({
  declarations: [ReviewProgramPopupComponent],
  imports: [CommonModule, ProgramDetailsModule],
  exports: [ReviewProgramPopupComponent]
})
export class ReviewProgramPopupModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { UserRole } from '@models/enums/user-role.enum';
import { showHide } from '@app/animation';
import { ISchooling } from '@models/interfaces/schooling.interface';
import { FactIconsEnum } from '@models/enums/fact-icon-list.enum';
import { SuggestionItemTypeEnum } from '@models/enums/suggestion-item-type.enum';
import { valueIsNumber } from '@app/misc/helpers/value-is-number';
import { AccountService } from '@services/api/account/account.service';
import { SnackBarNotificationType } from '@models/enums/snack-bar-notification-type.enum';
import { NotificationService } from '@services/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { UserProgramsApiService } from '@services/api/user-programs/user-programs-api.service';
import { SaveCurrentSessionService } from '@services/save-current-session/save-current-session.service';

@Component({
  selector: 'suggestion-item',
  templateUrl: './suggestion-item.component.html',
  styleUrls: ['./suggestion-item.component.scss'],
  animations: [showHide],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionItemComponent {
  readonly key: string = 'PAGE_DETAIL.';
  readonly factIconsList: typeof FactIconsEnum = FactIconsEnum;
  readonly roleList: typeof UserRole = UserRole;
  @Input() type: SuggestionItemTypeEnum = SuggestionItemTypeEnum.SCHOOL;
  @Input() content: ISchooling;
  @Input() showFavoriteBtn: boolean = true;

  typeList: typeof SuggestionItemTypeEnum = SuggestionItemTypeEnum;
  showExtraInfo: boolean = false;

  constructor(
    private auth: AuthService,
    private accountService: AccountService,
    private notification: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslatePipe,
    private userProgramsApiService: UserProgramsApiService,
    private saveCurrentSession: SaveCurrentSessionService
  ) {}

  get role(): UserRole {
    return this.auth.myRole;
  }

  get canShowTimer(): boolean {
    return !!(this.content?.going_live?.enable && this.content?.going_live?.date && this.content?.going_live?.video_link);
  }

  get suggestionId(): number {
    return SuggestionItemTypeEnum.SCHOOL === this.type ? this.content.id : this.content.school_id;
  }

  get shouldHideSchoolLink(): boolean {
    if (!this.activatedRoute.snapshot.params?.id) {
      return true;
    }
    return SuggestionItemTypeEnum.PROGRAM === this.type && this.content.school_id === this.activatedRoute.snapshot.params.id;
  }

  get userIsAuth(): boolean {
    return this.auth.isAuthenticated;
  }

  dateFuture(): string {
    const date: Date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).toString();
  }

  detailUrl(id: string | number): string[] {
    return [`/suggestion/${this.typeList.SCHOOL === this.type ? 'school-details' : 'program-details'}/${id}`];
  }

  programQueryParams(): {student?: string} {
    if (SuggestionItemTypeEnum.PROGRAM === this.type) {
      return {
        student: this.activatedRoute.snapshot.queryParams.student
      };
    }
    return {};
  }

  isNumber(value: any): boolean {
    return valueIsNumber(value);
  }

  addToFavorite(id: number): void {
    if (!this.auth.isAuthenticated) {
      this.notification.addToQueue(this.translate.transform(`MESSAGE.YOU_ARE_NOT_LOGGED`), SnackBarNotificationType.warning, {
        duration: 10000
      });
      this.saveCurrentSession.saveSession();
      this.router.navigate(['', 'auth', 'log-in']);
      return;
    }
    this.content.is_favorite = true;
    this.userProgramsApiService.favorite(id, { status: true }).subscribe(
      (response: any): void => {
        this.notification.addToQueue(this.translate.transform(`MESSAGE.ADDED_TO_FAVORITE`), SnackBarNotificationType.success, {
          duration: 10000
        });
      },
      (error: any): void => {
        Object.values(error.error.errors).map((message: string): void => {
          this.notification.addToQueue(message, SnackBarNotificationType.error);
        });
      }
    );
  }

  deleteFromFavorite(id: number): void {
    this.content.is_favorite = false;
    this.userProgramsApiService.favorite(id, { status: false }).subscribe(
      (response: any): void => {
        this.notification.addToQueue(this.translate.transform(`MESSAGE.DELETE_FROM_FAVORITE`), SnackBarNotificationType.success);
      },
      (error: any): void => {
        Object.values(error.error.errors).map((message: string): void => {
          this.notification.addToQueue(message, SnackBarNotificationType.error);
        });
      }
    );
  }

  forceMakeApplied(): void {
    this.content = {...this.content, applied: true};
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'auth-nav',
  templateUrl: './auth-nav.component.html',
  styleUrls: ['./auth-nav.component.scss']
})
export class AuthNavComponent {
  readonly key: string = 'COMMON.';
}

import { Component, OnInit, Output, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';
import { BaseFormFieldAbstractComponent } from '@misc/abstracts/base-form-field.abstract.component';
import { InputType } from '@models/enums/input-type.enum';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IOption } from '@forms/base-select/base-select.component';

@Component({
  selector: 'base-autocomplete',
  templateUrl: './base-autocomplete.component.html',
  styleUrls: ['./base-autocomplete.component.scss']
})
export class BaseAutocompleteComponent extends BaseFormFieldAbstractComponent implements OnInit {
  get type(): string {
    return InputType.text;
  }
  inputType: InputType;
  @Input() options: any[];
  @Input() template: TemplateRef<any>;
  @Output() inputSearch: EventEmitter<string> = new EventEmitter();
  @Output() search: Subject<Event> = new Subject();
  @ViewChild('auto', { static: false }) autocomplete: any;
  @Input() getValue: (item: IOption) => any = item => (item as IOption).value;
  @Input() displayWith: (item: any) => any = item => item as IOption;

  ngOnInit(): void {
    this.search.pipe(debounceTime(300), distinctUntilChanged()).subscribe((event: Event): void => {
      const target: HTMLInputElement = event.target as HTMLInputElement;
      this.inputSearch.emit(target.value);
    });
  }
}

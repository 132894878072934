import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@misc/abstracts/api-base.abstract.service';
import { ClassType } from 'class-transformer/ClassTransformer';
import { FileResponse } from '@models/classes/file-response.model';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { IServicesConfig } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UserProgramsApiService extends ApiBaseAbstractService<FileResponse> {
  protected model: ClassType<FileResponse> = FileResponse;
  protected URLPath: string = '/api/v1/user-programs';

  getAllFavorites(params?: Params): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/user-programs/favorite`, { params });
  }

  favorite(programId: number, params?: any, options?: any, services?: IServicesConfig | null): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/user-programs/favorite/${programId}`, params, options, services);
  }
}

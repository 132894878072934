import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BaseEditorComponent } from '@shared/components/base-editor/base-editor.component';

@NgModule({
  declarations: [BaseEditorComponent],
  imports: [CommonModule, AngularEditorModule, ReactiveFormsModule, EditorModule],
  exports: [BaseEditorComponent]
})
export class BaseEditorModule {}

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { UserRole } from '@models/enums/user-role.enum';
import { MediaMatcherService } from '@services/media-matcher/media-matcher.service';
import { MediaBreakpoints } from '@models/enums/media-breakpoints.enum';
import { MatDrawerMode } from '@angular/material/sidenav/drawer';

@Component({
  selector: 'account-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss']
})
export class AccountLayoutComponent implements OnInit {
  readonly roleList: typeof UserRole = UserRole;
  sidebar$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  fsd: any;

  constructor(private auth: AuthService, private mediaMatcherService: MediaMatcherService) {
  }

  ngOnInit(): void {
    this.fsd = this.breakpointByMediaIsMatched$.subscribe((matches: boolean): void => {
      this.sidebar$.next(!matches);
    });
  }

  get breakpointByMediaIsMatched$(): Observable<boolean> {
    return this.mediaMatcherService.breakpointByMediaIsMatched$(MediaBreakpoints.tabletM);
  }

  get role(): UserRole {
    return this.auth.myRole;
  }

  get userId(): number {
    return this.auth.me?.id;
  }

  get actionIcon(): string {
    return this.sidebar$.value ? 'chevron_left' : 'chevron_right';
  }

  get matSidenavMode(): MatDrawerMode {
    if (this.mediaMatcherService.breakpointIsMatched(MediaBreakpoints.tabletM)) {
      return 'over';
    }
    return 'side';
  }

  get fixedTopGapOffset(): number {
    return this.mediaMatcherService.breakpointIsMatched(MediaBreakpoints.tabletM) ? 70 : 100;
  }

  sidebarTrigger(): void {
    this.sidebar$.next(!this.sidebar$.value);
  }

  matSidenavToggle($event: Event): void {
    console.log('$event', $event);
  }
}

<div *ngIf="programDetail" class="program-details">
  <program-preview [imgSrc]="programDetail.background?.url"></program-preview>
  <div class="program-details__holder">
    <ul *ngIf="!idProgram && programDetail.school?.id" class="program-details__breadcrumbs">
      <li class="program-details__breadcrumbs-item">
        <a [routerLink]="'/'" class="program-details__breadcrumbs-link">
          {{ 'COMMON.HOME' | translate }}
        </a>
        <mat-icon class="program-details__breadcrumbs-icon">keyboard_arrow_right</mat-icon>
      </li>
      <li class="program-details__breadcrumbs-item">
        <a [routerLink]="'/suggestion/school-details/' + programDetail.school.id" class="program-details__breadcrumbs-link">
          {{ programDetail.school.name }}
        </a>
        <mat-icon class="program-details__breadcrumbs-icon">keyboard_arrow_right</mat-icon>
      </li>
      <li class="program-details__breadcrumbs-item">
        <span>
          {{ programDetail.name }}
        </span>
      </li>
    </ul>
    <div
      [ngClass]="{
        'w-100': idProgram
      }"
      class="program-details__content"
    >
      <school-info-card class="m-b-9">
        <mat-tab-group class="detail-info-tabs" animationDuration="0ms" (selectedIndexChange)='selectedIndexHandle($event)'>
          <mat-tab [label]="key + 'PROGRAMS_DESCRIPTION_TAB_LABEL' | translate | uppercase">
            <program-description [program]="programDetail"></program-description>
          </mat-tab>
          <mat-tab [label]="key + 'ADMISSION_REQUIREMENTS_TAB_LABEL' | translate | uppercase">
            <program-requirements-candidate [program]="programDetail"></program-requirements-candidate>
          </mat-tab>
          <mat-tab [label]="key + 'FEE_TAB_LABEL' | translate | uppercase">
            <div class="tab-content-page-detail">
              <div *ngIf="checkNumberType(programDetail?.application_fee.amount)" class="tab-content-page-detail-field">
                <span>{{ key + 'DETAIL_TAB_APP_FEE_FIELD' | translate }}:</span>
                <b class="accent-bold-text">
                  {{
                    programDetail?.application_fee.amount
                      | currency: programDetail?.application_fee?.currency?.toUpperCase():'symbol':'1.2-2'
                  }}
                </b>
              </div>
              <div *ngIf="checkNumberType(programDetail?.tuition_fee?.amount)" class="tab-content-page-detail-field">
                <span>{{ key + 'TUITION_FREE_LABEL' | translate }}:</span>
                <b class="accent-bold-text">
                  {{ programDetail?.tuition_fee?.amount | currency: programDetail?.tuition_fee?.currency?.toUpperCase():'symbol':'1.2-2' }}
                </b>
              </div>
              <div *ngIf="checkNumberType(programDetail?.other_fees?.amount)" class="tab-content-page-detail-field">
                <span class="average-bold-text">{{ key + 'OTHER_FEES' | translate }}</span>
                <ul class="simple-list">
                  <li class="simple-list__item">
                    {{ key + 'AMOUNT_LABEL' | translate }}:
                    <b class="accent-bold-text">
                      {{ programDetail?.other_fees?.amount | currency: programDetail?.other_fees?.currency.toUpperCase():'symbol':'1.2-2' }}
                    </b>
                  </li>
                  <li class="simple-list__item">
                    {{ key + 'CURRENCY_LABEL' | translate }}:
                    <b class="accent-bold-text">{{ programDetail?.other_fees?.currency.toUpperCase() | uppercase }}</b>
                  </li>
                  <li class="simple-list__item">
                    {{ key + 'FREQUENCY_LABEL' | translate }}:
                    <b class="accent-bold-text">{{ programDetail?.other_fees?.frequency }}</b>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf='programDetail.testimonials?.length' [label]="key + 'TESTIMONIALS_TAB_LABEL' | translate | uppercase">
            <testimonial-item [program]="programDetail"></testimonial-item>
          </mat-tab>
          <mat-tab [label]="key + 'FACT_LABEL' | translate | uppercase">
            <program-tab-facts [program]="programDetail"></program-tab-facts>
          </mat-tab>
        </mat-tab-group>
        <div class="program__actions">

          <apply-to-program
            [contentId]='programDetail.id'
            [needDisable]='programDetail.applied'
            (forceMakeApplied)='forceMakeApplied()'
            [label]="'BUTTON_NAME.APPLY' | translate | uppercase"
            extraClass='mid-size-btn'
          ></apply-to-program>
        </div>
      </school-info-card>

      <school-info-card *ngIf='activeTabIndex === 0' [title]="key + 'FACT_LABEL' | translate" class="m-b-9">
        <ul class="program-fact-list">
          <li *ngIf="programDetail?.level" class="program-fact-list__item">
            <mat-icon svgIcon="study"></mat-icon>
            <b>{{ key + 'DETAIL_TAB_PROGRAM_LEVEL_FIELD' | translate }}: {{ programDetail?.level.name }}</b>
          </li>
          <li *ngIf="checkNumberType(programDetail?.application_fee?.amount)" class="program-fact-list__item">
            <mat-icon svgIcon="suitcase"></mat-icon>
            {{ key + 'APPLICATION_FEE_LABEL' | translate }}:
            <b>
              {{ programDetail.application_fee.amount | currency: programDetail.application_fee?.currency?.toUpperCase():'symbol':'2.2-2' }}
            </b>
          </li>
          <li *ngIf="checkNumberType(programDetail.tuition_fee?.amount)" class="program-fact-list__item">
            <mat-icon svgIcon="currency-dollar"></mat-icon>
            {{ key + 'TUITION_FREE_LABEL' | translate }}:
            <b>
              {{ programDetail.tuition_fee.amount | currency: programDetail.tuition_fee?.currency?.toUpperCase():'symbol':'2.2-2' }}
            </b>
            /year
          </li>
          <li
            *ngIf="checkNumberType(programDetail?.school?.cost_living?.sum) && programDetail?.school?.cost_living?.currency"
            class="program-fact-list__item"
          >
            <mat-icon svgIcon="house"></mat-icon>
            {{ key + 'COST_OF_LIVING_TYPE_2_LABEL' | translate }}:
            <b>
              {{
                programDetail?.school?.cost_living?.sum
                  | currency: programDetail?.school?.cost_living?.currency?.toUpperCase():'symbol':'2.2-2'
              }}
            </b>
            /
            {{ programDetail?.school?.cost_living?.period }}
          </li>
          <li class="program-fact-list__item order-3">
            {{ key + 'YOU_MAY_CHECK_AT' | translate }}:
            <a href="https://www.numbeo.com/" target="_blank" class="program-fact-list__link">{{ key + 'NUMBEO_SITE_NAME' | translate }}</a>
          </li>
          <li *ngIf='courseDurationField()' class="program-fact-list__item">
            <mat-icon svgIcon="calendar"></mat-icon>
            {{ key + 'DURATION' | translate }}:
            <b>
              {{ courseDurationField() }}
            </b>
          </li>
          <li *ngIf="programDetail?.study_type" class="program-fact-list__item">
            <mat-icon svgIcon="four-dots"></mat-icon>
            {{ key + 'TYPE_OF_STUDY' | translate }}:
            <b>{{ programDetail?.study_type?.name }}</b>
          </li>
        </ul>
      </school-info-card>
      <!--      <school-info-card-->
      <!--        *ngIf='false'-->
      <!--        [title]="key + 'SIMILAR_PROGRAMS_LABEL' | translate"-->
      <!--        class="m-b-9"-->
      <!--      >-->
      <!--        <ul class="similar-program-list">-->
      <!--          <li class="similar-program-list__item">-->
      <!--            <a href='#' class="similar-program-list__link">-->
      <!--              <b>English for Academic Purpuses Program</b> Algoma University - Sault Ste. Marie-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li class="similar-program-list__item">-->
      <!--            <a href='#' class="similar-program-list__link">-->
      <!--              <b>English for Academic Purpuses Program</b> Algoma University - Sault Ste. Marie-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li class="similar-program-list__item">-->
      <!--            <a href='#' class="similar-program-list__link">-->
      <!--              <b>English for Speakers of Other Languages - Intensive English Program </b> Alliant International University-->
      <!--            </a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </school-info-card>-->
    </div>
    <div *ngIf=" false && !idProgram" class="program-details__advertising"></div>
  </div>
</div>

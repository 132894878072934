import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AppAbstractComponent } from '@misc/abstracts/app.abstract.component';
import { IconsService } from '@services/icons/icons.service';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { FilterService } from '@services/filter/filter.service';
import { ActivatedRoute, Data, Event, NavigationEnd, Route, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { SeoService } from '@services/seo/seo.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  responsiveOptions: any;
  routeSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) protected config: IAppConfig,
    private icons: IconsService,
    private filterService: FilterService,
    private router: ActivatedRoute,
    private route: Router,
    private seoService: SeoService
  ) {}

  ngOnInit(): void {
    console.log('App Version: ' + this.config.version);
    this.routeSubscription = this.route.events
      .pipe(filter((event: Event): boolean => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd): void => {
        this.forceResetSuggestionFilters(event);
      });

    this.updateMetaTags();

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  updateMetaTags(): void {
    this.route.events
      .pipe(
        filter((event: Event): boolean => event instanceof NavigationEnd),
        map((): ActivatedRoute => this.router),
        map(
          (route: ActivatedRoute): ActivatedRoute => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }
        ),
        filter((route: ActivatedRoute): boolean => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute): Observable<Data> => route.data)
      )
      .subscribe((event: Data): void => {
        this.seoService.applyData(event);
      });
  }

  forceResetSuggestionFilters(routeEvent: NavigationEnd): void {
    const blackList: string[] = ['/', '/start-here'];

    if (blackList.some((item: string): boolean => item === routeEvent.url)) {
      this.filterService.removeFilterOption();
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }
}

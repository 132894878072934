<div *ngIf='student; else loader' class='student-profile-detail'>
  <div class='flex-grid'>
    <div class="cell small-only-12">
      <h2 class='title'>{{ key + 'PERSONAL_INFORMATION_TITLE' | translate }}</h2>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <div class='student-profile-detail__image-holder'>
        <img
          [src]='student.avatar?.url'
          alt=''
          class='student-profile-detail__image'
        >
      </div>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span *ngIf='student.first_name' class='display-block m-b-4'>
        {{ key + 'FIST_NAME_LABEL' | translate }}:
        <b>{{ student.first_name }}</b>
      </span>
      <span *ngIf='student.patronymic' class='display-block m-b-4'>
        {{ key + 'MIDDLE_NAME_LABEL' | translate }}:
        <b>{{ student.patronymic }}</b>
      </span>
      <span *ngIf='student.last_name' class='display-block m-b-4'>
        {{ key + 'LAST_NAME_LABEL' | translate }}:
        <b>{{ student.last_name }}</b>
      </span>
      <span *ngIf='student.email' class='display-block m-b-4'>
        {{ key + 'EMAIL_LABEL' | translate }}:
        <b>{{ student.email }}</b>
      </span>
      <span *ngIf='student.date_of_birth' class='display-block m-b-4'>
        {{ key + 'DATE_OF_BIRTH_LABEL' | translate }}:
        <b>{{ student.date_of_birth | date: 'MMMM d, y' }}</b>
      </span>
      <span *ngIf='student.phone' class='display-block m-b-4'>
        {{ key + 'PHONE_NUMBER_LABEL' | translate }}:
        <b>{{ student.phone }}</b>
      </span>
      <span *ngIf='student.passport_number' class='display-block m-b-4'>
        {{ key + 'PASSPORT_NUMBER_LABEL' | translate }}:
        <b>{{ student.passport_number }}</b>
      </span>
      <span *ngIf='student.gender' class='display-block m-b-4'>
        {{ key + 'GENDER_TITLE' | translate }}:
        <b>{{ student.gender }}</b>
      </span>
      <span *ngIf='student.marital_status' class='display-block m-b-4'>
        {{ key + 'MARITAL_STATUS_TITLE' | translate }}:
        <b>{{ student.marital_status }}</b>
      </span>
      <span *ngIf='student.country_of_citizenship' class='display-block m-b-4'>
        {{ key + 'COUNTRY_OF_CITIZENSHIP_LABEL' | translate }}:
        <b>{{ student.country_of_citizenship?.name }}</b>
      </span>
      <span *ngIf='student.languages' class='display-block m-b-4'>
        {{ key + 'LANGUAGES_LABEL' | translate }}:
        <b>{{ student.languages }}</b>
      </span>
    </div>
  </div>
  <div class='flex-grid'>
    <div class="cell small-only-12">
      <h2 class='title'>{{ key + 'ADDRESS_DETAIL_TITLE' | translate }}</h2>
    </div>
    <div *ngIf='student.address' class='cell large-6 middle-6 small-12'>
      <span>
        {{ 'COMMON.LOCATION' | translate }}:
        <b>{{ student.address }}</b>
      </span>
    </div>
    <div *ngIf='student.province' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'PROVINCE_LABEL' | translate }}:
        <b>{{ student.province }}</b>
      </span>
    </div>
    <div *ngIf='student.city' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'CITY_LABEL' | translate }}:
        <b>{{ student.city }}</b>
      </span>
    </div>
    <div *ngIf='student.zip_code' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'COUNTRY_OF_CITIZENSHIP_LABEL' | translate }}:
        <b>{{ student.zip_code }}</b>
      </span>
    </div>
    <div *ngIf='student.country_of_education' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'COUNTRY_OF_EDUCATION_LABEL' | translate }}:
        <b>{{ student.country_of_education.name }}</b>
      </span>
    </div>
    <div *ngIf='student.highest_level_of_education' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'HIGHEST_LEVEL_OF_EDUCATION_LABEL' | translate }}:
        <b>{{ student.highest_level_of_education }}</b>
      </span>
    </div>
    <div *ngIf='student.have_graduated_recent_school' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'HAVE_GRADUATED_RECENT_SCHOOL_LABEL' | translate }}:
        <b>
          {{ getLabel(student.have_graduated_recent_school, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div *ngIf='student.grading_scheme' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'GRADING_SCHEME_LABEL' | translate }}:
        <b>{{ student.grading_scheme }}</b>
      </span>
    </div>
    <div *ngIf='student.your_grade_average' class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'YOUR_GRADE_AVERAGE_LABEL' | translate }}:
        <b>{{ student.your_grade_average }}</b>
      </span>
    </div>
  </div>
  <div class='flex-grid'>
    <div class="cell small-only-12">
      <h2 class='title'>{{ key + 'ATTENDED_SCHOOL_TITLE' | translate }}</h2>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'PRIMARY_SCHOOL_LABEL' | translate }}:
        <b>{{ getDegreeLabel(student.primary_school, primarySchoolOption) }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'SECONDARY_SCHOOL_LABEL' | translate }}:
        <b>{{ getDegreeLabel(student.secondary_school, secondarySchool) }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'HIGH_SCHOOL_LABEL' | translate }}:
        <b>{{ getDegreeLabel(student.high_school, highSchoolOption) }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'BACHELORS_DEGREE_LABEL' | translate }}:
        <b>{{ getDegreeLabel(student.bachelors_degree, bachelorDegreeOption)  }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'MASTERS_DEGREE_LABEL' | translate }}:
        <b>{{ getDegreeLabel(student.masters_degree, mastersDegreeOption) }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span>
        {{ key + 'DOCTORATE_DEGREE_LABEL' | translate }}:
        <b>{{ student.doctorate_degree }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12'>
      <span class='display-block m-b-3'>
        {{ key + 'SCHOOL_LIST' | translate }}:
      </span>
      <ul class='student-profile-detail__school-list'>
        <li *ngFor='let item of student.school_list' class='student-profile-detail__school-list-item'>{{ item }}</li>
      </ul>
    </div>
  </div>
  <div *ngIf="student.english_exam_type !== 'nothing'" class='flex-grid'>
    <div class="cell cell_not-padding-bottom m-b-4 small-only-12">
      <span>
        {{ key + 'ENGLISH_EXAM_TYPE_LABEL' | translate }}:
        <b>{{ student.english_exam_type | uppercase }}</b>
      </span>
    </div>
    <div class="cell small-only-12 cell_not-padding-bottom m-b-4">
      <div class='flex-grid'>
        <div class='cell large-4 middle-4 small-6 cell_not-padding-bottom m-b-4'>
          <span>
            {{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_READING' | translate }}:
            <b>{{ getEnglishExamType(student.english_exam_type, 'Reading') }}</b>
          </span>
        </div>
        <div class='cell large-4 middle-4 small-6 cell_not-padding-bottom m-b-4'>
          <span>
            {{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_LISTENING' | translate }}:
            <b>{{ getEnglishExamType(student.english_exam_type, 'Listening') }}</b>
          </span>
        </div>
        <div class='cell large-4 middle-4 small-6 cell_not-padding-bottom m-b-4'>
          <span>
            {{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_SPEAKING' | translate }}:
            <b>{{ getEnglishExamType(student.english_exam_type, 'Speaking') }}</b>
          </span>
        </div>
        <div class='cell large-4 middle-4 small-6 cell_not-padding-bottom m-b-4'>
          <span>
            {{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_WRITING' | translate }}:
            <b>{{ getEnglishExamType(student.english_exam_type, 'Writing') }}</b>
          </span>
        </div>
        <div class='cell large-4 middle-4 small-6 cell_not-padding-bottom m-b-4'>
          <span>
            {{ 'COMMON.PROGRAM_LANGUAGE_REQUIREMENTS_OVERALL' | translate }}:
            <b>{{ getEnglishExamType(student.english_exam_type, 'Overall') }}</b>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf='student.i_have_gre_exam_scores' class='flex-grid'>
    <div class="cell cell_not-padding-bottom m-b-4 small-only-12">
      <h2 class='sub-title'>{{ key + 'GRE_EXAM_SCORES_LABEL' | translate }}</h2>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'ENGLISH_EXAM_TYPE_LABEL' | translate }}:
        <b>{{ student.gre_exam_date | date: 'MMMM d, y' }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'VERBAL_SCORE_LABEL' | translate }}:
        <b>{{ student.gre_verbal_score }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'QUANTITATIVE_SCORE_LABEL' | translate }}:
        <b>{{ student.gre_quantitative_score }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'WRITING_SCORE_LABEL' | translate }}:
        <b>{{ student.gre_writing_score }}</b>
      </span>
    </div>
  </div>
  <div *ngIf='student.i_have_gmat_exam_scores' class='flex-grid'>
    <div class="cell cell_not-padding-bottom m-b-4 small-only-12">
      <h2 class='sub-title'>{{ key + 'GMAT_EXAM_SCORES_LABEL' | translate }}</h2>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'ENGLISH_EXAM_TYPE_LABEL' | translate }}:
        <b>{{ student.gmat_exam_date | date: 'MMMM d, y' }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'VERBAL_SCORE_LABEL' | translate }}:
        <b>{{ student.gmat_verbal_score }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'QUANTITATIVE_SCORE_LABEL' | translate }}:
        <b>{{ student.gmat_quantitative_score }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'WRITING_SCORE_LABEL' | translate }}:
        <b>{{ student.gmat_writing_score }}</b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'WRITING_OVERALL_LABEL' | translate }}:
        <b>{{ student.gmat_overall_score }}</b>
      </span>
    </div>
  </div>
  <div class='flex-grid'>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'USA_F1_VISA' | translate }}:
        <b>
          {{ getLabel(student.have_usa_f1_visa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'CANADIAN_STUDY_PERMIT_OR_VISITOR_VISA' | translate }}:
        <b>
          {{ getLabel(student.have_valid_canadian_study_permit, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'UK_STUDENT_VISA_TIER_4_OR_SHORT_TERM_STUDY_VISA' | translate }}:
        <b>
          {{ getLabel(student.have_uk_student_visa_short_study_visa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'AUSTRALIAN_STUDENT_OR_VISITOR_VISA' | translate }}:
        <b>
          {{ getLabel(student.have_australian_student, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'NEW_ZEALAND_STUDENT_OR_VISITOR_VISA' | translate }}:
        <b>
          {{ getLabel(student.have_new_zealand_student, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span class='display-block'>
        {{ key + 'VALID_VISA_FORM_OTHER_COUNTRY' | translate }}:
        <b>
          {{ getLabel(student.have_valid_visa_form_other_country, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
      <span *ngIf='student.other_country?.length' class='display-block p-t-4'>
        {{ 'INPUT_NAME.COUNTRY' | translate }}:
      </span>
      <ul *ngIf='student.other_country?.length' class='student-profile-detail__school-list p-t-3'>
        <li *ngFor='let item of student.other_country' class='student-profile-detail__school-list-item'>
          <b>{{ item.name }}</b>
        </li>
      </ul>
    </div>
  </div>

  <div class='flex-grid'>
    <div class="cell cell_not-padding-bottom m-b-4 small-only-12">
      <h2 class='title'>{{ key + 'ADDITIONAL_INFORMATION' | translate }}</h2>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'HAVE_EVER_VISITED_CANADA_USA_APPROVAL' | translate }}:
        <b>
          {{ getLabel(student.have_ever_been_refused_visa_for_canada_usa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'CURRENT_RESIDENT_CANADA_USA' | translate }}:
        <b>
          {{ getLabel(student.you_currently_residing_canada_usa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'APPLICATION_IMMIGRATION_INTO_CANADA_USA' | translate }}:
        <b>
          {{ getLabel(student.applied_type_immigration_into_canada_usa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'VALID_US_STUDY_VISA' | translate }}:
        <b>
          {{ getLabel(student.have_valid_us_study_visa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'REFUSING_VISA_FOR_CANADA_USA' | translate }}:
        <b>
          {{ getLabel(student.have_ever_been_refused_visa_for_canada_usa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
    <div class='cell large-6 middle-6 small-12 cell_not-padding-bottom m-b-4'>
      <span>
        {{ key + 'REMOVAL_FROM_CANADA_USA' | translate }}:
        <b>
          {{ getLabel(student.were_ever_removed_from_canada_usa, 'COMMON.YES', 'COMMON.NO') | translate }}
        </b>
      </span>
    </div>
  </div>
  <div class='flex-grid'>
    <div *ngIf='student.documents?.passport' class='cell large-6 middle-6 small-12'>
      <div class='student-profile-detail__image-holder'>
        <img
          [src]='student.documents?.passport?.url'
          [alt]='student.documents?.passport.name'
          class='student-profile-detail__image'
        >
      </div>
    </div>
    <div *ngIf='student.documents?.resume' class='cell large-6 middle-6 small-12'>
      <div class='student-profile-detail__image-holder'>
        <img
          [src]='student.documents?.resume?.url'
          [alt]='student.documents?.resume.name'
          class='student-profile-detail__image'
        >
      </div>
    </div>
    <div *ngIf='student.documents?.reference' class='cell large-6 middle-6 small-12'>
      <div class='student-profile-detail__image-holder'>
        <img
          [src]='student.documents?.reference?.url'
          [alt]='student.documents?.reference.name'
          class='student-profile-detail__image'
        >
      </div>
    </div>
    <div *ngIf='student.documents?.photo' class='cell large-6 middle-6 small-12'>
      <div class='student-profile-detail__image-holder'>
        <img
          [src]='student.documents?.photo?.url'
          [alt]='student.documents?.photo.name'
          class='student-profile-detail__image'
        >
      </div>
    </div>
    <div *ngIf='student.documents?.other_documents' class='cell large-6 middle-6 small-12'>
      <div class='student-profile-detail__image-holder'>
        <img
          [src]='student.documents?.other_documents?.url'
          [alt]='student.documents?.other_documents.name'
          class='student-profile-detail__image'
        >
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div *ngIf='!isLoading' class='student-profile-detail__loader'>
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf='isLoading && isError' class='student-profile-detail__error'>
    <h2>{{ 'MESSAGE.SOMETHING_WENT_WRONG' | translate }}</h2>
  </div>
</ng-template>

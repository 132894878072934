import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { UnauthGuard } from '@guards/unauth/unauth.guard';
import { AuthLayoutComponent } from '@layouts/auth/auth-layout.component';
import { AuthGuard } from '@guards/auth/auth.guard';
import { MeResolver } from '@resolvers/me/me.resolver';
import { MainLayoutComponent } from '@layouts/main/main-layout.component';
import { AuthModule } from '@modules/auth/auth.module';
import { MainModule } from '@modules/main/main.module';
import { AccountLayoutComponent } from '@layouts/account/account-layout.component';
import { AccountModule } from '@modules/account/account.module';
import { NotFoundPageComponent } from '@modules/main/common/not-found-page/not-found-page.component';
import { PaymentSuccessComponent } from '@modules/main/common/payment-success/payment-success.component';
import { StaticModule } from '@modules/static/static.module';

const routes: Routes = [
  {
    path: '',
    // canActivate: [UnauthGuard],
    resolve: { me: MeResolver },
    component: MainLayoutComponent,
    runGuardsAndResolvers: 'always',
    data: {
      description:
        'ApplyClever supports students in achieving placements to study internationally at any point in their academic journey with personal customer experience.'
    },
    loadChildren: (): Promise<MainModule> =>
      import('@modules/main/main.module').then((m: { MainModule: MainModule }): MainModule => m.MainModule)
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: (): Promise<AuthModule> =>
      import('@modules/auth/auth.module').then((m: { AuthModule: AuthModule }): AuthModule => m.AuthModule)
  },
  {
    path: 'static',
    component: MainLayoutComponent,
    loadChildren: (): Promise<StaticModule> =>
      import('@modules/static/static.module').then((m: { StaticModule: StaticModule }): StaticModule => m.StaticModule)
  },
  {
    path: 'account',
    resolve: { me: MeResolver },
    canActivate: [AuthGuard],
    component: AccountLayoutComponent,
    runGuardsAndResolvers: 'always',
    loadChildren: (): Promise<AccountModule> =>
      import('@modules/account/account.module').then((m: { AccountModule: AccountModule }): AccountModule => m.AccountModule)
  },
  {
    path: 'payment/success',
    component: PaymentSuccessComponent,
    data: {
      title: 'Payment Successful'
    }
  },
  {
    path: '**',
    redirectTo: '/404'
  },
  {
    path: '404',
    component: NotFoundPageComponent
  }
];

const config: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Exclude, Expose } from 'class-transformer';
import { UserRole } from '@models/enums/user-role.enum';
import { IUploadFile } from '@models/interfaces/file-model.interface';

@Exclude()
export class User {
  @Expose()
  id: number;
  @Expose()
  email: string;
  @Expose()
  phone: string;
  @Expose()
  firstName: string;
  @Expose()
  lastName: string;
  @Expose()
  createdAt: string;
  @Expose()
  patronymic: string;
  @Expose()
  country: any;
  @Expose()
  avatar: IUploadFile;
  // TODO: after change structure user role replace to that role: UserRole
  @Expose()
  role: {
    id: number;
    key: UserRole;
  };
  @Expose()
  countMessages: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DctDownloadComponent } from '@shared/components/dct-download/dct-download.component';

@NgModule({
  declarations: [DctDownloadComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, MatIconModule],
  exports: [DctDownloadComponent]
})
export class DctDownloadModule {}

<div class="flex-grid p-b-4">
  <div class="cell small-only-12">
    <h2 class="title-block">{{ key + 'PERSONAL_INFORMATION_TITLE' | translate }}</h2>
    <h3 class="subtitle-block">{{ key + 'PERSONAL_INFORMATION_SUBTITLE' | translate }}</h3>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'FIST_NAME_LABEL' | translate" [control]="getControl('first_name', inputForm)"></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'MIDDLE_NAME_LABEL' | translate"
      [control]="getControl('patronymic', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'LAST_NAME_LABEL' | translate" [control]="getControl('last_name', inputForm)"></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-datepicker
      startViewMode="month"
      [placeholder]="key + 'DATE_OF_BIRTH_LABEL' | translate"
      [control]="getControl('date_of_birth', inputForm)"
    ></base-datepicker>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'LANGUAGES_LABEL' | translate" [control]="getControl('languages', inputForm)"></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-autocomplete
      [placeholder]="key + 'COUNTRY_OF_CITIZENSHIP_LABEL' | translate"
      [control]="getControl('country_of_citizenship', inputForm)"
      [options]="citizenshipOptions"
      (inputSearch)="fetchCitizenship($event)"
      [template]="autoCompleteTemplate"
      [displayWith]="displayWith"
      [getValue]="getValue"
      matTooltipPosition='above'
      [matTooltip]=" key + 'MULTIPLE_CITIZENSHIPS_INDICATE_ONLY_ONE_PASSPORT' | translate"
    ></base-autocomplete>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'PASSPORT_NUMBER_LABEL' | translate"
      [control]="getControl('passport_number', inputForm)"
    ></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-radio-group
      [label]="key + 'GENDER_TITLE' | translate"
      [radioOption]="genderStatus"
      [control]="getControl('gender', inputForm)"
    ></base-radio-group>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-radio-group
      [label]="key + 'MARITAL_STATUS_TITLE' | translate"
      [radioOption]="marriedStatus"
      [control]="getControl('marital_status', inputForm)"
    ></base-radio-group>
  </div>
</div>
<div class="flex-grid">
  <div class="cell small-only-12">
    <h2 class="title-block">{{ key + 'ADDRESS_DETAIL_TITLE' | translate }}</h2>
  </div>
  <div class="cell cell_not-padding-bottom small-only-12">
    <google-map-search [control]="getControl('address', inputForm)"></google-map-search>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-autocomplete
      [placeholder]="key + 'COUNTRY_LABEL' | translate"
      [control]="getControl('country', inputForm)"
      [options]="countryOptions"
      (inputSearch)="fetchCountry($event)"
      [template]="autoCompleteTemplate"
      [displayWith]="displayWith"
      [getValue]="getValue"
    ></base-autocomplete>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'PROVINCE_LABEL' | translate" [control]="getControl('province', inputForm)"></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'CITY_LABEL' | translate" [control]="getControl('city', inputForm)"></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input
      [placeholder]="key + 'EMAIL_LABEL' | translate"
      [control]="getControl('email', inputForm)"
      [customPrefix]='!shouldDisabledEmail && warning'
      [disabled]='shouldDisabledEmail'
    ></base-form-input>
    <ng-template #warning>
      <mat-icon color='warn' [matTooltip]="'MESSAGE.EMAIL_CHANGE_WARNING' | translate">warning</mat-icon>
    </ng-template>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'PHONE_NUMBER_LABEL' | translate" [control]="getControl('phone', inputForm)"></base-form-input>
  </div>
  <div class="cell cell_not-padding-bottom large-4 middle-6 small-12">
    <base-form-input [placeholder]="key + 'ZIP_CODE_LABEL' | translate" [control]="getControl('zip_code', inputForm)"></base-form-input>
  </div>
</div>

<ng-template #autoCompleteTemplate let-item>
  {{ item.label }}
</ng-template>

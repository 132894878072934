import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassType } from 'class-transformer/ClassTransformer';
import { User } from '@models/classes/user.model';
import { IEligibilityFilterOptions, IRawProgramFilterOptions, IRawSchoolFilterOptions } from '@models/interfaces/filters.interface';
import { APP_CONFIG, IAppConfig } from '@misc/constants/app-config.constant';
import { HttpService } from '@services/http/http.service';
import { IBtnContent } from '@modules/main/common/start-here/components/filter-stepper/filter-stepper.component';
import { TranslatePipe } from '@ngx-translate/core';
import { IOption } from '@forms/base-select/base-select.component';
import { prepareFilterOption } from '@app/misc/helpers/prepare-filter-option';
import { IGradingSchemeOriginal } from '@models/interfaces/forms/option.interface';
import { StorageService } from '@services/storage/storage.service';

export enum QuickStartTypesEnum {
  form = 'form',
  stepper = 'stepper'
}

export interface IFilterRawItem {
  id?: number;
  key?: string;
  code?: string;
  name?: string;
}

export interface IAllFilterRawDictionary {
  disciplines?: IFilterRawItem[];
  education_levels?: IFilterRawItem[];
  english_exam_types?: IFilterRawItem[];
  program_categories?: IFilterRawItem[];
  school_types?: IFilterRawItem[];
  welcome_packages?: IFilterRawItem[];
}

export interface IAllFilterMilledDictionary {
  disciplines?: IOption[];
  education_levels?: IOption[];
  english_exam_types?: IOption[];
  program_categories?: IOption[];
  school_types?: IOption[];
  welcome_packages?: IOption[];
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  readonly key: string = 'QUICK_START.';
  readonly quickStartTypes: typeof QuickStartTypesEnum = QuickStartTypesEnum;
  readonly storageKey: string = 'filter';
  protected readonly URLPath: string = '/api/v1/filters';
  protected readonly model: ClassType<any> = User;
  filterStepObserver: BehaviorSubject<number> = new BehaviorSubject(1);
  applySchoolFilter$: BehaviorSubject<void> = new BehaviorSubject(null);
  applyProgramFilter$: BehaviorSubject<void> = new BehaviorSubject(null);
  quickStartCurrentType: QuickStartTypesEnum = QuickStartTypesEnum.form;
  filterIsFetched: boolean = false;

  allFiltersOptions: IEligibilityFilterOptions = this.defaultFiltersOptions();

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private http: HttpService,
    private translate: TranslatePipe,
    private storage: StorageService
  ) {}

  get apiUrl(): string {
    return this.config.apiUrl;
  }

  get currentFilterStep(): number {
    return this.filterStepObserver.value;
  }

  get degreeContent(): IBtnContent {
    return {
      title: this.translate.transform(`${this.key}LEVEL_STUDY_TITLE`),
      btnCollection: null
    };
  }

  get disciplineStudyContent(): IBtnContent {
    return {
      title: this.translate.transform(`${this.key}DISCIPLINE_STUDY_TITLE`),
      btnCollection: [
        {
          label: this.translate.transform(`${this.key}OTHER`),
          value: null
        }
      ]
    };
  }

  get locationContent(): IBtnContent {
    return {
      title: this.translate.transform(`${this.key}LOCATION_TITLE`),
      btnCollection: [
        {
          label: this.translate.transform(`${this.key}OTHER`),
          value: null
        }
      ]
    };
  }

  get tuitionFreeContent(): IBtnContent {
    return {
      title: this.translate.transform(`${this.key}TUITION_FREE_TITLE`),
      actionBtn: this.translate.transform(`${this.key}TUITION_FREE_BTN_SEARCH`)
    };
  }

  get sortBy(): IOption[] {
    return [
      {
        label: 'SORT_BY.SCHOOL_RANK',
        value: 'rank'
      },
      {
        label: 'SORT_BY.TUITION_DOWN',
        value: 'tuition_down'
      },
      {
        label: 'SORT_BY.TUITION_UP',
        value: 'tuition_up'
      },
      {
        label: 'SORT_BY.APPLICATION_FEE_DOWN',
        value: 'application_fee_down'
      },
      {
        label: 'SORT_BY.APPLICATION_FEE_UP',
        value: 'application_fee_up'
      },
      {
        label: 'SORT_BY.WITH_PROMOTION',
        value: 'with_promotion'
      },
      {
        label: 'SORT_BY.WITH_DISCOUNT',
        value: 'with_discount'
      }
    ];
  }

  defaultFiltersOptions(): IEligibilityFilterOptions {
    return {
      school: {},
      program: {}
    };
  }

  setSchoolFiltersOptions(options: IRawSchoolFilterOptions): void {
    this.allFiltersOptions.school = {
      ...this.allFiltersOptions.school,
      ...options
    };
    this.saveFilterOption();
  }

  getSchoolFiltersOptions<T>(rawValue?: boolean): T {
    if (rawValue) {
      return this.allFiltersOptions.school as T;
    }

    return this.transformFilterOption(this.allFiltersOptions.school) as T;
  }

  setProgramFiltersOptions(options: IRawProgramFilterOptions): void {
    this.allFiltersOptions.program = {
      ...this.allFiltersOptions.program,
      ...options
    };
    this.saveFilterOption();
  }

  saveFilterOption(): void {
    this.storage.setItem(this.storageKey, JSON.stringify(this.allFiltersOptions));
  }

  getFilterOption(): void {
    this.allFiltersOptions = JSON.parse(this.storage.get(this.storageKey)) ?? { school: {}, program: {} };
  }

  removeFilterOption(): void {
    this.storage.remove(this.storageKey);
    this.allFiltersOptions = this.defaultFiltersOptions();
  }

  getProgramFiltersOptions<T>(rawValue?: boolean): T {
    if (rawValue) {
      return this.allFiltersOptions.program as T;
    }

    return this.transformFilterOption(this.allFiltersOptions.program) as T;
  }

  transformFilterOption(data: any): any {
    return prepareFilterOption(data);
  }

  changeQuickStartType(): void {
    this.quickStartCurrentType = this.quickStartTypes.stepper;
  }

  incrementCurrentFilterStep(): void {
    const currentStep: number = this.filterStepObserver.value;
    this.filterStepObserver.next(currentStep + 1);
  }

  resetFilter(): void {
    this.filterStepObserver.next(1);
    this.quickStartCurrentType = QuickStartTypesEnum.form;
  }

  fetchCountry(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('countries', params));
  }

  fetchEducationLevel(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('education-levels', params));
  }

  fetchProgramCategories(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('program-categories', params));
  }

  fetchSchoolTypes(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('school-types', params));
  }

  fetchSchool(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('schools', params));
  }

  fetchDisciplines(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('disciplines', params));
  }

  fetchKindInstitution(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('kind-institutions', params));
  }

  fetchStudyTypes(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('study-types', params));
  }

  fetchEnglishExamTypes(params: string = ''): Observable<IOption[]> {
    return this.commonRequest(this.makeUrl('english-exam-types', params));
  }

  fetchAllFilters(params: string[] = []): Observable<IAllFilterMilledDictionary> {
    const url: string = params?.length ? `${this.URLPath}?types=${params.join(',')}` : this.URLPath;
    return this.http.get(`${this.apiUrl}${url}`).pipe(
      map(
        (data: IAllFilterRawDictionary): IAllFilterMilledDictionary => {
          return Object.keys(data).reduce((acc: IAllFilterMilledDictionary, key: string): any => {
            return {
              ...acc,
              [key]: data[key].map((item: IFilterRawItem): IOption => this.transformOptionItem(item))
            };
          }, {});
        }
      )
    );
  }

  fetchCurrencies(): Observable<IOption[]> {
    return this.commonRequest(`${this.URLPath}/currencies`);
  }

  fetchPopularCountry(): Observable<IOption[]> {
    return this.commonRequest(`${this.URLPath}/popular-countries`);
  }

  fetchEducationLevelGroups(): Observable<IOption[]> {
    return this.commonRequest(`${this.URLPath}/education-level-groups`);
  }

  fetchEducationLevelByCountry(id: number): Observable<IOption[]> {
    return this.http.get(`${this.apiUrl}${this.URLPath}/level/${id}`).pipe(
      map((data: IFilterRawItem[]): IOption[] => {
        return data.map((item: IFilterRawItem): IOption => this.transformOptionItem(item));
      })
    );
  }

  fetchGradingScheme(countryId: number, levelId: number): Observable<IGradingSchemeOriginal[]> {
    return this.http.get(`${this.apiUrl}${this.URLPath}/grading-scheme?country_id=${countryId}&level_id=${levelId}`);
  }

  commonRequest(url: string): Observable<IOption[]> {
    return this.http.get(`${this.apiUrl}${url}`, {}, { skipLoaderStart: true }).pipe(
      map((data: IFilterRawItem[]): IOption[] => {
        return data.map((item: IFilterRawItem): IOption => this.transformOptionItem(item));
      })
    );
  }

  makeUrl(url: string, query: string = ''): string {
    return query ? `${this.URLPath}/${url}?search=${query}` : `${this.URLPath}/${url}`;
  }

  transformOptionItem(item: IFilterRawItem): IOption {
    return {
      value: item.id || item.key,
      label: item.name
    };
  }
}

import { Component, OnInit } from '@angular/core';
import {MegaMenuItem} from 'primeng/api';

@Component({
  selector: 'main',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  items: MegaMenuItem[];

  ngOnInit(): void {
    this.items = [
      {
        label: 'Educator',
      },
      {
        label: 'Students',
      },
      {
        label: 'Recruiters',
      },
      {
        label: 'News',
      },
      {
        label: 'About Us',
      }
    ];
  }
}

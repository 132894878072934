import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IProgramDetail, IProgramLanguageRequirementsItem, IProgramMandatorySubjects } from '@models/interfaces/program-detail.interface';
import { IOption } from '@forms/base-select/base-select.component';
import { DocumentsRequested, ExamList, intakeStatuses } from '@misc/constants/_base.constant';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'program-requirements-candidate',
  templateUrl: './program-requirements-candidate.component.html',
  styleUrls: ['./program-requirements-candidate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramRequirementsCandidateComponent {
  readonly key: string = 'PAGE_DETAIL.';
  intakesStatus: IOption[] = intakeStatuses;
  examList: typeof ExamList = ExamList;

  @Input() program: IProgramDetail;

  constructor(private translate: TranslatePipe) {
  }

  isEntranceExam(): string {
    return !!this.program?.entrance_exam ? 'check-circle-custom' : 'minus-custom';
  }

  getIntakeStatus(status?: string): string {
    if (!status) {
      return null;
    }
    return this.intakesStatus.find((intake: IOption): boolean => intake.value === status)?.label;
  }

  getExamList(): string {
    if (Object.values(this.program.exams_list).some((value: boolean): boolean => value)) {
      return Object
        .keys(this.program.exams_list)
        .filter((key: string): boolean => this.program.exams_list[key])
        .reduce((acc: string[], item: string): string[] => {
          if (this.examList[item]) {
            return [...acc, this.examList[item]];
          }
          return [...acc];
          }, [])
        .join('/');
    }
    return null;
  }

  getDocuments(): any {
    if (!this.program) {
      return [];
    }

    const documentsRequested: string [] =  Object
      .keys((this.program?.documents_requested ?? {}))
      .filter((key: string): boolean => this.program?.documents_requested[key])
      .reduce((acc: string[], key: string): string[] => {
        if (DocumentsRequested[key]) {
          return [...acc, this.translate.transform(DocumentsRequested[key])];
        }
        return [...acc];
      }, []);

    const OtherFields: string[] = (this.program?.documents_fields ?? [])
      .map((item: { field: string; row_id: string }): string => item.field);

    return [...documentsRequested, ...OtherFields];
  }

  shouldDisplayLanguageRequirements(data: IProgramLanguageRequirementsItem): boolean {
    if (!data) {
      return false;
    }

    return Object.values(data ?? {}).some((item: string): boolean => item && !!(`${item}` || '').trim());
  }

  shouldDisplayProgramMandatorySubjects(data: IProgramMandatorySubjects[]): boolean {
    return (data || []).some((item: IProgramMandatorySubjects): boolean => !!item?.name);
  }
}

import { Component, ElementRef, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MegaMenuItem } from 'primeng/api';
import { AuthService } from '@services/auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRole } from '@models/enums/user-role.enum';
import { MediaMatcherService } from '@services/media-matcher/media-matcher.service';
import { MediaBreakpoints } from '@models/enums/media-breakpoints.enum';
import { ISocialNetwork } from '@models/interfaces/social-network.interface';
import { socialNetwork } from '@misc/constants/social-network.constant';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SocketService } from '@services/socket/socket.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { User } from '@models/classes/user.model';
import Echo from 'laravel-echo';
import { IGetNewMessageData } from '@models/interfaces/sockets/get-new-message-data.interface';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  readonly key: string = 'COMMON.';
  items: MegaMenuItem[];
  @ViewChild('header', { static: false }) header: ElementRef;
  @ViewChild('headerHolder', { static: false }) headerHolder: ElementRef;
  mobileMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  socialLinks: ISocialNetwork[] = socialNetwork;
  socketSubscription: Subscription;
  isHome: boolean;
  count: number = 0;

  constructor(
    private auth: AuthService,
    private mediaMatcherService: MediaMatcherService,
    private router: Router,
    private socket: SocketService
  ) {
    //
  }

  get showStartHere(): boolean {
    return (!this.auth.me || this.auth.me.role?.key !== UserRole.school) && !this.isHome;
  }

  get btnActionTitle(): string {
    return this.auth.me ? `${this.key}PROGRAMS` : `${this.key}START_HERE`;
  }

  get btnActionLink(): string[] {
    return this.auth.me ? ['/', 'suggestion'] : ['/', 'start-here'];
  }

  get btnActionLinkQueryParams(): { student?: number } {
    if (this.auth.isAuthenticated && this.auth.me?.role?.key === UserRole.student) {
      return { student: this.auth.me?.id };
    }
    return {};
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event: any): boolean => event instanceof ActivationEnd))
      .subscribe((event: { snapshot: ActivatedRouteSnapshot }): void => {
        this.isHome = decodeURIComponent(this.router.url) === '/';
      });
    this.items = [
      {
        label: 'EDUCATOR_LINK_LABEL',
        url: 'educator'
      },
      {
        label: 'STUDENTS_LINK_LABEL',
        url: 'student'
      },
      {
        label: 'RECRUITERS_LINK_LABEL',
        url: 'recruiter'
      },
      {
        label: 'NEWS_LINK_LABEL',
        url: 'news'
      },
      {
        label: 'ABOUT_LINK_LABEL',
        url: 'about-us'
      }
    ];

    this.auth.me$.pipe(filter((data: any): boolean => data)).subscribe((user: User): void => {
      this.socketSubscription = this.socket.getNewMessage.subscribe((data: any): void => (this.count = data?.count ?? user.countMessages));
    });
  }

  get mediaMatcher(): Observable<boolean> {
    return this.mediaMatcherService.breakpointByMediaIsMatched$(MediaBreakpoints.tabletM);
  }

  get mobileMenuIsOpen(): boolean {
    return this.mobileMenu$.value;
  }

  logout(): void {
    this.auth.logout();
  }

  mobileMenuTrigger(): void {
    this.mobileMenu$.next(!this.mobileMenuIsOpen);
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.auth.isAuthenticated$;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if (this.mediaMatcherService.breakpointIsMatched(MediaBreakpoints.tabletM)) {
      this.headerHolder.nativeElement.classList.remove('sticky');
      return;
    }
    let methodName: string | null = null;
    if (window.pageYOffset > this.headerHolder.nativeElement.offsetHeight) {
      methodName = 'add';
    } else {
      methodName = 'remove';
    }

    if (methodName) {
      this.headerHolder.nativeElement.classList[methodName]('sticky');
    }
  }

  ngOnDestroy(): void {
    this.socketSubscription?.unsubscribe();
  }
}

import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export interface IFileResponses {
  upload: any;
}

const imgList: string[] = [
  'https://omoro.ru/wp-content/uploads/2018/05/veselii-smailik-2.jpg',
  'https://bipbap.ru/wp-content/uploads/2018/02/41NP2h3kNML.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnelJ97Z-SAHNwaPUe4-FRLgC4EM5l1B7qSA&usqp=CAU',
  'https://klike.net/uploads/posts/2019-09/1568622558_1.jpg',
];

export const fileResponses: IFileResponses = {
  upload(_: any, body: any): Observable<HttpResponse<IFileResponses>> {
    const newFile: any = {
      id: Math.floor(Math.random() * 1000),
      name: body.name,
      originalName: body.name,
      mimeType: body.type,
      size: body.size,
      context: '',
      createdAt: new Date().toString(),
      updatedAt: new Date().toString(),
      path: imgList[Math.floor(Math.random() * imgList.length)]
    };
    return of(
      new HttpResponse({
        status: 200,
        body: newFile
      })
    );
  }
};

<label *ngIf="label">{{ label }}</label>
<mat-radio-group
  [color]='"primary"'
  [formControl]='formControl'
  class="base-radio-group"
>
  <mat-radio-button class="base-radio-group__button" *ngFor="let radio of radioOption" [value]="radio.value">
    <ng-container
      *ngTemplateOutlet='radioButtonTemplate ? radioButtonTemplate : defaultRadioButtonTemplate; context: { $implicit: radio }'
    ></ng-container>
  </mat-radio-button>
</mat-radio-group>

<ng-template #defaultRadioButtonTemplate let-item>
  {{ item.label | translate }}
</ng-template>

<div class="file-uploading"
     [class.single]="!multiple"
     [ngClass]="{
       'align-center': alignSingleBtn === 'center' && !control?.value,
       'align-left': alignSingleBtn === 'left' && !control?.value,
       'align-right': alignSingleBtn === 'right' && !control?.value,
       'single': !multiple
     }"
>
  <label *ngIf="label" class="file-uploading__label">
    {{ label }}
    <mat-icon
      *ngIf="tooltipLabel"
      [matTooltip]="tooltipLabel"
      matTooltipPosition="above"
      svgIcon="question"
      class="file-uploading__tooltip"
    ></mat-icon>
  </label>
  <ng-container *ngTemplateOutlet="multiple ? multipleTemplate : singleTemplate"></ng-container>
  <input
    #fileInput
    [accept]="accept"
    (change)="onFileChange($event)"
    [multiple]="multiple"
    type="file"
    hidden
    class="file-controls__input"
  />
</div>

<ng-template #multipleTemplate>
  <div
    class="flex-grid multiple-box"
    [ngClass]="{
      'align-center': alignMultipleBtn === 'center' && !controlArray?.value?.length,
      'align-left': alignMultipleBtn === 'left' && !controlArray?.value?.length,
      'align-right': alignMultipleBtn === 'right' && !controlArray?.value?.length
    }"
  >
    <ng-container *ngFor="let item of controlArray.value; let i = index">
      <ng-container
        *ngTemplateOutlet="
          previewTemplate ? previewTemplate : previewDefaultTemplate;
          context: {
            $implicit: item,
            control: controlArray,
            order: i
          }
        "
      ></ng-container>
    </ng-container>
    <div
      *ngIf="controlArray.value.length < maxQualityFiles && ShowUploadBtn"
      class="cell large-3"
      [ngClass]="{
      'flex-content-center': alignMultipleBtn === 'center' && !controlArray?.value?.length,
      'flex-content-end': alignMultipleBtn === 'left' && !controlArray?.value?.length,
      'flex-content-start': alignMultipleBtn === 'right' && !controlArray?.value?.length,
      'flex-center': controlArray.value.length
    }"
    >
      <ng-container *ngTemplateOutlet="raisedBtn"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #singleTemplate>
  <ng-container *ngIf="control?.value">
    <ng-container
      *ngTemplateOutlet="
        previewTemplate ? previewTemplate : previewDefaultTemplate;
        context: { $implicit: control?.value, control: control }
      "
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="!control?.value">
    <ng-container *ngTemplateOutlet="btnLabel ? raisedBtn : iconBtn"></ng-container>
  </ng-container>
</ng-template>

<ng-template #raisedBtn>
  <div
    class="file-uploading__multiple-btn-holder"
    [class.empty]='!controlArray.value.length'
  >
    <button
      mat-raised-button
      color="primary"
      (click)="clicked()"
      [matTooltip]="btnTooltip | translate"
      class="file-uploading__simple-btn"
    >
      {{ btnLabel }}
    </button>
  </div>
</ng-template>

<ng-template #iconBtn>
  <button mat-icon-button (click)="clicked()" class="file-uploading__btn-circle">
    <mat-icon class="file-uploading__btn-icon">control_point</mat-icon>
  </button>
</ng-template>

<ng-template #previewDefaultTemplate let-item let-control="control" let-order="order">
  <div class="cell" [class.large-3]="multiple">
    <div class="file-uploading__preview-holder">
      <div
        class="file-uploading__preview-image"
        [ngClass]="{
          'file-uploading__preview-image_multiple': multiple,
          'file-uploading__preview-image_single': !multiple
        }"
      >
        <img [src]="item?.url" alt="" />
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" [class.primary-color]="!multiple" class="file-uploading__action-menu">
        <mat-icon svgIcon="gear"></mat-icon>
      </button>
      <mat-menu #menu="matMenu" yPosition="below" xPosition="after">
        <button mat-menu-item (click)="editItem(item)">{{ 'BUTTON_NAME.EDIT' | translate }}</button>
        <button mat-menu-item (click)="deleteItem(item)">{{ 'BUTTON_NAME.DELETE' | translate }}</button>
      </mat-menu>
    </div>
  </div>
</ng-template>
